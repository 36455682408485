<template>
    <div class="dh-card-container" v-if="visible">
        <div class="dh-card-wrapper" :class="{'slide-in':visible, 'slide-out': slideOut}">
            <div class="row">
                <div class="col-md-12">
                    <div @click="slide" class="dh-card-close">X</div>
                    <div class="dh-card dh-card-inner">
                        <h3>{{title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CardPopover",
        data() {
          return {
              visible: false,
              title: '',
              description: '',
              slideOut: false
          }
        },
        methods: {
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },
            slide() {
                this.slideOut=true;
                let self = this;
                setTimeout(function () {
                    self.slideOut = false;
                    self.visible = false;
                },1000);
            },
            isVisible() {
              return this.visible;
            },
            setTitle(title) {
                this.title = title;
                return this;
            },
            setDescription(description) {
                this.description = description;
                return this;
            }
        }
    }
</script>

<style scoped lang="scss">
    .dh-card-container {
        z-index: 9999999;
        position: fixed;
        bottom: 50px;
        left: 25px;
        width: 375px;
        .dh-card {
            background: #fff;
            box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
            transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
            padding: 14px 80px 18px 36px;
            cursor: pointer;
        }

        .dh-card:hover {
            transform: scale(1.05);
            box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
        }

        .dh-card h3 {
            font-weight: 400;
            color: #ffffffb3;
            font-size: 1.3em;
        }

        .dh-card p {
            color: #ffffffb3;
        }

        .dh-card img {
            position: absolute;
            top: 20px;
            right: 15px;
            max-height: 120px;
        }

        .dh-card-inner {
            /*background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);*/
            background-repeat: no-repeat;
            background-position: right;
            background-size: 80px;
            background-color: rgba(191, 191, 191,0.3);
        }

        .dh-card-close{
            position:absolute;
            z-index:999;
            top:5px;
            right: 20px;
            color:#fff;
            &:hover { color: red; }
            cursor: pointer;
        }

        @media(max-width: 990px) {
            .dh-card {
                margin: 20px;
            }
        }
        .dh-card-wrapper {
            transform: translateX(-120%);
            -webkit-transform: translateX(-120%);

        }
        .slide-in {
            animation: slide-in 0.5s forwards;
            -webkit-animation: slide-in 0.5s forwards;
        }

        .slide-out {
            animation: slide-out 0.5s forwards;
            -webkit-animation: slide-out 0.5s forwards;
        }

        @keyframes slide-in {
            100% {
                transform: translateX(0%);
            }
        }

        @-webkit-keyframes slide-in {
            100% {
                -webkit-transform: translateX(0%);
            }
        }

        @keyframes slide-out {
            0% {
                transform: translateX(0%);
            }
            100% {
                transform: translateX(-120%);
            }
        }

        @-webkit-keyframes slide-out {
            0% {
                -webkit-transform: translateX(0%);
            }
            100% {
                -webkit-transform: translateX(-120%);
            }
        }
    }
</style>