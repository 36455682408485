<template>
    <drop-zone-uploader class="text-muted"
                        :id="profileUploader"
                        :parent="resources"
                        :dataColumn="dataColumn"
                        :columns="columns"
                        :croppable="croppable"
                        :options="dropOptions"
                        @file-added="addResources">
        <slot name="label">
        </slot>
    </drop-zone-uploader>
</template>

<script>
    import DropZoneUploader from "../../../components/file_uploader/DropzoneUploader";

    export default {
        name: "ProfileUploader",
        components: {DropZoneUploader},
        props: {
            resources: {
                type: String,
                default: 'resource'
            },
            columns: {
                type: Array,
                default: () => {
                    return []
                }
            },
            profileUploader: {
                type: String,
                default: 'profileUploader'
            },
            croppable: {
                type: Boolean,
                default: false
            },
            dataColumn: {
                type: Object,
                default: () => {
                    return {type: 'profile'}
                }
            }
        },
        data: () => ({
            dropOptions: {
                acceptedFiles: "image/jpeg,image/png,image/gif"
            }
        }),
        methods: {
            addResources(sId, aFiles, sParent, sDataColumn) {
                this.$events.emit('getCustomerProfileImage', sId, aFiles, sParent, sDataColumn);
            }
        }
    }
</script>

<style scoped>

</style>