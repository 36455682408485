import Noty from 'noty';

class NotyService {
    overrideNotyDefaults() {
        Noty.overrideDefaults({
            dismissQueue: true,
            closeWith: ['button', 'click'],
            callbacks: {
                onTemplate: function () {
                    if (this.options.type === 'i4t-info') {

                        this.barDom.innerHTML = `<div class="job-popup-area">
                                <div class="job-popup">
                                    <div class="time-popup">
                                        <span>${this.options.text.activity_at}</span>                             
                                    </div>
                                    <div class="popup-body">
                                        <div class="profile-pic-pop">
                                            <img src="${this.options.text.activity_profile_image}" />
                                        </div>
                                        <div class="right-desc-pop">
                                            <div class="title-popup">
                                             ${this.options.text.message}
                                        </div>
                                            <div class="bottom-popup-text">
                                             ${this.options.text.job_code}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>`;

                    } else if (this.options.type === 'lead-info') {
                        this.barDom.innerHTML = `<div class="job-popup-area lead-pop">
                                <div class="job-popup">
                                    <div class="time-popup">
                                        <span>${moment(this.options.text.updated_at).fromNow()}</span>                             
                                    </div>
                                    <div class="popup-body">
                                        <div class="profile-pic-pop">
                                            <img src="/img/icons/new.png" />
                                        </div>
                                        <div class="right-desc-pop">
                                            <div class="title-popup">
                                               <span class="top-desc">New Lead Alert!</span>
                                                <span>${this.options.text.lead.location.substr(-55)}</span>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>`;
                    }
                }
            }
        });
    };

}

export default new NotyService();