<template>

    <div class="input-group mb-3 table-records-pages" id="date-range">

        <div class="cal-item">
            <div class="input-group-prepend">
                <label class="input-group-text"> From </label>
            </div>
            <date-picker
                    id="vueTableFromDate"
                    @selected="checkOnChaneFrom"
                    v-model="dateFilter.fromFilterDate"
                    :class="'date_picker'"
                    :format="'yyyy-MM-dd'"
                    :bootstrap-styling="true"
                    :placeholder="'From'"
                    :style="['padding-left:0']"
                    :calendar-button="true"
                    :calendar-button-icon="'fa fa-calendar'"
            />
        </div>

        <div class="cal-item">
            <div class="input-group-append">
                <label class="input-group-text">To</label>
            </div>
            <date-picker
                    id="vueTableToDate"
                    :disabled=isDisable
                    @selected="checkOnChaneTo"
                    v-model="dateFilter.toFilterDate"
                    :class="'date_picker'"
                    :format="'yyyy-MM-dd'"
                    :bootstrap-styling="true"
                    :placeholder="'To'"
                    :style="['padding-left:0']"
                    :calendar-button="true"
                    :calendar-button-icon="'fa fa-calendar'"
            />
        </div>

        <!--<div class="cal-item">-->
            <!--<div class="input-group-prepend">-->
                <!--<label class="input-group-text"> Date Period </label>-->
            <!--</div>-->
            <!--<select class="form-control" @change="filterRange"-->
                    <!--v-model="getSelectedValue">-->
                <!--<option value="0" disabled></option>-->
                <!--<option value="1">This Week</option>-->
                <!--<option value="2">This Month</option>-->
                <!--<option value="3">Last Month</option>-->
                <!--<option value="4">Last 3 Months</option>-->
            <!--</select>-->
        <!--</div>-->
    </div>

</template>

<script>
    import DatePicker from 'vuejs-datepicker';

    export default {
        components: {
            DatePicker
        },
        data() {
            return {
                getSelectedValue: '',
                isFrom: false,
                isTo: false,
                isDisable: true,
                dateFilter: {
                    fromFilterDate: '',
                    toFilterDate: ''
                },
            }
        },
        methods: {
            // filterRange() {
            //     let self = this;
            //     let start = '';
            //     let end = '';
            //     let firstOfThisMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
            //     switch (this.getSelectedValue) {
            //         case '1':
            //             start = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss');
            //             end = moment().endOf('week').format('YYYY-MM-DD HH:mm:ss');
            //             self.dateFilter.fromFilterDate = start;
            //             self.dateFilter.toFilterDate = end;
            //             this.isFrom = true;
            //             this.isTo = true;
            //             this.isDisable = false;
            //             break;
            //         case '2':
            //             start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
            //             end = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
            //             self.dateFilter.fromFilterDate = start;
            //             self.dateFilter.toFilterDate = end;
            //             this.isFrom = true;
            //             this.isTo = true;
            //             this.isDisable = false;
            //             break;
            //         case '3':
            //             start = moment(firstOfThisMonth).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            //             end = moment(firstOfThisMonth).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            //             self.dateFilter.fromFilterDate = start;
            //             self.dateFilter.toFilterDate = end;
            //             this.isFrom = true;
            //             this.isTo = true;
            //             this.isDisable = false;
            //             break;
            //         case '4':
            //             start = moment(firstOfThisMonth).subtract(4, 'months').startOf('month').format('YYYY-MM-DD');
            //             end = moment(firstOfThisMonth).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            //             self.dateFilter.fromFilterDate = start;
            //             self.dateFilter.toFilterDate = end;
            //             this.isFrom = true;
            //             this.isTo = true;
            //             this.isDisable = false;
            //             break;
            //         default:
            //             start = '';
            //             end = '';
            //             this.isFrom = false;
            //             this.isTo = false;
            //             this.isDisable = true;
            //     }
            //
            //     this.doFilterDate();
            // },
            checkOnChaneTo() {
                this.isTo = true;
                this.doFilterDate();
            },
            checkOnChaneFrom() {
                this.isFrom = true;
                this.isDisable = false;
                this.doFilterDate();
            },
            doFilterDate() {
                if (this.isFrom && this.isTo) {
                    this.$events.fire('filter-date', this.dateFilter);
                }
            },
            doFilterDateWithSearch(filterData) {
                this.$events.fire('filter-date-and-search-bar', this.dateFilter, filterData);
            }
        },
        events: {
            autoFireDateRangeFilter(filterData) {
                this.doFilterDateWithSearch(filterData);
            }
        }
    }
</script>

<style scoped lang="scss">


</style>