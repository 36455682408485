import UserService from "../../../services/UserService";

export default {
    namespaced: true,
    state: {
        user: null,
        permissions: null,
        features : null,
        data : null,
        terminologies : []
    },
    getters: {
        user: (state) => state.user,
        permissions: (state) => state.permissions,
        features: state => state.features,
        data: (state) => state.data,
        terminologies: state => state.terminologies,
        getUserData:  (state) => (property) => {
            return _.get(state, property);
        }
    },
    mutations: {
        setUserData(state, data) {
            state.user = data.user;
            state.permissions = data.permissions;
            state.features = data.features;
            state.data = data;
            state.terminologies = data.terminologies;
        },
        clearUserData(state) {
            state.user = null;
            state.permissions = null;
            state.features = null;
            state.data = null;
            state.terminologies = [];
        }

    },
    actions: {
        fetchAuthUserData({commit, dispatch}) {
            return UserService.fetchUserData().then(async userData => {
                let _response = userData.data.result;
                await commit('setUserData', _response);

                if (_response?.user?.two_factor == 0){
                    await dispatch('auth/authenticated', true, {root:true})
                }

                return _response;

                //removing page loader
                $('#pageLoader').fadeOut(1500);
            })
        },
        clearUserData({commit}){
            commit('clearUserData');
        },
        setTwoFactorVerified({commit}, verified) {

        }
    }
};