<template>

    <div class="input-group mb-12 activity-log-search-row commissions-payment-report-filters" >
      <div class="left-clum">
        <div class="cft-filtre-max-width">
          <div class="cpt-item">
            <div class="datepic">
              <div class="input-group-prepend">
                  <label class="input-group-text"> From </label>
              </div>
              <date-picker
                      id="vueTableFromDate"
                      @selected="checkOnChaneFrom"
                      v-model="dateFilter.fromFilterDate"
                      :disabled-dates="{ from: disableDates('from', dateFilter.toFilterDate) }"
                      :class="'date_picker'"
                      :format="'yyyy-MM-dd'"
                      :bootstrap-styling="true"
                      :placeholder="'Please Select'"
                      :style="['padding-left:0']"
                      :calendar-button="true"
                      :calendar-button-icon="'fa fa-calendar'"
              />
            </div>
          </div>

          <div class="cpt-item">
            <div class="datepic">
              <div class="input-group-prepend">
                  <label class="input-group-text">To</label>
              </div>
              <date-picker
                      id="vueTableToDate"
                      :disabled=isDisable
                      @selected="checkOnChaneTo"
                      :disabled-dates="{ to: disableDates('to', dateFilter.fromFilterDate) }"
                      v-model="dateFilter.toFilterDate"
                      :class="'date_picker'"
                      :format="'yyyy-MM-dd'"
                      :bootstrap-styling="true"
                      :placeholder="'Please Select'"
                      :style="['padding-left:0']"
                      :calendar-button="true"
                      :calendar-button-icon="'fa fa-calendar'"
              />
            </div>
          </div>

          <div class="cpt-item">
              <div class="input-group-prepend">
                  <label class="input-group-text"> Date Period </label>
              </div>
              <select class="form-control" @change="filterRange"
                      v-model="getSelectedValue">
                  <option value="">Please Select</option>
                  <option value="0">Last 24 hours</option>
                  <option value="1">Last Week</option>
                  <option value="2">This Month</option>
                  <option value="3">Last Month</option>
                  <option value="4">Last 3 Months</option>
              </select>
          </div>

<!--          <div class="cpt-item" v-if="showExtraFilterInDateRange">-->
<!--              <div class="input-group-prepend">-->
<!--                  <label class="input-group-text"> Status </label>-->
<!--              </div>-->
<!--              <select class="form-control" @change="doFilter" v-model="filters.status">-->
<!--                  <option value="">All</option>-->
<!--                  <option value="pending">Pending</option>-->
<!--                  <option value="approved">Approved</option>-->
<!--                  <option value="rejected">Reject</option>-->
<!--              </select>-->
<!--          </div>-->
        </div>
      </div>
      <div class="right-clum">
        <div class="right-cpt-item">
              <form class="form-inline filter-bar">
                  <div class="form-group">
                      Search: <input type="text" v-model="filterText" class="form-control search-feild form-control-sm" @keyup="doFilterDateWithSearch"
                                     :placeholder="searchPlaceholder">
                  </div>
              </form>
          </div>
        </div>
    </div>

</template>

<script>
    import DatePicker from 'vuejs-datepicker';

    export default {
        components: {
            DatePicker
        },
        data() {
            return {
                getSelectedValue: '',
                isFrom: false,
                isTo: false,
                isDisable: true,
                dateFilter: {
                    fromFilterDate: '',
                    toFilterDate: ''
                },
                filterText: '',
                stopTableReload: false,
                showExtraFilterInDateRange: true,
                filters: {
                    status: '',
                },
            }
        },
        props: {
            disconnectFilters : {
                type: Boolean,
                default: false
            },
            showExtraFilter : {
                type: Boolean,
                default: true
            },
            searchPlaceholder: {
                type: String,
                default: ''
            }
        },
        methods: {
            disableDates(mode, date) {
                let modified = new Date(Date.now());
                switch (mode) {
                    case 'to':
                        modified = date ? new Date(date) : new Date(new Date().setFullYear(new Date().getFullYear() - 1));
                        break;
                    case  'from':
                        modified = date ? new Date(date) : new Date(Date.now());
                        break;
                }
                return modified;
            },
            filterRange() {
                let self = this;
                let start = '';
                let end = '';
                let firstOfThisMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                switch (this.getSelectedValue) {
                    case '0':
                        start = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
                        end = moment().format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '1':
                        start = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
                        end = moment().format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '2':
                        start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        end = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '3':
                        start = moment(firstOfThisMonth).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        end = moment(firstOfThisMonth).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '4':
                        start = moment(firstOfThisMonth).subtract(4, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        end = moment(firstOfThisMonth).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    default:
                        console.log('default');
                        start = '';
                        end = '';
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = false;
                        this.isTo = false;
                        this.isDisable = true;
                }

                this.doFilterDateWithSearch();
            },
            checkOnChaneTo() {
                this.getSelectedValue = "";
                this.isTo = true;
                this.doFilterDateWithSearch();
            },
            checkOnChaneFrom() {
                this.getSelectedValue = "";
                this.isFrom = true;
                this.isDisable = false;
                this.doFilterDateWithSearch();
            },
            doFilterDate() {
                if (this.isFrom && this.isTo) {
                    this.$events.fire('filter-date', this.dateFilter);
                }
            },
            doFilterDateWithSearch() {

                if (this.filterText.length > 2 ){
                    this.$events.fire('filter-date-and-search-bar', this.dateFilter, this.filterText);
                }

                if ( this.filterText.length == 0){
                    this.$events.fire('filter-date-and-search-bar', this.dateFilter, this.filterText);
                }

            },
            doFilter() {
                let self = this;
                let filters = {
                    status: self.filters.status
                }
                self.$events.fire('filter-custom-filter', filters);
            },
        },
        mounted() {
            let self = this;
            let start = moment().subtract(1, 'days').format('YYYY-MM-DD');
            let end = moment().format('YYYY-MM-DD HH:mm:ss');
            self.dateFilter.fromFilterDate = '';
            self.dateFilter.toFilterDate = '';
            this.isFrom = false;
            this.isTo = false;
            this.isDisable = false;
        },
        events: {
            // autoFireDateRangeFilter(filterData) {
            //     this.doFilterDateWithSearch(filterData);
            // }
        }
    }
</script>

<style scoped>

    .cal-item {
        width: 200px !important;
        display: inline-block;
    }

    .search-filter {
        width: 300px !important;
        position: relative;
    }

    .search-filter:before {
        font-family: 'osfont' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 35%;
        top: 45%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 16px;
        content: "\E92C";
        color: rgba(0, 0, 0, 0.4);
        z-index: 9;
    }

    .search-filter .input-group-prepend, .search-filter .input-group-append {
        width: 30%;
    }

    .search-filter .input-group-text {
        background-color: transparent;
        border: 0;
        color: #3E4B5B;
    }

    .cal-item .form-control {
        display: inline-block;
        float: left;
        width: 50%;
        font-size: 0.7rem !important;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0 0.25rem 0.25rem 0;
        padding-left: 5px;
    }

    .search-filter .form-control {
        background-color: #eeeeee;
        border: none !important;
        border-radius: 33px;
        min-width: 180px;
        margin-bottom: 3px;
        outline: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        padding-left: 50px;
        width: 70%;
    }

    .filter-area .filter-bar {
        padding: 0px 0px 5px;
        float: left;
    }

    .cal-item .input-group-prepend label {
        background-color: #007bff;
        color: #fff;
        font-weight: 500;
        border: 1px solid #007bff;
        min-width: 57px;
        border-radius: 4px 0 0 4px;
        font-size: 12px;
        padding: 7px 7px;
        display: block;
        width: 100%;
        text-align: left;
        max-height: 35px;
    }

    .date_picker {
        background-color: #e9ecef !important;
    }

</style>
