import { getField, updateField } from 'vuex-map-fields';
import AuthService from "../../../services/AuthService";
import UserService from "../../../services/UserService";

export default {
    namespaced: true,
    state: {
        username: null,
        password: null,
        isAuthenticated : false
    },
    getters: {
        getField,
        username: (state) => state.username,
        password: (state) => state.password,
        isAuthenticated: state => state.isAuthenticated,
    },
    mutations: {
        updateField,
        setAuthData(state, data) {
            state.username = data.username;
            state.password = data.password;
        },
        setIsAuthenticated: (state, isAuthenticated) => {
            state.isAuthenticated = isAuthenticated;
        }
    },
    actions: {
        login({commit, dispatch}, data) {
            return new Promise((resolve, reject) => {
                AuthService.login(data).then(async response => {
                    let _response = response.result;

                    if(_response){
                        await dispatch('userdata/fetchAuthUserData', {}, {root:true})
                        resolve(_response);
                    }
                    //removing page loader
                    $('#pageLoader').fadeOut(1500);
                }).catch(function (error) {
                    commit('setIsAuthenticated', false);
                    reject(error);
                });
            });
        },

        logout({commit, dispatch}) {
            return new Promise((resolve, reject) => {
                AuthService.logout().then(response => {
                    console.log("response", response)

                    if(response){
                        commit('setIsAuthenticated', false);
                        dispatch('userdata/clearUserData', {}, {root:true});
                        dispatch('profile/clearProfileData', {}, {root:true});

                        resolve(true);
                    }
                    //removing page loader
                    $('#pageLoader').fadeOut(1500);
                }).catch(function (error) {
                    reject(error);
                });
            });
        },
        authenticated({commit}, authenticated){
            commit('setIsAuthenticated', authenticated);
        },
        verifyTwoFactorCode({commit}, code){
            return new Promise((resolve, reject) => {
                AuthService.verifyTwoFactorCode(code).then(response => {
                    console.log("response", response)

                    if(response){
                        resolve(response.data.result);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            });
        },
        sendPasswordResetLink({state},email) {
            return new Promise((resolve, reject) => {
                AuthService.sendPasswordResetLink(email).then(response => {

                    if(response){
                        resolve(response.data.message);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            });
        },
        resetPassword({state}, postData) {
            return new Promise((resolve, reject) => {
                AuthService.resetPassword(postData).then(response => {

                    if(response){
                        resolve(response);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            });
        }
    }
};