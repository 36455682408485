<template>
    <scan-authenticator :hideClose="1" :clickToClose="false" ref="ScanAuthenticatorModal" name="ScanAuthenticatorModal"
                        transition="pop-out" :width="700" :headerTitle="modelTitle" id="auth-modal">

        <template slot="body" style="min-height: 698px;">


            <!--Start QR Option Selection-->
            <div class="qr-option-area" v-if="showDefault==0">

                <div class="row">

                    <div class="col-sm-12" v-if="showDefault==0">

                        <h4 class="steps-qr text-left optiontitle">Choose a Secure Method to Proceed</h4>

                    </div>

                </div>

                <div class="row">

                    <div class="col-sm-6">
                        <div class="option-auth">

                            <div class="img-icon">
                                <img src="../../assets/img/google-auth.png"/>
                            </div>

                            <h5>Authenticator App</h5>

                            <p>Setup an authenticator app <br/>to generate Login Codes.</p>

                            <div class="auth-redio">

                                <div class="location-category">
                                    <div class="radio-btn-area align-set">
                                        <input id="new" type="radio" name="auth_type" value="1"
                                               v-model="security_method" @click="showError=false">
                                        <label for="new">

                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="option-auth">

                            <div class="img-icon">
                                <img src="../../assets/img/sms-auth.png"/>
                            </div>

                            <h5>Text Message</h5>

                            <p>We'll send a Text Message with a <br/>Login Code to your mobile.</p>

                            <div class="auth-redio">

                                <div class="location-category">
                                    <div class="radio-btn-area align-set">
                                        <input id="new2" type="radio" name="auth_type" value="2"
                                               v-model="security_method" @click="showError=false">
                                        <label for="new2">

                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <div v-if="showError" class="row" ref="errormessage">

                    <div class="col-sm-12">

                         <span class="text-danger">
                            Please select a Security Method
                        </span>

                    </div>

                </div>

            </div>
            <!--End QR Option Selection-->


            <!--Start QR Code Scan-->
            <div class="col-sm-12" v-if="showDefault==1">

                <h4 class="steps-qr">Steps to Follow</h4>

                <ul class="qrcode-area">

                    <li><span>1</span>
                        Get the Google Authenticator App from the
                        <a target="_blank"
                           href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8">App
                            Store</a> or Andriod <a target="_blank"
                                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Play
                            Store</a>.
                    </li>

                    <li><span>2</span>
                        Using the App, Tap on the <strong>Add</strong> Button.
                    </li>

                    <li><span>3</span>
                        Choose the option to <strong>Scan a Barcode.</strong>
                    </li>

                    <li><span>4</span>
                        Use app to scan the QR Code below.
                    </li>

                </ul>


                <div class="qrcode" style="background-image: url(../../../img/mobile-bg.png);">
                    <img style="background-image: url(../../../img/focus.png); border: none !important;"
                         :src="qrImage"/>

                    <a href="#" v-on:click="ShowModel">Having trouble?</a>
                </div>

            </div>
            <!--End QR Code Scan-->


            <!--Start Show mannual Entry Code-->
            <div class="col-sm-12" v-else-if="showDefault==2">

                <h4 class="steps-qr">Steps to Follow</h4>

                <div class="outer-list" style="background-color:transparent !important;">

                    <ul class="qrcode-area">

                        <li><span>1</span>
                            In the App, Tap the <strong>Add</strong> Button.
                        </li>

                        <li><span>2</span>
                            Choose the <strong>Enter a Provided Key</strong>.
                        </li>

                        <li><span>3</span>
                            Enter Account Name and Key below.

                            <div class="number-generate">
                                {{code}}
                                <span>Space doesn't matter</span>
                            </div>
                        </li>

                    </ul>

                </div>

            </div>
            <!--End Show mannual Entry Code-->


            <!--Start Enter 6 Digit code -->
            <div class="col-sm-12 digit-code-area" v-else-if="showDefault==3">

                <div class="input-below" id="scancode-element">
                    <p class="p-text-2">Please enter the 6 digit Login Code</p>
                    <pincode-input
                            v-model="key"
                            placeholder="0"
                            class="outer-input"
                            :length="6"
                    />
<!--                    <input class="outer-input" type="text" v-model="key" id="pin_code">-->
                    <div class="text-danger">{{errorsText}}</div>
                </div>


            </div>
            <!--Start Enter 6 Digit code -->


            <!--Start Success Popup -->
            <div class="col-sm-12 verfit-alert" v-else-if="showDefault==4">

                <div class="show-suceess-img">
                    <img src="img/shield.png"/>
                </div>
                <p>
                    Verification Successful
                </p>


            </div>
            <!--End Success Popup -->

        </template>

        <template slot="footer">
            <div class="buton-2fa">

                <button class="btn btn-danger" type="button" v-if="showDefault!=4" @click="canceled">Cancel</button>
                <button v-if="showDefault==0" v-on:click="ShowQrScan" class="btn btn-primary" type="button"> Proceed
                </button>
                <button v-if="showDefault==1" v-on:click="ShowDigit" class="btn btn-primary" type="button"> Proceed
                </button>
                <button v-if="showDefault==2" v-on:click="ShowDigit" class="btn btn-primary" type="button"> Proceed
                </button>
                <button data-dismiss="modal" v-on:click="modalClose" v-if="showDefault==4"
                        class="btn btn-primary center-btn" type="button">Complete
                </button>

            </div>
        </template>

    </scan-authenticator>

</template>

<script>
    import PincodeInput from 'vue-pincode-input';
    import ScanAuthenticator from './../modal/VueJsModal';

    // require('bootstrap-pincode-input');

    export default {
        components: {
            ScanAuthenticator,
            PincodeInput
        },
        props: [
            'user_id'
        ],
        data: () => ({
            modelTitle: 'Two-Factor Authentication',
            showDefault: 0,
            qrImage: '',
            key: null,
            errorsText: null,
            security_method: 1,
            code: null,
            showError: false
        }),
        methods: {
            canceled() {
                this.$emit('closed');
                this.$router.push({name: 'committee_member_profile_security'});

            },
            ShowQrScan() {
                if (this.security_method) {
                    //this.showDefault = 1;
                    if (this.security_method == 1) {
                        let self = this;
                        XhrRequest.send(XhrRequest.XHR_GET_METHOD, '2fa/checkmobile').then(response => {
                            if (response.data.result)
                                self.showDefault = 1;
                            else
                                this.$toast.error('Please update your Mobile Number under "Account Settings", before you enable this feature.');
                        }).catch(error => {
                            this.$toast.error("Something went wrong!");
                        });

                    } else {
                        XhrRequest.send(XhrRequest.XHR_POST_METHOD, '2fa/sendsmscode').then(response => {
                            this.showDefault = 3;
                        }).catch(error => {
                            if (error.data.status_code != 422)
                                this.$toast.error("Something went wrong!");
                        });
                    }
                } else
                    this.showError = true;
            },
            ShowModel() {
                this.showDefault = 2;
            },
            ShowDigit() {
                this.showDefault = 3;
            },
            verify() {
                let self = this;
                let sendData = {};
                sendData = {
                    key: this.key
                }
                XhrRequest.send(XhrRequest.XHR_POST_METHOD, '2fa/verify', sendData)
                    .then(response => {
                        if (response.data.result) {
                            self.showDefault = 4;
                            this.$emit('enable', self.security_method);
                        } else {
                            self.errorsText = "Invalid Code Entered";
                        }
                    })
                    .catch(error => {

                    });

            },
            modalClose() {
                this.$router.push({name: 'committee_member_profile_security'});
            },
            generateAuthentication() {
                let self = this;
                XhrRequest.send(XhrRequest.XHR_GET_METHOD, '2fa').then(response => {
                    self.qrImage = response.data.result.image_url;
                    self.code = response.data.result.key;
                });

            }
        },
        mounted() {
            this.$refs.ScanAuthenticatorModal.show();
            this.generateAuthentication();
        },
        updated() {
            if (this.showDefault == 3) {
                let self = this;
                console.log("key", self.key.toString().length);
                if (self.key.toString().length >= 6){
                    self.verify();
                }

                // $('#pin_code').pincodeInput({
                //     hidedigits: false,
                //     inputs: 6,
                //     complete: function (value) {
                //         self.key = value;
                //         self.verify();
                //     }
                // });
                // $('#pin_code').pincodeInput().data('plugin_pincodeInput').focus();
            } else {
                $(".pincode-input-container").remove();
            }
        },


    }
</script>

<style lang="scss">
    @import './../../assets/styles/sass/_components/2fa-authentication/authentication.scss';
    @import './../../assets/styles/sass/_components/radio/radio';
</style>
