<template>
    <div class="alert alert-danger">
        <strong>Danger!</strong> Indicates a dangerous or potentially negative action.
    </div>
</template>


<script>
    export default {}
</script>
