<template>
    <div>
        <bread-crumb :links="[{name:'home', label:'Home'},{label: 'Quotations'}]"/>

        <div class="row">
            <div class="col-sm-12">
                <div class="element-wrapper" style="padding-bottom: 0rem;">

                    <div class="element-box-tp datatable-area">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="form-header">Quotations</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="filters-min-div">
                                    <h3>Filters</h3>
                                    <div class="row">

                                        <template v-for="filter in filterArray">
                                            <template  v-if="filter.active_status == 1">
                                                <div class="col-md-2">
                                                    <div class="filter-input">
                                                        <label>{{filter.label_name}}</label>

                                                        <input type="text" v-if="textBoxDisplay(filter.label_key)"
                                                               :name="filter.label_key" v-model="formData[filter.label_key]"
                                                               v-on:keyup="searchList">

                                                        <div class="datepic" v-if="filter.label_key == 'received_date'">
                                                            <date-picker @selected="dateReceviedSelected"
                                                                         :class="'date_picker'"
                                                                         :format="dateFormat"
                                                                         v-model="received_date"
                                                                         clear-button/>
                                                        </div>

                                                        <v-select label="full_name"
                                                              :clearable="(formData[filter.label_key] != '')? true : false"
                                                              :options="managerList"
                                                              v-if="filter.label_key == 'strata_manager'"
                                                              :loading="managerIsLoading"
                                                              @input="searchList"
                                                              :inputId="'strataManagerInput'" 
                                                              :searchable="true"
                                                              v-model="strata_manager">
                                                            <template slot="no-options">
                                                                No managers found..
                                                            </template>
                                                            <template v-slot:option="option">
                                                                {{ option.first_name }} {{ option.last_name }}
                                                            </template>

                                                            <template v-slot:selected-option="option">
                                                                {{ option.first_name }} {{ option.last_name }}
                                                            </template>
                                                        </v-select>

                                                        <select v-if="filter.label_key == 'status'"
                                                                v-model="formData[filter.label_key]"
                                                                id="statusDrop" @change="searchList">
                                                            <option value="">All</option>
                                                            <option v-for="item in statusGroups" :value="item.id">{{item.label}}</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </template>

                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive wo-and-qr-table">

                            <div class="row">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <vue-table2 ref="quotationList"
                                                    :fields="columns"
                                                    :api-url="apiUrl"
                                                    data-path="result.data"
                                                    pagination-path="result"
                                                    :sort-order="initSort"
                                                    :show-filter="false"
                                                    :show-custom-filter="true"
                                                    v-on:onRowClicked="requestRowClick">

                                            <template v-slot:customfilters>
                                                <div class="custom-filters filter-bar">
                                                    <div class="wo-row">
                                                        <div class="col-md-6">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="wo-serch">
                                                                <div class="form-group">
                                                                    Search:
                                                                    <input type="text" placeholder=""
                                                                           class="form-control form-control-sm"
                                                                           v-model="formData.filter"
                                                                           v-on:keyup="searchList">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <template slot="description" slot-scope="props">
                                                <span :title=props.rowData.description_full>{{props.rowData.description}}</span>
                                            </template>

                                            <template slot="strata_manager" slot-scope="props">
                                                <span :title=props.rowData.strata_manager_full>{{props.rowData.strata_manager}}</span>
                                            </template>

                                        </vue-table2>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import BreadCrumb from "../../components/_global/breadcrumb/BreadCrumb";
    import VueTable2 from "../../components/datatable/VueTable2";
    import QuotationService from "../../services/QuotationService";
    import ErrorMessage from "../../components/validation/ErrorMessage";
    import DatePicker from 'vuejs-datepicker';
    import vSelect from 'vue-select';
    import ListAction from "./ListAction";
    import { debounce } from 'lodash';
    import moment from 'moment';
    import {mapGetters} from "vuex";
    import AppConstants from "../../const/AppConstants";

    Vue.component('list-actions', ListAction);
    //Vue.defineComponent('list-actions', ListAction);

    export default {
        name: "QRDashboard",
        components: {
            BreadCrumb,
            VueTable2,
            QuotationService,
            DatePicker,
            ErrorMessage,
            vSelect,
            ListAction
        },
        data() {
            return {
                columns: [],
                initSort: [],
                apiUrl:'',
                filterArray: [],
                columnArray:[],
                dateFormat: 'yyyy-MM-dd',
                formData: {
                    filter: '',
                    qr_number: '',
                    received_date: '',
                    strata_manager: '',
                    status: '',
                    strata_plan_no: '',
                    strata_plan_name: '',
                    supplier: ''
                },
                managerList: [],
                managerIsLoading: false,
                received_date:'',
                strata_manager: '',
                statusGroups: []
            }
        },
        computed: {
            ...mapGetters({
                user: "userdata/user"
            }),
        },
        methods:{
            initListTable(){
                let self = this;
                self.filterColumns();
                self.searchList();

                $.each(self.columnArray, function (key, value) {

                    let columnArray = [];
                    columnArray['title'] = value.label_name;

                    if (value.label_key == 'strata_manager' || value.label_key == 'description') {
                        columnArray['name'] = '__slot:'+value.label_key;
                    }else{
                        columnArray['name'] = value.label_key;
                    }

                    if (value.active_status == 1) {
                        columnArray['visible'] = true;
                    } else {
                        columnArray['visible'] = false;
                    }

                    if (value.filter_direction != null) {
                        columnArray['sortField'] = value.label_key;

                        //sorting
                        self.initSort.push(
                            {
                                field: value.label_key,
                                direction: value.filter_direction
                            }
                        );
                    }

                    self.columns.push(columnArray);

                });

                let lastColumnArray = [];
                lastColumnArray['name'] = '__component:list-actions';
                lastColumnArray['title'] = 'Actions';
                self.columns.push(lastColumnArray);

            },
            setDataFilters() {
                let self = this;
                $.each(self.user?.workspace?.filters, function(key, value) {
                    if (value.utility == AppConstants.WO_TYPE.QR){
                        self.filterArray.push(value);
                    }
                });
            },
            textBoxDisplay(key) {
                if (key == 'qr_number' || key == 'strata_plan_no' || key == 'strata_plan_name' || key=='supplier') {
                    return true;
                }
                return false;
            },
            asyncStrataManagers(search) {
                let self = this;
                self.managerIsLoading = true;
                QuotationService.searchManager(search).then((response) => {
                    self.managerList = response.data.result;
                    self.managerIsLoading = false;
                });
            },
            searchList() {
                this.getList(true, this);
            },
            getList: _.debounce((loading, vm) => {
                vm.tableAPIUrl();
                vm.$emit('filter-custom-filter');
            }, 500),
            dateReceviedSelected(date) {
                if (date != null) {
                    this.formData.received_date = moment(date).format('YYYY-MM-DD');
                } else {
                    this.formData.received_date = '';
                }

                this.searchList();
            },
            getFormData() {
                let self = this;

                if (self.strata_manager != null && self.strata_manager != undefined && self.strata_manager != '') {
                    self.formData.strata_manager = self.strata_manager.id;
                } else {
                    self.formData.strata_manager = '';
                }

                return self.formData;
            },
            getStatusGroups() {
                let self = this;
                QuotationService.status().then((response) => {
                    self.statusGroups = response.data.result;
                });
            },
            filterColumns()
            {
                let self = this;

                $.each(self.user?.workspace?.columns, function(key, value) {
                    if (value.utility == AppConstants.WO_TYPE.QR){
                        self.columnArray.push(value);
                    }
                });
            },
            tableAPIUrl() {
                let queryData = Object.keys(this.getFormData()).map(key => key + '=' + this.getFormData()[key]).join('&');
                this.apiUrl = 'committee/quotations/list?' + queryData;
            },
            requestRowClick(rowData, index, event) {
                this.$router.push(
                    {
                        name: 'quote_request_view',
                        params: {
                            'id': rowData.job_group_id
                        }
                    }
                );
            },
        },
        beforeMount() {
            let self = this;
            self.asyncStrataManagers('');
            self.getStatusGroups();
            self.setDataFilters();
            self.initListTable();
        },
        mounted() {

            this.$nextTick(() => {
                if (this.$route.params.status_group){
                    this.formData.status = this.$route.params.status_group;
                    this.searchList();
                }
            })
        },
        events: {
            newActivity(response){
                this.$refs.quotationList.hardReload();
            }
        }
    }
</script>

<style scoped>

</style>
