<template>
    <div>
        <div class="element-box new-form-theme timeline-sction vcontainer"
             style="background-color: #ffffff !important;">

            <div class="row">

                <div class="col-sm-12">

                    <div class="inner-title-section">

                        <div class="row">

                            <div class="col-md-12">

                                <h5 class="form-header" style="padding-bottom: 0px; border-bottom: none;"
                                    @click="check">
                                    Detailed Activity Log
                                </h5>

                            </div>

                        </div>

                    </div>

                </div>


            </div>


            <div class="row">

                <div class="col-sm-12">

                    <div class="timed-activities padded" v-if="jobInfo.job_detail!==undefined">

                        <div class="timed-activity" v-for="logItem in jobInfo.job_detail.log.job_process">

                            <div class="ta-date">
                                <span>{{logItem.datetime.datetime_formatted}}</span>
                                <i class="far fa-clock clock"></i>
                            </div>

                            <div class="ta-record-w">
                                <div class="ta-record-section">

                                    <div class="ta-record">
                                        <div class="ta-activity">{{logItem.title}}
                                        </div>
                                        <div class="ta-timestamp">

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="22" height="22"
                                                 viewBox="0 0 48 48">
                                                <path d="M23.99 4C12.94 4 4 12.95 4 24s8.94 20 19.99 20C35.04 44 44 35.05 44 24S35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16zm1-26h-3v12l10.49 6.3L34 29.84l-9-5.34z"/>
                                            </svg>

                                            <strong>{{logItem.datetime.time_formatted}}</strong>
                                        </div>
                                    </div>

                                    <div class="line-row">

                                        <div class="row">
                                            <div class="col-sm-4" v-for="(dataItem,dataKey) in logItem.data"
                                                 v-if="dataItem.display && dataItem.type==='normal'">
                                                <div class="time-boxes">
                                                    <div class="label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             fill="#000" viewBox="0 0 48 48">
                                                            <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"/>
                                                        </svg>
                                                        {{dataItem.title}}
                                                    </div>
                                                    <div class="label-value" v-if="!Array.isArray(dataItem.value)">
                                                        <span v-if="dataItem.title=='Start Time'">
                                                             {{momentTime().unix(logItem.data.estimated_timestamp.value).tz($user.timezone).format('hh:mm A')}}
                                                        </span>
                                                        <span v-else-if="dataItem.title=='Estimated Start Time'">
                                                             {{timeFormat(dataItem.value).format('Do MMMM Y hh:mm A')}}
                                                        </span>
                                                        <span v-else-if="dataItem.title=='Star Rating'"
                                                              v-html="starRatings(dataItem.value) + ' '+ dataItem.value">
                                                        </span>
                                                        <span v-else>
                                                             {{dataItem.value}}
                                                        </span>
                                                    </div>
                                                    <div class="label-value" v-if="Array.isArray(dataItem.value)">
                                                        <div v-for="arrayItem in dataItem.value">{{arrayItem}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-for="(dataItem,dataKey) in logItem.data"
                                                 v-if="dataItem.display && dataItem.type==='text'">
                                                <div class="time-boxes">
                                                    <div class="label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             fill="#000" viewBox="0 0 48 48">
                                                            <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"/>
                                                        </svg>
                                                        {{dataItem.title}}
                                                    </div>
                                                    <div class="label-value text-log-content" v-if="!Array.isArray(dataItem.value)">
                                                        {{dataItem.value}}
                                                    </div>
                                                    <div class="label-value text-log-content" v-if="Array.isArray(dataItem.value)">
                                                        <div v-for="arrayItem in dataItem.value">{{arrayItem}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-for="(dataItem,dataKey) in logItem.data"
                                                 v-if="dataItem.display && dataItem.editable">
                                                <div class="time-boxes">
                                                    <div class="label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             fill="#000" viewBox="0 0 48 48">
                                                            <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"/>
                                                        </svg>
                                                        {{dataItem.title}}
                                                        <span class="log-edit" @click="editBlock(logItem, dataItem)">
                                                            <svg  data-v-34d80852="" data-v-886377a0="" data-v-629e596e="" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="17" height="17" viewBox="0 0 485.219 485.22" xml:space="preserve" style="left: 1px;fill: #ffffff !important;width: 14px !important;"><g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""><path data-v-34d80852="" data-v-886377a0="" data-v-629e596e="" d="M467.476,146.438l-21.445,21.455L317.35,39.23l21.445-21.457c23.689-23.692,62.104-23.692,85.795,0l42.886,42.897
		C491.133,84.349,491.133,122.748,467.476,146.438z M167.233,403.748c-5.922,5.922-5.922,15.513,0,21.436
		c5.925,5.955,15.521,5.955,21.443,0L424.59,189.335l-21.469-21.457L167.233,403.748z M60,296.54c-5.925,5.927-5.925,15.514,0,21.44
		c5.922,5.923,15.518,5.923,21.443,0L317.35,82.113L295.914,60.67L60,296.54z M338.767,103.54L102.881,339.421
		c-11.845,11.822-11.815,31.041,0,42.886c11.85,11.846,31.038,11.901,42.914-0.032l235.886-235.837L338.767,103.54z
		 M145.734,446.572c-7.253-7.262-10.749-16.465-12.05-25.948c-3.083,0.476-6.188,0.919-9.36,0.919
		c-16.202,0-31.419-6.333-42.881-17.795c-11.462-11.491-17.77-26.687-17.77-42.887c0-2.954,0.443-5.833,0.859-8.703
		c-9.803-1.335-18.864-5.629-25.972-12.737c-0.682-0.677-0.917-1.596-1.538-2.338L0,485.216l147.748-36.986
		C147.097,447.637,146.36,447.193,145.734,446.572z"></path></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g> <g data-v-34d80852="" data-v-886377a0="" data-v-629e596e=""></g></svg>
                                                        </span>
                                                    </div>
                                                    <div class="label-value text-log-content" v-if="!Array.isArray(dataItem.value)">
                                                        {{dataItem.value}}
                                                    </div>
                                                    <div class="label-value text-log-content" v-if="Array.isArray(dataItem.value)">
                                                        <div v-for="arrayItem in dataItem.value">{{arrayItem}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-for="(dataItem,dataKey) in logItem.data"
                                                 v-if="dataItem.display && dataItem.type==='list'">
                                                <div class="time-boxes">
                                                    <div class="label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             fill="#000" viewBox="0 0 48 48">
                                                            <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"/>
                                                        </svg>
                                                        {{dataItem.title}}
                                                    </div>
                                                    <div class="label-value text-log-content">
                                                        <ul>
                                                            <template v-for="text in splitJoin(dataItem.value)">
                                                            <li v-if="text">{{ text }}</li>
                                                            </template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-4" v-for="(dataItem,dataKey) in logItem.data"
                                                 v-if="dataItem.display && dataItem.type==='rating'">
                                                <div class="time-boxes">
                                                    <div class="label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             fill="#000" viewBox="0 0 48 48">
                                                            <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"/>
                                                        </svg>
                                                        {{dataItem.title}}
                                                    </div>
                                                    <div class="label-value" v-if="!Array.isArray(dataItem.value)">
                                                        <span v-if="dataItem.title=='Star Rating'"
                                                              v-html="starRatings(dataItem.value)">
                                                        </span>
                                                    </div>
                                                    <div class="label-value" v-if="Array.isArray(dataItem.value)">
                                                        <div v-for="arrayItem in dataItem.value">{{arrayItem}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-for="(dataItem,dataKey) in logItem.data"
                                                 v-if="dataItem.type==='images' && dataItem.display">
                                                <div class="time-boxes">
                                                    <div class="label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             fill="#000" viewBox="0 0 48 48">
                                                            <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"/>
                                                        </svg>
                                                        {{dataItem.title}}
                                                    </div>
                                                    <div class="label-value">


                                                        <div class="row">

                                                            <div class="col-md-2" v-for="imageItem in dataItem.value"
                                                                 v-if="imageItem.type=='pdf'">
                                                                <div class="job-lg-img">
                                                                    <a :href="imageItem.resource" target="_blank">
                                                                        <img id="job-log-pdf" :src="imageItem.thumb">
                                                                    </a>
                                                                    <!--<span v-if="imageItem.name">{{imageItem.name}}</span>-->
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2" v-for="imageItem in dataItem.value"
                                                                 v-if="(imageItem.type=='xlsx' || imageItem.type=='doc' || imageItem.type=='mp4' || imageItem.type=='csv' || imageItem.type=='txt')">
                                                                <div class="job-lg-img">

                                                                    <a :href="imageItem.resource" target="_blank">
                                                                        <img :src="'/img/icons/file-mobile-icon.jpg'">
                                                                    </a>

                                                                </div>
                                                            </div>

                                                            <div class="col-md-2"
                                                                 v-for="(imageItem, index) in dataItem.value"
                                                                 v-if="(imageItem.type=='image' || imageItem.type=='png' || imageItem.type=='jpg' || imageItem.type=='jpeg' || imageItem.type=='gif')">
                                                                <div class="image-slider-width-disc-img prv-margin">

                                                                    <!--<div @click="popOver(imageItem.title, imageItem.description)" :class="'images_'+index" v-viewer="{movable: false,container:'.vcontainer', title:getImageTitle, hide: hideOver }">
                                                                        <img :src="imageItem.resource" :alt="getImageAlt(imageItem)" :key="imageItem.resource">
                                                                    </div>-->
                                                                    <div :class="'images_'+index" v-viewer="{movable: false,container:'.vcontainer', title:getImageTitle }">
                                                                      <div class="img-div">
                                                                        <img :src="imageItem.resource" :alt="getImageAlt(imageItem)" :key="imageItem.resource">
                                                                      </div>
                                                                        <template v-if="hasImageTitle(imageItem)">
                                                                          <div class="img-discrip">
                                                                            <h6>{{ imageItem.title }}</h6>
                                                                            <p>{{ imageItem.description }}</p>
                                                                          </div>
                                                                        </template>
                                                                    </div>
                                                                  <span style="display: none">
                                                                    <img :src="imageItem.thumb" @click="show(index)"
                                                                         class="img-v"
                                                                         :data-source="imageItem.resource"
                                                                         :key="imageItem.resource"/>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>


                    </div>

                </div>

            </div>
            <div class="row" v-if="(logLoad)">

                <div class="col-sm-12 text-center load-more">

                    <button class="btn btn-primary" @click="loadMore" v-if="logCount">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                             id="Capa_1" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;"
                             xml:space="preserve" width="512px" height="512px"><g><g>
	<path d="M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021   c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z"
          data-original="#030104" class="active-path" data-old_color="#030104" fill="#FFFFFF"/>
	<path d="M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876   L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z"
          data-original="#030104" class="active-path" data-old_color="#030104" fill="#FFFFFF"/>
</g></g> </svg>
                        <span v-if="loadingJob ">Loading...</span>
                        <span v-else>Load More</span>
                    </button>

                </div>

            </div>

        </div>

        <card-popover ref="imageDescription"></card-popover>
    </div>

</template>

<script>
    import Create from '../../store/forms/job/create';
    import {createHelpers} from 'vuex-map-fields';
    import Util from "../../helpers/util";
    import moment from 'moment';
    import StarRating from 'vue-star-rating'
    import CardPopover from "../../components/card/CardPopover";


    const {mapFields} = createHelpers({
        getterType: 'job_create/getField',
        mutationType: 'job_create/updateField'
    });
    export default {
        data() {
            return {
                imageOption: {
                    url: 'data-source'
                }
            };
        }, components: {
            CardPopover,
            StarRating
        },
        name: "JobLog",
        computed: {
            ...mapFields(Create)
        },
        props: ['id', 'loadingJob','logCount','logLoad'],
        mixins: [Util],
        methods: {
            check() {

            },
            momentTime() {
                return require('moment-timezone');
            },
            popOver(title, description) {
                return (this.$refs.imageDescription && title && description) ?
                    this.$refs.imageDescription
                    .setTitle(title)
                    .setDescription(description)
                    .show()
                    : false;
            },
            hideOver() {
                return (this.$refs.imageDescription && this.$refs.imageDescription.isVisible()) ? this.$refs.imageDescription.hide() : false;
            },
            show(index) {
                const viewer = this.$el.querySelector('.images_' + index).$viewer
                viewer.show();
            },
            loadMore() {
                this.$emit("loadMore");
            },
            splitJoin(theText){
                return theText.split(', ');
            },
            timeFormat(val) {
                return moment(val);
            },
            getImageTitle(image, imageData) {
                return image.alt;
            },
            getImageAlt(imageItem) {
                if(imageItem.title && imageItem.title != 'null')
                    return imageItem.title; // + ': ' + imageItem.description;
                if(imageItem.original_name && imageItem.original_name != 'null')
                    return imageItem.original_name;
                return '';
            },
            hasImageTitle(imageItem) {
                if(imageItem.title && imageItem.title != 'null')
                    return imageItem.title; // + ': ' + imageItem.description;
                return '';
            },
            editBlock(process, item) {
                switch (item.type) {
                    // This should set from backend
                    case 'enterprise-internal-note':
                        this.$events.emit('internalNotes', {
                            'job_id': this.job_id,
                            'process_id': process.process_id,
                            'text': item.value
                        }, 'header')
                    break;
                }
            }
        },
        mounted() {
            if (this.id !== undefined) {
                this.job_id = this.id;
            } else if (this.$route.params.id !== undefined) {
                this.job_id = this.$route.params.id;
            }


        },
    }
</script>

<style scoped lang="scss">
    @import "../../assets/styles/sass/_components/timeline/timeline";

    .clock {
        overflow: visible;
        bottom: -59px;
        width: 25px;
        height: 25px;
        border: none;
        border-radius: 50%;
        color: #37474f;
        font-size: 2.7rem;
        padding-top: 0;
        position: inherit;
        background: #fbfbfb;
    }
</style>
