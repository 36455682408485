<template>
    <div class="search-with-suggestions-w">
        <div class="search-with-suggestions-modal">
            <div class="element-search">
                <input class="search-suggest-input" placeholder="Start typing to search..." type="text"/>
                <div class="close-search-suggestions">
                    <i class="os-icon os-icon-x"/>
                </div>

            </div>
            <div class="search-suggestions-group">
                <div class="ssg-header">
                    <div class="ssg-icon">
                        <div class="os-icon os-icon-box"/>
                    </div>
                    <div class="ssg-name">
                        Projects
                    </div>
                    <div class="ssg-info">
                        24 Total
                    </div>
                </div>
                <div class="ssg-content">
                    <div class="ssg-items ssg-items-boxed">
                        <a class="ssg-item" href="users_profile_big.html">
                            <div class="item-media" style="background-image: url(img/company6.png)"/>
                            <div class="item-name">
                                Integ<span>ration</span> with API
                            </div>
                        </a><a class="ssg-item" href="users_profile_big.html">
                        <div class="item-media" style="background-image: url(img/company7.png)"/>
                        <div class="item-name">
                            Deve<span>lopm</span>ent Project
                        </div>
                    </a>
                    </div>
                </div>
            </div>
            <div class="search-suggestions-group">
                <div class="ssg-header">
                    <div class="ssg-icon">
                        <div class="os-icon os-icon-users"></div>
                    </div>
                    <div class="ssg-name">
                        Customers
                    </div>
                    <div class="ssg-info">
                        12 Total
                    </div>
                </div>
                <div class="ssg-content">
                    <div class="ssg-items ssg-items-list">
                        <a class="ssg-item" href="users_profile_big.html">
                            <div class="item-media" style="background-image: url(img/avatar1.jpg)"/>
                            <div class="item-name">
                                John Ma<span>yer</span>s
                            </div>
                        </a><a class="ssg-item" href="users_profile_big.html">
                        <div class="item-media" style="background-image: url(img/avatar2.jpg)"/>
                        <div class="item-name">
                            Th<span>omas</span> Mullier
                        </div>
                    </a><a class="ssg-item" href="users_profile_big.html">
                        <div class="item-media" style="background-image: url(img/avatar3.jpg)"/>
                        <div class="item-name">
                            Kim C<span>olli</span>ns
                        </div>
                    </a>
                    </div>
                </div>
            </div>
            <div class="search-suggestions-group">
                <div class="ssg-header">
                    <div class="ssg-icon">
                        <div class="os-icon os-icon-folder"/>
                    </div>
                    <div class="ssg-name">
                        Files
                    </div>
                    <div class="ssg-info">
                        17 Total
                    </div>
                </div>
                <div class="ssg-content">
                    <div class="ssg-items ssg-items-blocks">
                        <a class="ssg-item" href="#">
                            <div class="item-icon">
                                <i class="os-icon os-icon-file-text"/>
                            </div>
                            <div class="item-name">
                                Work<span>Not</span>e.txt
                            </div>
                        </a><a class="ssg-item" href="#">
                        <div class="item-icon">
                            <i class="os-icon os-icon-film"/>
                        </div>
                        <div class="item-name">
                            V<span>ideo</span>.avi
                        </div>
                    </a><a class="ssg-item" href="#">
                        <div class="item-icon">
                            <i class="os-icon os-icon-database"/>
                        </div>
                        <div class="item-name">
                            User<span>Tabl</span>e.sql
                        </div>
                    </a><a class="ssg-item" href="#">
                        <div class="item-icon">
                            <i class="os-icon os-icon-image"/>
                        </div>
                        <div class="item-name">
                            wed<span>din</span>g.jpg
                        </div>
                    </a>
                    </div>
                    <div class="ssg-nothing-found">
                        <div class="icon-w">
                            <i class="os-icon os-icon-eye-off"/>
                        </div>
                        <span>No files were found. Try changing your query...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "suggestions"
    }
</script>

<style scoped>

</style>