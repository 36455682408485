const Utils = {
    methods: {
        workflow(data, callback) {
            XhrRequest.send(XhrRequest.XHR_POST_METHOD, 'job/workflow', data, true, XhrRequest.RESPONSE_TYPE_JSON, {
                timeout: 60000,
                displayError: false
            })
                .then((response) => {
                    this.$toast.success(response.data.message);
                    callback(response.data, true);
                })
                .catch((error) => {
                    console.error(error);
                    this.error(error.data.message);
                    callback(error, false);
                });
        },
        getLabel(name, replaceChar = ' ') {
            return _.startCase(_.toLower(name.replace(/[^0-9a-z]/gi, replaceChar)));
        },

        getName(label, replaceChar = '_') {
            return _.toLower(label.replace(/[^0-9a-z]/gi, replaceChar));
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        roundPrices(price) {
            return Number(Math.round(price + 'e2') + 'e-2').toFixed(2);
        },
        displayDate(datetime, format = 'YYYY-MM-DD HH:mm:ss', newFormat = 'YYYY-MM-DD') {
            return moment(datetime, format).format(newFormat);
        },

        humanFriendlyTime(datetime, format = 'YYYY-MM-DD HH:mm:ss', compare = 'now') {
            if (compare == 'now') {
                return moment(val, 'YYYY-MM-DD HH:mm:ss').fromNow();
            }
        },
        starRatings(rate, outof = 5) {
            let rateStarts = '';
            let starType = (Math.floor(rate)) ? 'fas' : 'far';
            let decimalRate = Math.floor(rate);
            for (let i = 0; i < outof; i++) {

                if (i == decimalRate) {
                    starType = 'far';
                }

                if (i == decimalRate && (rate - decimalRate) >= 0.3) {
                    if (decimalRate) {
                        rateStarts += '<i class="fas fa-star-half-alt"></i>';
                    } else {
                        rateStarts = '<i class="fas fa-star-half-alt"></i>' + rateStarts;
                    }

                    continue;
                }

                rateStarts += '<i class="' + starType + ' fa-star"></i>';
            }

            return rateStarts;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        separateThousands(val){
            return Number(val).toLocaleString('au', { minimumFractionDigits: 2, maximumFractionDigits:2 });
        }
    }
};
export default Utils;
