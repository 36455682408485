let appConstants = {
    USER_NO_IMAGE: '/assets/img/user.png',

    WORKFLOW: {
        JOB_UNDER_REVIEW :'under_review',
        JOB_MANAGER_APPROVED : 'manager_approved',
        JOB_MANAGER_DECLINED : 'manager_declined',
        JOB_CREATED : 'created',
        JOB_EDITED : 'edited',
        JOB_ESTIMATED_CA : 'estimated_ca',
        JOB_ESTIMATED_BOC : 'estimated_boc',
        JOB_CUSTOMER_APPROVED : 'customer_approved',
        JOB_CUSTOMER_REJECTED : 'customer_rejected',
        JOB_EMPLOYEE_ASSIGNED : 'employee_assigned',
        JOB_SEND_TO_BID : 'send_to_bid',
        JOB_DISPATCHED : 'dispatched',
        JOB_EMPLOYEE_ACCEPTED : 'employee_accepted',
        JOB_EMPLOYEE_REJECTED : 'employee_rejected',
        JOB_RE_ASSIGN : 're_assign',
        JOB_EMPLOYEE_ON_THE_WAY : 'employee_on_the_way',
        JOB_EMPLOYEE_ARRIVED : 'employee_arrived',
        JOB_PURCHASE_ORDER_RAISED : 'purchase_order_raised',
        JOB_SUBMIT_PO_REQUEST : 'submit_po_request',
        JOB_PO_ADVANCE_PAYMENT : 'po_advance_payment',
        JOB_START_JOB : 'start_job',
        JOB_QUOTE_SUBMITTED : 'quote_submitted',
        JOB_QUOTE_APPROVED : 'quote_approved',
        JOB_QUOTE_REJECTED : 'quote_rejected',
        JOB_IN_PROGRESS : 'in_progress',
        JOB_ADD_ADDITIONAL_DETAILS : 'add_additional_details',
        JOB_ADD_JOB_NOTES : 'add_notes',
        JOB_EXTERNAL_ASSETS_CAPTURING : 'external_assets_capturing',
        JOB_ON_HOLD : 'on_hold',
        JOB_RESUMED : 'resumed',
        JOB_FOLLOW_UP_JOB : 'follow_up_job',
        JOB_COMPLETED : 'completed',
        JOB_INVOICE_DRAFTED : 'invoice_drafted',
        JOB_WARRANTY_SERVICE_REMINDERS : 'warranty_service_reminders',
        JOB_INVOICE_SUBMITTED : 'invoice_submitted',
        JOB_INVOICE_RE_SUBMITTED : 'invoice_re_submitted',
        JOB_PAYMENT_PROCESS_REQUESTED : 'job_payment_process_requested',
        JOB_CUSTOMER_PAYMENT : 'customer_payment',
        JOB_EMPLOYEE_PAYMENT_ACKNOWLEDGEMENT : 'employee_payment_acknowledgement',
        JOB_COMPANY_ACKNOWLEDGE_PAYMENT : 'company_acknowledge_payment',
        JOB_EMPLOYEE_RATED : 'employee_rated',
        JOB_CUSTOMER_RATED : 'customer_rated',
        JOB_SHARED : 'shared',
        JOB_ARCHIVE : 'archive',
        EXTERNAL_CM_QUOTE_REJECTED :'external_cm_quote_rejected',
        EXTERNAL_CM_QUOTE_APPROVED :'external_cm_quote_approved',
        EXTERNAL_CM_QUOTE_APPROVAL_PENDING :'external_cm_quote_approval_pending',
    },
    WORKFLOW_STATUSES:{
        WORKFLOW_NEW_CLAIMED: 'new.claimed',
        WORKFLOW_NEW_DECLINED: 'new.declined',
        WORKFLOW_CLAIMED_REJECTED: 'claimed.rejected',
        WORKFLOW_CLAIMED_CONFIRMED: 'claimed.confirmed',
        WORKFLOW_CLAIMED_RATED: 'rated',
        WORKFLOW_ARCHIVED: 'archived',
        WORKFLOW_NEW_CREATED: 'new.created',
        WORKFLOW_NEW_UPDATED: 'new.updated',
    },
    JOB_COMPANY_QUOTATION_STATUS: {
        JOB_COMPANY_QUOTATION_PENDING : 'pending',
        JOB_COMPANY_QUOTATION_RECEIVED : 'received',
        JOB_COMPANY_QUOTATION_APPROVED : 'approved',
        JOB_COMPANY_QUOTATION_REJECTED : 'rejected',
    },
    JOB_GROUP_TYPES: {
        JOB_GROUP_TYPE_REGULAR: 'regular',
        JOB_GROUP_TYPE_RECURRING: 'recurring',
        JOB_GROUP_TYPE_RECURRING_JOB: 'recurring_job',
        JOB_GROUP_TYPE_QUOTATION_REQUEST: 'quotation_request',
    },
    FIXED_JOB_SOURCES: {
        I4T_MAINTENANCE: {
            label:'i4T Maintenance',
            value: 26,
            public: 0
        }
    },
    EXTERNAL_JOB_SOURCES: [
        'console.cloud',
        'propertyme'
    ],
    ENTERPRISE_KEYS: {
        PICA: 'pica'
    },
    COMPANY_SOURCE_PICA: 'pica',
    AGENT_REQUEST_STATUS:{
        STATUS_NEW:'new',
        STATUS_CLAIMED:'claimed',
        STATUS_CONFIRMED:'confirmed',
        STATUS_REJECTED:'rejected',
        STATUS_ARCHIVED:'archived',
    },
    LEAD_VALIDATOR:{
        DAILY_LIMIT:'daily_limit',
        FREE_LEADS:'free_leads',
        NO_PAYMENT_ACCOUNT:'payment_account',
        COMPANY_STATUS:'company_status',
        VALID:'valid',
        CONFIGURATION:'configuration',
        NO_COMPANY:'no_company',
        PAYMENT_FAILED:'payment_failed',
    },
    LEAD_VALIDATOR_MESSAGES:{
        DAILY_LIMIT:'daily_limit',
        FREE_LEADS:'free_leads',
        NO_PAYMENT_ACCOUNT:'payment_account',
        COMPANY_STATUS:'company_status',
        VALID:'valid',
        CONFIGURATION:'configuration',
        NO_COMPANY:'no_company',
        PAYMENT_FAILED:'payment_failed',
    },
    ROLE_TYPE:{
        USER_ADMINISTRATOR: 1,
        USER_COMPANY_ADMIN: 2,
        USER_FRANCHISOR_ADMIN: 4,
        USER_ENTERPRISE:5
    },
    ORGANIZATIONS:{
        DATA_SOURCE_INTERNAL_DB : 1,
        DATA_SOURCE_API : 2,
        DATA_SOURCE_OFF : 0
    },
    ENTERPRISE_TYPES:{
        REAL_ESTATE: 1,
        STRATA : 2
    },
    PROPERTY_TYPES:{
        PRIMARY : 1,
        SECONDARY: 2
    },
    ROLE_NAMESPACE:{
        COMPANY : {
            KEY : 'company',
            VALUE : 'Company'
        },
        ENTERPRISE : {
            KEY : 'enterprise',
            VALUE : 'Enterprise'
        }
    },
    ENTERPRISE_FEATURES:{
        SYSTEM_REDIRECT_URL : 'enterprise_system_redirect_url'
    },
    WO_TYPE:{
        WO:'regular',
        QR:'quotation_request'
    },
    STATUS_GROUPS:{
        IN_PROGRESS : 2,
        COMPLETED:3,
        CLOSED:4
    },
    COMMUNITY_SELECT_APP_ID: 18,
    SUPPLIER_STATUS:{
        EXT_STATUS_NORMAL_DISPLAY : 'Normal',
        EXT_STATUS_PREFERRED_DISPLAY : 'Preferred',
        EXT_STATUS_BLACKLIST_DISPLAY : 'Blacklist'
    }
};

export default Object.assign({}, appConstants);

