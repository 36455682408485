<template>
    <div v-show="tablePagination && tablePagination.last_page > 1" :class="css.wrapperClass">
        <ul :class="[css.listWrapper]">
            <li class="paginate_button page-item" v-if="buttonSettings.firstPage">
                <a @click="loadPage(1)"
                   :class="[ 'page-link', css.linkClass, isOnFirstPage ? css.disabledClass : '']">
                    <span v-if="css.icons.first != ''" :class="[css.icons.first]">First</span>
                    <span v-else>&laquo;</span>
                </a>
            </li>
            <li class="paginate_button page-item" v-if="buttonSettings.previousPage">
                <a @click="loadPage('prev')"
                   :class="['page-link', css.linkClass, isOnFirstPage ? css.disabledClass : '']">
                    <span v-if="css.icons.next != ''" :class="[css.icons.prev]">Previous</span>
                    <span v-else></span>
                </a>
            </li>
            <template v-if="notEnoughPages">
                <template v-for="n in totalPage">
                    <li :class="['paginate_button page-item',css.pageClass, isCurrentPage(n) ? css.activeClass : '']">
                        <a @click="loadPage(n)"
                           v-html="n" class="page-link">
                        </a>
                    </li>
                </template>
            </template>
            <template v-else>
                <template v-for="n in windowSize">
                    <li :class="['paginate_button page-item',css.pageClass, isCurrentPage(windowStart+n-1) ? css.activeClass : '']">
                        <a @click="loadPage(windowStart+n-1)" class="page-link"
                           v-html="windowStart+n-1">
                        </a>
                    </li>
                </template>
            </template>
            <li class="paginate_button page-item" v-if="buttonSettings.nextPage">
                <a @click="loadPage('next')"
                   :class="['page-link', css.linkClass, isOnLastPage ? css.disabledClass : '']">
                    <span v-if="css.icons.next != ''" :class="[css.icons.next]">Next</span>
                    <span v-else></span>
                </a>
            </li>
            <li class="paginate_button page-item" v-if="buttonSettings.lastPage">
                <a @click="loadPage(totalPage)"
                   :class="['page-link', css.linkClass, isOnLastPage ? css.disabledClass : '']">
                    <span v-if="css.icons.last != ''" :class="[css.icons.last]">Last</span>
                    <span v-else>&raquo;</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import PaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin'

    export default {
        mixins: [PaginationMixin],
        props: ['paginationButtons'],
        data() {
            return {
                buttonSettings: {
                    firstPage: false,
                    previousPage: true,
                    nextPage: true,
                    lastPage: false,
                }
            }
        },
        methods: {
            setDefaults() {
                this.buttonSettings = Object.assign(this.buttonSettings, Object.assign({}, this.paginationButtons));
            }
        },
        beforeMount() {
            this.setDefaults();
        },
    }
</script>
