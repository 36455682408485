<template>
    <div class="job-main-form" id="detail-view-button-parent">
        <router-view></router-view>


        <div class="job-summary-details">

            <div class="row">
                <div class="col-sm-12">
                    <div class="profile-view-box">
                        <div class="row">

                            <div class="col-md-2 col-xxl-2">
                                <div class="profile-img-box">
                                  <div class="profile-div">
                                    <img alt="" v-if="jobInfo.job_detail.customer.profile_pic"
                                         v-bind:src="jobInfo.job_detail.customer.profile_pic">
                                    <img alt="" v-if="!jobInfo.job_detail.customer.profile_pic"
                                         src="img/user.png">
                                  </div>
                                    <div class="rating-profile"
                                         v-html="starRatings(jobInfo.job_detail.customer.rating.count)"></div>

                                </div>

                            </div>

                            <div class="col-md-9 col-xxl-7">

                                <div class="cus-details-right">

                                    <h3>{{jobInfo.job_detail.customer.name}}</h3>

                                    <div class="location-customer cus-item">
                                        <svg style="margin-left: -3px;" xmlns="http://www.w3.org/2000/svg" width="18"
                                             height="18" fill="#334152"
                                             viewBox="0 0 48 48">
                                            <path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/>
                                        </svg>
                                        {{processAddress(jobInfo.job_detail.job_meta_data.job_location.address, jobInfo.job_detail.customer.customer_address)}}
                                    </div>

                                    <div class="number-customer cus-item">
                                        <img class="mobile-icon" src="../../assets/img/phone2.png"/>
                                        {{processPhoneNumber(jobInfo.job_detail.customer)}}
                                    </div>

                                    <div class="email-customer cus-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#334152"
                                             viewBox="0 0 48 48">
                                            <path d="M40 8H8c-2.21 0-3.98 1.79-3.98 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zm0 8L24 26 8 16v-4l16 10 16-10v4z"/>
                                        </svg>
                                        {{processEmailAddress(jobInfo.job_detail.customer)}}
                                    </div>


                                </div>

                            </div>
                            <div class="col-sm-1 col-xxl-3">

                                <div class="dropdown" v-if="notATemplate()">

                                    <div class="circle-drop-btn dropbtn" @click="actionddlShow()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             viewBox="0 0 48 48">
                                            <path d="M24 16c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"/>
                                        </svg>
                                    </div>

                                    <div v-if="actionButton" id="settingdown"
                                         class="dropdown-content2">
                                        <a v-on:click="download('work-order/pdf/'+jobId+'/download', 'WO'+jobId)">
                                            {{ jobInfo.job_group_type == 'quotation_request' ? 'Quotation Request' : 'Work Order' }} PDF
                                        </a>
                                        <a v-on:click="download('qr/pdf/'+jobId+'/download', 'QR'+jobId)" href="#"
                                           v-if="jobInfo.job_group_type == 'regular' && jobInfo.job_detail.job_meta_data.quotation_request_code != null">
                                            Quotation Request PDF</a>
                                        <!--<a v-if="jobInfo.job_group_type != 'quotation_request'"
                                           :href="'/job/log/pdf/'+jobInfo.id + '/download'">View Activity Log PDF</a>-->
                                        <!--<a v-if="jobInfo.job_group_type != 'quotation_request'" target="_blank"
                                           :href="'/job/log/pdf/'+jobInfo.id + '/print'">Print Activity Log</a>
                                        <a target="_blank" :href="'/job/inspection/pdf/'+jobInfo.id + '/download'"
                                           v-if="jobInfo.job_detail.selected_quotation != null && this.$user.role != 'enterprise'">Download
                                            Inspection Report</a>-->
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="row"
                 v-if="false && notATemplate() && $user.role != 'enterprise' && jobInfo.job_detail.job_group_type != 'quotation_request'">

                <div class="col-sm-12">

                    <div class="timeline-top">

                        <div class="row">

                            <div class="col-sm-12">

                                <div class="step-job">

                                    <div class="job-item first active">

                                        <div class="icon-job">
                                            <i class="fa fa-check"></i>
                                        </div>

                                        <div class="name-job">New</div>
                                        <div class="arrow-job"></div>

                                    </div>

                                    <div class="job-item " v-bind:class="{'active current':jobInfo.active_step>1}">

                                        <div class="icon-job">
                                            <i class="fa fa-check"></i>
                                        </div>

                                        <div class="name-job">
                                            Quoted
                                            <!--<span>{{jobInfo.status_data.label}}</span>-->
                                        </div>
                                        <div class="arrow-job"></div>

                                    </div>

                                    <div class="job-item" v-bind:class="{'active':jobInfo.active_step>2}">

                                        <div class="icon-job">
                                            <i class="fa fa-check"></i>
                                        </div>

                                        <div class="name-job">Dispatched</div>
                                        <div class="arrow-job"></div>

                                    </div>

                                    <div class="job-item" v-bind:class="{'active':jobInfo.active_step>3}">

                                        <div class="icon-job">
                                            <i class="fa fa-check"></i>
                                        </div>

                                        <div class="name-job">In-Progress</div>
                                        <div class="arrow-job"></div>

                                    </div>

                                    <div class="job-item last" v-bind:class="{'active':jobInfo.active_step>4}">

                                        <div class="icon-job">
                                            <i class="fa fa-check"></i>
                                        </div>
                                        <div class="name-job">Completed</div>


                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>

                </div>

            </div>

        </div>

        <div class="row">

            <div class="col-sm-12">
                <div class="middle-box">

                    <div class="row" v-if="loaded && !viewMode">

                        <div class="col-md-12 col-xxl-12 text-right">

                            <div class="row action-btn-details">

                                <div class="inner-section-btn">


                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="colps-btns">

                                                <button :class="['btn', 'btn-primary', 'btn-lg', getBtnAlignmentClass('archive')]"
                                                        title="Archive"
                                                        @click.prevent="archive"
                                                        :disabled="editMode"
                                                        v-if="false"><!--isArchivable() && !userReadOnly-->
                                                    <i class="fa fa-archive" aria-hidden="true"></i>
                                                    <span>Archive</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            </div>

            <div class="col-sm-12" v-if="notATemplate()">
                <div class="status-bar">
                    <div class="job-status1">
                        <span>{{ isStrata && jobInfo.job_group_type == 'quotation_request' ? 'Quotation Request' : $terminologies.job.singularTerm }} Status</span>
                        <div class="status1">{{ jobInfo.job_detail.job_meta_data.job_status}}</div>
                    </div>
                </div>
            </div>

        </div>
        <slot></slot>

        <div v-if="!hideExtra">

            <ViewModal :headerTitle="'Add Notes'" ref="addNoteModal" :name="'addNotesModal-header'" :width="'50%'"
                       id="add-note-modal" transition="" :has-route="false" :showFooter="false">
                <template slot="body">
                    <job-notes @add-note="addNote" @close="$refs.addNoteModal.hide()"></job-notes>
                </template>
            </ViewModal>

        </div>
    </div>
</template>

<script>
    import Create from '../../store/forms/job/create';
    import {createHelpers} from 'vuex-map-fields';
    import ViewModal from '../../components/modal/VueJsModal';
    import swal from 'sweetalert2';
    import Util from "../../helpers/util";
    import {ENTERPRISE_TYPES, JOB_GROUP_TYPES, WORKFLOW, COMPANY_SOURCE_PICA} from "../../const/AppConstants";
    import  {apiBaseUrl} from '../../config/apiConfig'

    const {mapFields} = createHelpers({
        getterType: 'job_create/getField',
        mutationType: 'job_create/updateField'
    });

    export default {
        components: {ViewModal},
        mixins: [Util],
        data() {
            return {
                actionButton: false,
                job_id: null,
                loaded: true,
                editMode: this.localEdit,
                employee: null,
                viewMode: this.viewOnly,
                initProcess: true,
                context: 'header',
                archivableWorkflows: [
                    WORKFLOW.JOB_UNDER_REVIEW,
                    WORKFLOW.JOB_CREATED,
                    WORKFLOW.JOB_EDITED,
                    WORKFLOW.JOB_CUSTOMER_REJECTED,
                    WORKFLOW.JOB_QUOTE_REJECTED
                ],
                billedArchivableWorkflow: [
                    WORKFLOW.JOB_CUSTOMER_REJECTED,
                    WORKFLOW.JOB_QUOTE_REJECTED
                ],
                currentRoute: '',
                redirectRoutes: [],
                job_group_types: JOB_GROUP_TYPES,
                showPhoneNumber:true,
                showEmailAddress:true,
            };
        },
        name: 'JobProfileHeader',
        computed: {
            ...mapFields(Create),
            userReadOnly() {
                return this.$user.role == 'enterprise' && this.$user.admin;
            },
            subcontractorEnabled() {
                return this.$store.getters['userdata/getUserData']('features.subcontractor');
            },
            isStrata() {
                try { return ((this.$user.role == 'enterprise' && this.$user.enterprise.type == ENTERPRISE_TYPES.STRATA) || this.jobInfo.job_detail.company.source === COMPANY_SOURCE_PICA ) } catch (e) { return  false; }
            },
        },
        props: {
            localEdit: {
                default: false
            },
            hideExtra: {
                default: false
            },
            jobId: {
                default: null
            },
            est_btn: {
                default: true
            },
            params: {
                default: function () {
                    return {};
                }
            },
            asg_btn: {
                default: false
            },
            accept_btn: {
                default: false
            },
            dispatch_ban: {
                default: false
            },
            viewOnly: {
                default: false
            },
            lastView: {
                default: 'job_edit'
            }
        },
        methods: {

            getBtnAlignmentClass(action) {
                switch (action) {
                    case 'archive':
                    case 'addNoteModal':
                    case 'followUp':
                    case 'jobEdit':
                    case 'sendToBid':
                    case 'woQrRemind':
                        return 'left';

                    case 'schedule':
                    case 'update':
                    case 'cancel':
                    case 'managerDecline':
                    case 'managerApprove':
                    case 'rejectEstimate':
                    case 'approveEstimate':
                    case 'rejectQuote':
                    case 'approveQuote':
                    case 'assign':
                    case 'reAssign':
                    case 'dispatchJob':
                    case 'paymentAcknowledgement':
                    case 'approve':
                    case 'viewInvoice':
                    case 'internalNote':
                        return 'right';

                    case 'quoteRequest':
                    case 'sendQuotation':
                        if(this.jobInfo.job_detail.workflow_key == 'created' || this.jobInfo.job_detail.workflow_key == 'edited' || this.jobInfo.job_detail.workflow_key == 'upfront_quote_requested'){
                            return 'right';
                        }else if(this.jobInfo.job_detail.workflow_key == 'employee_assigned' || this.jobInfo.job_detail.workflow_key == 'employee_accepted' || this.jobInfo.job_detail.workflow_key == 'dispatched'){
                            return 'left';
                        }
                        break;

                    case 'viewQuote':
                        if(this.jobInfo.job_detail.workflow_key == 'upfront_quoted_ca' || this.jobInfo.job_detail.workflow_key == 'onsite_quoted_ca' ||
                            this.jobInfo.job_detail.workflow_key == 'created' || this.jobInfo.job_detail.workflow_key == 'edited' ||
                            (this.jobInfo.job_detail.workflow_key == 'on_hold' && (this.jobInfo.job_detail.workflow_tree.includes('onsite_quoted_ca') ||
                                this.jobInfo.job_detail.workflow_tree.includes('onsite_quoted_boc')))){
                            return 'right';
                        }else if(this.jobInfo.job_detail.workflow_key == 'upfront_quote_customer_approved' || this.jobInfo.job_detail.workflow_key == 'employee_assigned' || this.jobInfo.job_detail.workflow_key == 'dispatched'){
                            return 'left';
                        } else {
                            return 'left';
                        }
                        break;

                }

            },
            schedule() {
                this.$router.push({name: 'job_all_employee_timeline_schedule'});
            },
            getClassForButtonPlacement(section) {
                let classText = "";
                switch (section) {
                    case "left":
                        if (this.$user.role == 'enterprise')
                            classText = "col-md-6 col-xxl-7 text-left";
                        else
                            classText = "col-md-8 text-left";
                        break;
                    case "right":
                        if (this.$user.role == 'enterprise')
                            classText = "col-md-6 col-xxl-5 text-right";
                        else
                            classText = "col-md-4 text-right";
                        break;
                    default:
                        classText = "";
                }
                return classText;
            },
            managerApprove() {

                let self = this;

                if(self.jobInfo.job_detail.company.id == process.env.MIX_I4T_UNASSIGNED_COMPANY_ID){
                    swal({
                        title: 'Job cannot be Approved',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Assign a Trades Company',
                        reverseButtons: true,
                        customClass: 'approve-request-modal',
                        html: '<p>There is no Trades Company Assigned to this Job.</p>',

                    }).then(function(val) {

                        if(val.value === true){
                            self.$router.push({
                                name: 'job_edit',
                                params: { id: self.job_id, show_approve_popup:true}
                            })
                        }

                    })
                }else{
                    swal({
                        title: 'How do you wish to Continue?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Proceed',
                        reverseButtons: true,
                        customClass: 'approve-request-modal',
                        html:
                            '<div class="swal2-radio" style="display: flex;">' +
                            '<label><input type="radio" id="estimate_pref_1" name="estimate_pref" value="estimated_boc"><span class="swal2-label">Allow to Proceed with this Job Request</span></label>' +
                            '<label><input type="radio" id="estimate_pref_2" name="estimate_pref" value="estimated_ca"><span class="swal2-label">Request an Initial Estimation for Approval</span></label>' +
                            '</div>' +
                            '<div class="p-4"><textarea id="approve_comment" aria-label="Type your comment here" class="swal2-textarea" placeholder="Type your comment here..."></textarea></div>',
                        focusConfirm: false,
                        onBeforeOpen: (swal) => {
                            if (swal.querySelector('input[name="estimate_pref"][value="estimated_boc"]'))
                                swal.querySelector('input[name="estimate_pref"][value="estimated_boc"]').checked = true;
                        },
                        preConfirm: () => {
                            let estimation_pref = (document.querySelector('input[name="estimate_pref"]:checked').value) ?
                                document.querySelector('input[name="estimate_pref"]:checked').value : '';
                            let comment = (document.getElementById('approve_comment').value) ? document.getElementById('approve_comment').value : '';

                            this.$root.workflowEnterprise({
                                "process": "manager_approved",
                                "job_id": self.job_id,
                                "est_pref": estimation_pref,
                                "customer_comments": comment
                            }, function (data, status) {
                                if (status) {
                                    self.$emit('refresh');
                                }

                            });
                        },
                        allowOutsideClick: () => !swal.isLoading()
                    });
                }


            },
            managerDecline() {
                let self = this;
                swal({
                    title: 'Are you sure to Reject this request ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true,
                    customClass: 'reject-request-modal',
                    text: 'Specify Reasons for Rejection',
                    input: 'textarea',
                    inputValidator: (result) => {
                        return !result && 'Please Specify Reasons for Rejection'
                    },
                    preConfirm: (comment) => {
                        this.$root.workflowEnterprise({
                            "process": "manager_declined",
                            "job_id": self.job_id,
                            "customer_comments": comment
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                });
            },
            actionddlShow() {
                this.actionButton = !this.actionButton;
                this.$forceUpdate();
                console.log(!this.actionButton);
            },
            quoteRequest() {
                this.process = "upfront_quote_requested";
                this.$store.commit('hub/setRequestContext', this.context);
                this.$refs.quoteRequest.triggerModel(this.job_id, this.jobInfo.job_detail.customer.id, false, true);
            },
            assign() {
                this.process = "employee_assigned";
                this.$store.commit('hub/setAssignMethod', this.context);
                this.$refs.assign.triggerModel(this.job_id, this.process);
            },
            reAssign(jobId, returnVal) {
                this.initProcess = returnVal;
                this.process = "re_assign";
                this.$store.commit('hub/setAssignMethod', this.context);
                this.$refs.assign.triggerModel(this.job_id, 're_assign');
            },
            sendQuotation() {
                let redirectRoute = false;
                if(this.redirectRoutes.includes(this.currentRoute))
                    redirectRoute = this.currentRoute;

                this.$router.push({name: 'job_quote', params:{jobId: this.job_id, redirect_route: redirectRoute}})
                // this.$refs.estimate.triggerModel(this.job_id, this.job);
            },
            view() {
                this.$refs.view.show();
            },
            addNoteModal() {
                this.$refs.addNoteModal.show()

            },
            addNote(data) {
                let self = this;
                this.$root.workflow({
                    "comments": data,
                    "process": "add_notes",
                    "job_id": self.job_id
                }, function (data, status) {
                    if (status) {
                        self.$refs.addNoteModal.hide();
                        if (status) {
                            self.$emit('refresh');
                        }
                    }

                });

            },
            dispatchJob() {
                let self = this;
                let title = this.jobInfo.job_detail.employee.name ? 'to ' + this.jobInfo.job_detail.employee.name : '';
                swal({
                    target: '#detail-view-button-parent',
                    title: `Do you want to Dispatch this job ${title}?`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.$root.workflow({
                            "process": "dispatched",
                            "job_id": this.job_id
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                });
            },
            sendToBid() {
                let self = this;
                swal({
                    target: '#detail-view-button-parent',
                    title: 'Do you want to Send this job to Bids?',
                    html: '<div class="row"><div class="col-sm-12">Remarks<textarea id="send_to_bid_comments" class="swal2-input"></textarea></div></div>',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    customClass: 'bids-modal',
                    confirmButtonText: 'Yes',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {

                        this.$root.workflow({
                            "process": "send_to_bid",
                            "job_id": this.job_id
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                });
            },
            paymentAcknowledgement() {
                let self = this;

                this.$root.workflow({
                    "process": "company_acknowledge_payment",
                    "job_id": this.job_id
                }, function (data, status) {
                    if (status) {
                        self.$emit('refresh');
                    }
                });
            },
            archive() {
                let self = this;
                swal({
                    target: '#detail-view-button-parent',
                    title: 'Do you want to Archive this Job?',
                    label: 'Reason for Archiving this Job',
                    customClass: 'archive-modal',
                    text: 'Specify the reason for Archiving this job',
                    input: 'textarea',
                    type: 'info',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showLoaderOnConfirm: true,
                    preConfirm: (comment) => {
                        self.$root.workflow({
                            "process": "archive",
                            "job_id": self.job_id,
                            "comments": comment
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    },
                    allowOutsideClick: () => !swal.isLoading()
                });
            },
            approveQuote() {
                let self = this;
                swal({
                    target: '#detail-view-button-parent',
                    title: 'Are you sure you want to Approve this Quotation?',
                    label: 'Reason for Approve the quotation',
                    customClass: 'archive-modal',
                    text: 'Specify Reasons for Approval',
                    input: 'textarea',
                    type: 'info',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showLoaderOnConfirm: true,
                    preConfirm: (comment) => {
                        self.$root.workflow({
                            "process": "quote_approved",
                            "job_id": self.job_id,
                            "comments": comment
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    },
                    allowOutsideClick: () => !swal.isLoading()
                });
            },
            rejectQuote() {
                let self = this;
                swal({
                    target: '#detail-view-button-parent',
                    title: 'Are you sure you want to Reject this Quotation?',
                    label: 'Reason for Reject the quotation',
                    customClass: 'archive-modal',
                    text: 'Specify Reasons for Rejection',
                    input: 'textarea',
                    type: 'info',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showLoaderOnConfirm: true,
                    preConfirm: (comment) => {
                        self.$root.workflow({
                            "process": "quote_rejected",
                            "job_id": self.job_id,
                            "comments": comment
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    },
                    allowOutsideClick: () => !swal.isLoading()
                });
            },
            approve() {
                let self = this;
                swal({
                    target: '#detail-view-button-parent',
                    title: 'Do you want to Approve this Job On-Behalf of customer?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.$root.workflow({
                            "process": "customer_approved",
                            "job_id": self.job_id,
                            "comments": 'Estimation is Approved on behalf of the Customer',
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                })
            },
            rejectEstimate() {
                let self = this;
                if(self.jobInfo.job_group_type === JOB_GROUP_TYPES.JOB_GROUP_TYPE_MULTIPLE_QUOTATION){

                    swal({
                        title: 'Notice!',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Proceed',
                        reverseButtons: true,
                        customClass: 'archive-modal',
                        html: '<label style="font-weight: 500; padding: 15px;">This Job is a part of a Multiple Quotations Request. It cannot be <br/>rejected from Job Dashboard. Do you want to visit Multiple Quotations <br/>page now to take actions?</label>',
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({
                                name:'multiple_quotation_jobs',
                                params:{ job_group_id: self.jobInfo.job_group_id, view_detail: 1}
                            });
                        }
                    });
                }else {
                    swal({
                        title: 'Are you sure you want to <br/>Reject this Estimation ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                        reverseButtons: true,
                        customClass: 'archive-modal',
                        text: 'Specify Reasons for Rejection',
                        input: 'textarea',
                        preConfirm: (comment) => {
                            if (comment) {
                                this.$root.workflow({
                                    "process": "customer_rejected",
                                    "job_id": self.job_id,
                                    "reason_text": comment,
                                }, function (data, status) {
                                    if (status) {
                                        self.$emit('refresh');
                                    }
                                });
                            } else {
                                swal.showValidationError('Comment is required')
                            }

                        },
                    })
                }
            },
            approveEstimate() {
                let self = this;
                if(self.jobInfo.job_group_type === JOB_GROUP_TYPES.JOB_GROUP_TYPE_MULTIPLE_QUOTATION){

                    swal({
                        title: 'Notice!',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Proceed',
                        reverseButtons: true,
                        customClass: 'archive-modal',
                        html: '<label style="font-weight: 500; padding: 15px;">This Job is a part of a Multiple Quotations Request. It cannot be <br/>approved from Job Dashboard. Do you want to visit Multiple Quotations <br/>page now to take actions?</label>',
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({
                                name:'multiple_quotation_jobs',
                                params:{ job_group_id: self.jobInfo.job_group_id, view_detail: 1}
                            });
                        }
                    });
                }else{
                    swal({
                        title: 'Are you sure you want to <br/>Approve this Estimation ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                        reverseButtons: true,
                        customClass: 'archive-modal',
                        text: 'Specify Reasons for Approval',
                        input: 'textarea',
                        preConfirm: (comment) => {
                            this.$root.workflow({
                                "process": "customer_approved",
                                "job_id": self.job_id,
                                "comments": comment,
                            }, function (data, status) {
                                if (status) {
                                    self.$emit('refresh');
                                }
                            });
                        },
                    })
                }

            },
            followUp() {
                let self = this;
                swal({
                    target: '#detail-view-button-parent',
                    title: 'Do you want to mark this job as a Follow-Up?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.$root.workflow({
                            "process": "follow_up_job",
                            "job_id": this.job_id
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                });
            },
            viewInvoice() {
                this.$router.push(
                    {
                        name: 'job_invoice',
                        params: {
                            'jobId': this.job_id
                        }
                    }
                );
            },
            viewQuote() {
                let redirectRoute = false;
                if(this.redirectRoutes.includes(this.currentRoute))
                    redirectRoute = this.currentRoute;

                this.$router.push(
                    {
                        name: 'job_quote_review',
                        params: {
                            'jobId': this.job_id,
                            'quoteId': this.jobInfo.job_detail.selected_quotation.id,
                            'redirect_route': redirectRoute,
                        }
                    }
                );
            },
            update() {
                this.$emit('refresh');
                this.$emit('update', this.lastView);
            },
            resume() {
                let self = this;

                swal({
                    target: '#detail-view-button-parent',
                    title: 'Comments',
                    html: '<textarea id="comments" class="swal2-input"></textarea>',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Resume!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.$root.workflow({
                            "process": "resumed",
                            "comments": '',
                            "job_id": self.job_id,
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                })
            },
            changeTab(workflow, subWorkflow = 'all') {
                this.$router.push(
                    {
                        name: 'job_dashboard',
                        params: {
                            'workflow': workflow,
                            'sub_workflow': subWorkflow
                        }
                    }
                );
            },
            resetEmployee() {
                this.employee = null;
                this.process = null;
                this.jobId = 0;
                this.initProcess = true;
            },
            isArchivable() {

                if (this.$user.role == "enterprise") {
                    let isInArray = this.archivableWorkflows.includes(this.jobInfo.workflow_key);
                    let isBilledArray = this.billedArchivableWorkflow.includes(this.jobInfo.workflow_key);

                    if (isInArray && !this.jobInfo.job_detail.archived) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (this.jobInfo.job_detail.archived || this.jobInfo.job_group_type === 'quotation_request') {
                        return false;
                    } else {
                        return true;
                    }
                }

            },
            hasQuotation() {
                if (this.jobInfo.job_detail.selected_quotation && this.jobInfo.job_detail.selected_quotation.id) {
                    return true;
                }
                return false;
            },
            externalQuotation() {
                return this.jobInfo.job_detail.selected_quotation && this.jobInfo.job_detail.selected_quotation.type == 'external';
            },
            notATemplate() {
                if (this.$route.params.job_group_type && this.$route.params.job_group_type == 'recurring') {
                    return false;
                }
                return true;
            },
            woQrRemind() {
                let self = this;
                swal({
                    title: 'Are you sure you want to <br />Send a Reminder to the Supplier ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true,
                    customClass: 'archive-modal',
                }).then((result) => {
                    if (result.value) {
                        this.$root.workflow({
                            "process": "wo_qr_reminded",
                            "job_id": self.job_id,
                        }, function (data, status) {
                            if (status) {
                                self.$emit('refresh');
                            }
                        });
                    }
                })
            },
            processPhoneNumber(customer) {
                let settings = customer.settings;
                try {
                    settings = (customer.settings) ? JSON.parse(customer.settings) : null;
                } catch (e) {}
                let show_mobile = true;
                let show_phone = true;
                let return_mobile = '';

                if (settings != null) {
                    show_mobile = (settings.mobile == 'auto') ? false : true;
                    show_phone = (settings.phone == 'auto') ? false : true;
                }
                if (show_mobile == true && customer.fixed_mobile.length > 1) {
                    this.showPhoneNumber = true;
                    return_mobile += '(' + customer.mobile_area_code + ') (' + customer.fixed_mobile + ')  ';
                }

                // if (show_phone == true && customer.fixed_phone.length > 1) {
                //     this.showPhoneNumber = true;
                //     return_mobile += '(' + customer.phone_area_code + ') (' + customer.fixed_phone + ')  ';
                // }
                if (return_mobile == ''){
                    this.showPhoneNumber = false;
                    return_mobile = ' - ';
                }

                return return_mobile;
            },

            processAddress(property_address, customer_address){
                console.log(property_address, customer_address);
                return (customer_address) ? customer_address : property_address;
            },

            processEmailAddress(customer){
                let settings = customer.settings;
                try {
                    settings = (customer.settings) ? JSON.parse(customer.settings) : null;
                } catch (e) {}
                let show_email = true;
                let return_email = '';

                if (settings != null) {
                    show_email = (settings.email == 'auto') ? false : true;
                }
                if (show_email == true && customer.email.length > 1) {
                    this.showEmailAddress = true;
                    return customer.email;
                }else{
                    this.showEmailAddress = false;
                    return ' - ';
                }
            },
            addInternalNote() {
                let self = this;
                self.$refs.internal_notes_modal.show(self.job_id);
            },
            internalNoteAdded() {
                this.$emit('refresh');
            },
            download(link, name) {
                // Had to use axios directly, because of a issue in XHR service
                axios({
                    url: apiBaseUrl + link,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('access_credentials')).access_token}
                })
                .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', name + '.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
            }
        },
        mounted() {
            this.job_id = (this.jobId) ? this.jobId : this.$route.params.id;
            this.currentRoute = this.$route.name;

            this.$router.options.routes.forEach(parent => {
                if (parent.name == 'home') {
                    parent.children.forEach(homeRoutes => {
                        if (homeRoutes.name == 'job_schedule_full') {
                            homeRoutes.children.forEach(scheduleRoute => {
                                this.redirectRoutes.push(scheduleRoute.name);
                            })
                        }
                    })
                }
            })

            this.loaded = (this.$user.job_access) ? true : false;

            window.onclick = function (event) {
                if (!event.target.matches('.dropbtn')) {

                    var dropdowns = document.getElementsByClassName("dropdown-content");
                    var i;
                    for (i = 0; i < dropdowns.length; i++) {
                        var openDropdown = dropdowns[i];
                        if (openDropdown.classList.contains('show')) {
                            openDropdown.classList.remove('show');
                        }
                    }
                }
            }

            console.log("this.jobInfo", this.jobInfo);
            console.log("this.$user", this.$user);

        },
        events: {
            employeeSelected(employee, comment, ctx) {
                let self = this;
                if (ctx == 'header') {
                    if (this.initProcess) {
                        if (this.process) {
                            this.employee = employee;
                            this.$root.workflow({
                                "process": this.process,
                                "employee_id": employee.id,
                                "comments": comment,
                                "job_id": this.job_id
                            }, function (data, status) {
                                if (status) {

                                    self.$refs.assign.hide();
                                    self.$emit('refresh')
                                }
                            });
                        }
                    } else {
                        this.employee = employee;
                        this.$refs.estimate.employeeSelected(this.employee);
                    }
                }
            },
            quoteRequested(contractors, contacts, ctx) {
                let self = this;
                if (ctx == 'header') {
                    if (this.process) {
                        this.$root.workflow({
                            "process": self.process,
                            "employee_ids": contractors,
                            'contact_ids': contacts,
                            "job_id": self.jobId
                        }, function (data, status) {
                            if (status) {
                                self.$refs.quoteRequest.hide();
                                self.$emit('refresh')
                            }
                        });
                    }
                }
            },
            internalNotes(eventData, ctx) {
                let self = this;
                if (ctx == 'header') {
                    self.$refs.internal_notes_modal.show(self.job_id, {
                        'process_id': eventData.process_id,
                        'text': eventData.text
                    });
                }
            }
        }
    };
</script>

<style lang="scss">
    @import "../../assets/styles/sass/_components/radio/popup-radio";
</style>
