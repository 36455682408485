<template>
    <div id="breadcrumb">
        <ul class="breadcrumb">
            <li v-for="(route, index) in breadCrumbLinks" class="breadcrumb-item">
                <router-link v-if="index < breadCrumbLinks.length-1" :to="{'name' : route.name}">{{route.label}}</router-link>
                <span v-else :to="{'name' : route.name}">{{route.label}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "BreadCrumb",
        props: ['links'],
        data() {
            return {
                breadCrumbLinks: this.links
            }
        }
    }
</script>

<style scoped>

</style>