import Axios from "./AxiosService";
import store from '../store';
import terminologies from "../plugins/terminologies";
import { BaseService } from "./BaseService";

class UserService extends BaseService{
    fetchUserData(){
        return Axios
            .send(Axios.XHR_GET_METHOD,'system/userdata', {})
            .then(response => {
                return response;
            });
    }
}

export default new UserService();