//import vue-toasted plugin and wrapping it
import Toasted from 'vue-toasted';
import Loading from 'vue-loading-overlay';

// Import stylesheet
//import 'vue-loading-overlay/dist/vue-loading.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {

    install: (Vue) => {

        Vue.use(Toasted);

        Vue.use(Loading);

        //default configs
        let _settings = {
            theme: "bubble",
            position: "top-center",
            iconPack : 'fontawesome',

            duration : 0,
            className : "app-toast",
            containerClass : 'app-toast-container',
            action : {
                text : '',
                onClick : (e, toastObject) => {
                    toastObject.goAway(0);
                }
            }
        };

        let loader = null;
        let keepToast = false;
        let self = this;

        /**
         * Loader instance to use loading package as a plugin
         * @type {{on(): void, off(): void}}
         */

        Vue.prototype.$loader = {
            on(){
                this._show();
            },

            off(){
               this._hide();
            },

            _show(){
                Vue.prototype.$toasted.clear();
                this.loader = Vue.$loading.show({},
                    {
                        // Pass slots by their names
                        //default:
                    });
                //@TODO Temporary solution
                $('.vld-icon').html('<div  data-class="onloading-bg" style="width:100%;height:100%" class="lds-wedges"><div>\n' +
                    '                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>\n' +
                    '            </div>');
            },

            _hide(){
                if(this.loader) {
                    this.loader.hide();
                }

                $('.vld-overlay').removeClass('is-active');
            }
        }

        /**
         * toast instance to use toasted package as a plugin
         * @type {{success(*=): void, progress(*, *=): void}}
         */
        Vue.prototype.$toast = {
            //clear toasts
            clear(){
                if(!this.keepToast) {
                    Vue.prototype.$toasted.clear();
                }

                this.keepToast = false; //resetting it back to hide
            },

            success(message, keep = false, options){
                //keeping the toast for route changes
                this.keepToast = keep;

                let override = {
                    type: 'info',
                    icon : 'check-circle',
                    duration : 5000
                }

                const settings = Object.assign(_settings, override, options);

                 Vue.prototype.$toasted.info(message, settings);
            },

            info(message, keep = false, options){
                //keeping the toast for route changes
                this.keepToast = keep;

                let override = {
                    type: 'info',
                    icon : 'check-circle',
                    className : 'notice'
                }

                const settings = Object.assign(_settings, override, options);

                Vue.prototype.$toasted.info(message, settings);
            },

            error(message, keep = false, options){

                //keeping the toast for route changes
                this.keepToast = keep;

                let override = {
                    icon : 'times-circle',
                    duration : 0
                }

                const settings = Object.assign(_settings, override, options);

                Vue.prototype.$toasted.error(message, settings);
            },

            formError(message, options){

                let override = {
                    icon : 'info',
                    duration : 0,
                }
                const settings = Object.assign(_settings, override, options);

                Vue.prototype.$toasted.error(message, settings);
            },

            loading(state, message, options){

                let override = {
                    type: 'info',
                    icon : 'check-circle'
                }

                const settings = Object.assign(_settings, override, options);

                if(state == false){
                    this._hide();
                    Vue.prototype.$toasted.info(message, settings);
                }else{
                    this.clear();
                    this.loader = Vue.$loading.show();
                    //Temporary solution
                    $('.vld-icon').html('<div  data-class="onloading-bg" style="width:100%;height:100%" class="lds-wedges"><div>\n' +
                        '                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>\n' +
                        '            </div>');
                }
            },

            _hide(){
                if(this.loader !== undefined && this.loader !== null) {
                    this.loader.hide();
                }
            }
        }
    }
}
