<template>
  <router-view></router-view>
</template>

<script>

import {mapGetters} from 'vuex';
import Login from "./views/auth/Login";
import Home from "./views/Home";
import AuthService from './services/AuthService'
import Vue from "vue";

export default {
  components: {
    Login,
    Home
  },
  data() {
    return {
      window: {
        width: 0
      },
      scrollContainerStyle: '',
      enterpriseData: {},
      showInvitationApprovedModal: false,
      showServiceModal: false
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },

  mounted() {
    this.handleResize();
    this.calculateScreenResolution();
    this.autoAdjust();

    // switch (performance.navigation.type) {
    //   case 0:
    //     localStorage.removeItem('tenantArray');
    //     localStorage.removeItem('tenantCount');
    //     break;
    //   case 1:
    //     //this is used to remove local storage when ever browser is refreshed.
    //     localStorage.removeItem('tenantArray');
    //     localStorage.removeItem('tenantCount');
    //     break;
    //   case 2:
    //     break;
    //   case 255:
    //     break;
    // }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {

    calculateScreenResolution() {
      // let sheight = screen.height;

      if (document.getElementById('top_header_bar') && document.getElementById('basic_footer_bar')){

      }
      this.handleResize();
      let windowHeight = window.innerHeight;
      if (document.getElementById('top_header_bar') && document.getElementById('basic_footer_bar')){
        let headerHeight = document.getElementById('top_header_bar').clientHeight;
        let footerHeight = document.getElementById('basic_footer_bar').clientHeight;

        // let totel = sheight - 248;
        let scrollContainerMaxHeight = windowHeight - (headerHeight + footerHeight + 15);
        this.scrollContainerStyle = {'max-height': scrollContainerMaxHeight + 'px'};
      }


    },


    autoAdjust() {
      let self = this;
      window.onresize = function () {
        // alert('changed');
        self.calculateScreenResolution();
      }
    },
    handleResize() {
      // this.window.width = window.innerWidth;

      if (window.innerWidth < 1280) {
        this.deviceCheckView = true;
        this.$router.push(
            {
              name: 'checkdevices'
            }
        );
      }

    }
  },

  watch: {
    $route(to, from) {
      this.calculateScreenResolution();
    }
  },

  events: {
    showInvitationApprovedModal(status) {
      this.showInvitationApprovedModal = status;
    },
    showCreateJobModal() {
      this.getUserData();
    }
  }
}
</script>


<style lang="scss">

  @import "https://fonts.googleapis.com/css?family=Raleway:100,600";
  @import "assets/styles/css/loader.css";
  @import "assets/styles/sass/theme/default/scss/main.scss";
  @import "assets/styles/sass/app.scss";

  @import "assets/styles/sass/login.scss";
  @import "assets/styles/sass/onboard.scss";
  @import "assets/styles/sass/company/layouts/main_layout.scss";
  @import "assets/styles/sass/company/default.scss";
  @import "assets/styles/sass/company/company.scss";
  @import "assets/styles/sass/enterprise/enterprise.scss";
</style>
