<template>
    <div class="footer" id="basic_footer_bar">

        <div class="row">

            <div class="col-sm-4">
                <div class="left-footer">
                    <a href="https://www.facebook.com/i4tradies/" target="_blank"><i class="fab fa-facebook"
                                                                                     aria-hidden="true"></i></a>
                    <a href="https://twitter.com/i4tradies" target="_blank"><i class="fab fa-twitter"
                                                                               aria-hidden="true"></i></a>
                    <a href="https://plus.google.com/+i4tradies" target="_blank"><i class="fab fa-google-plus"
                                                                                    aria-hidden="true"></i></a>
                    <a href="https://www.linkedin.com/company/i4tradies/" target="_blank"><i class="fab fa-linkedin"
                                                                                             aria-hidden="true"></i></a>
                    <a href="https://www.pinterest.com/i4tradies/" target="_blank"><i class="fab fa-pinterest-p"
                                                                                      aria-hidden="true"></i></a>
                    <a href="https://www.youtube.com/i4tradies" target="_blank"><i class="fab fa-youtube"></i></a>
                    <a href="https://www.instagram.com/i4tradies/" target="_blank"><i class="fab fa-instagram"
                                                                                      aria-hidden="true"></i></a>
                </div>
            </div>

            <div class="col-sm-4">
              <div class="right-footer">
                <span>
                Copyright © {{ new Date().getFullYear() }} <a href="https://www.i4tradies.com.au" title="i4Tradies"
                                                              target="_blank">i4Tradies</a></span>
                    <span><a href="https://www.i4tradies.com.au/terms" target="_blank">Terms and Conditions</a>
                    &nbsp;&nbsp;|&nbsp;
                    <a href="https://www.i4tradies.com.au/privacy" target="_blank">Privacy Policy </a>
                        </span>

              </div>
            </div>

            <div class="col-sm-4">
              <div class="google-footer">
                <img src="../../../assets/img/google.png" alt="Google Cloud Platform" title="Google Cloud Platform">
              </div>


            </div>
        </div>

<!--        <common-model ref="commonModel"></common-model>-->

    </div>

</template>

<script>
    // import CommonModel from '../../_global/model/common';
    export default {
        name: "BasicFooter",
        components : {},
        methods : {
            open(){
               // this.$refs.commonModel.open();
            },
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    @import './src/assets/styles/sass/_components/footer/footer';
</style>
