<template>
    <div class="job-main-form" id="detail-view-button-parent">
        <div class="job-summary-details">

            <div class="row">
                <div class="col-sm-12">
                    <div class="profile-view-box">
                        <div class="row">

                            <div class="col-md-2 col-xxl-2">
                                <div class="profile-img-box">
                                    <div class="profile-div">
                                      <img alt="" v-if="job_details.customer.profile_pic" v-bind:src="job_details.customer.profile_pic">
                                      <img alt="" v-else src="img/user.png">
                                    </div>
                                    <div class="rating-profile" v-html="starRatings()"></div>

                                </div>

                            </div>

                            <div class="col-md-9 col-xxl-7">

                                <div class="cus-details-right">

                                    <h3>{{job_details.customer.first_name}} {{job_details.customer.last_name}}</h3>

                                    <div class="location-customer cus-item">
                                        <svg style="margin-left: -3px;" xmlns="http://www.w3.org/2000/svg" width="18"
                                             height="18" fill="#334152"
                                             viewBox="0 0 48 48">
                                            <path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/>
                                        </svg>
                                        {{job_details.customer.address ? job_details.customer.address : job_details.misc_meta_data.address}}
                                    </div>

                                    <div class="number-customer cus-item">
                                        <img class="mobile-icon" src="../../assets/img/phone2.png"/>
                                        {{processPhoneNumber(job_details.customer)}}
                                    </div>

                                    <div class="email-customer cus-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#334152"
                                             viewBox="0 0 48 48">
                                            <path d="M40 8H8c-2.21 0-3.98 1.79-3.98 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zm0 8L24 26 8 16v-4l16 10 16-10v4z"/>
                                        </svg>
                                        {{processEmailAddress(job_details.customer)}}
                                    </div>


                                </div>

                            </div>
                            <div class="col-sm-1 col-xxl-3">

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="row">

            <div class="col-sm-12">
                <div class="status-bar">
                    <div class="job-status1">
                        <span>Quotation Request Status</span>
                        <div class="status1"> {{ job_details.status_label || 'Pending' }} </div>
                    </div>
                </div>
            </div>

        </div>
        <slot></slot>
    </div>
</template>

<script>
    import Utils from "../../helpers/util";

    export default {
        components: {Utils},
        data() {
            return {
                showPhoneNumber:true,
                showEmailAddress:true,
            };
        },
        name: 'JobProfileHeader',
        props: {
            job_details: {
                default: {}
            },
        },
        methods: {
            processPhoneNumber(customer) {
                let settings = (customer.settings) ? JSON.parse(customer.settings) : null;
                let show_mobile = true;
                let show_phone = true;
                let return_mobile = '';

                if (settings != null) {
                    show_mobile = (settings.mobile == 'auto') ? false : true;
                    show_phone = (settings.phone == 'auto') ? false : true;
                }
                if (show_mobile == true && customer.mobile.length > 1) {
                    this.showPhoneNumber = true;
                    return_mobile += '(' + customer.mobile_area_code + ') (' + customer.mobile + ')  ';
                }
                if (return_mobile == ''){
                    this.showPhoneNumber = false;
                    return_mobile = ' - ';
                }

                return return_mobile;
            },

            processEmailAddress(customer){
                let settings = (customer.settings) ? JSON.parse(customer.settings) : null;
                let show_email = true;
                let return_email = '';

                if (settings != null) {
                    show_email = (settings.email == 'auto') ? false : true;
                }
                if (show_email == true && customer.email.length > 1) {
                    this.showEmailAddress = true;
                    return customer.email;
                }else{
                    this.showEmailAddress = false;
                    return ' - ';
                }
            },
            starRatings(){
                return Utils.methods.starRatings(this.job_details.customer.rating_count)
            }
        },
        mounted() {
        }
    };
</script>

<style lang="scss">
    @import "../../assets/styles/sass/_components/radio/popup-radio";
</style>
