<template>

    <div class="input-group mb-12 activity-log-search-row commissions-payment-report-filters" >
        <div class="left-clum">
            <div class="cft-filtre-max-width">
                <div class="cpt-item">
                    <div class="datepic">
                        <div class="input-group-prepend">
                            <label class="input-group-text"> From </label>
                        </div>
                        <date-picker
                                id="vueTableFromDate"
                                @selected="checkOnChaneFrom"
                                v-model="dateFilter.fromFilterDate"
                                :disabled-dates="{ from: disableDates('from', dateFilter.toFilterDate) }"
                                :class="'date_picker'"
                                :format="'yyyy-MM-dd'"
                                :bootstrap-styling="true"
                                :placeholder="'From'"
                                :style="['padding-left:0']"
                                :calendar-button="true"
                                :calendar-button-icon="'fa fa-calendar'"
                        />
                    </div>
                </div>
                <div class="cpt-item">
                    <div class="datepic">
                        <div class="input-group-append">
                            <label class="input-group-text">To</label>
                        </div>
                        <date-picker
                                id="vueTableToDate"
                                :disabled=isDisable
                                @selected="checkOnChaneTo"
                                :disabled-dates="{ to: disableDates('to', dateFilter.fromFilterDate) }"
                                v-model="dateFilter.toFilterDate"
                                :class="'date_picker'"
                                :format="'yyyy-MM-dd'"
                                :bootstrap-styling="true"
                                :placeholder="'To'"
                                :style="['padding-left:0']"
                                :calendar-button="true"
                                :calendar-button-icon="'fa fa-calendar'"
                        />
                    </div>
                </div>
                <div class="cpt-item">
                    <div class="input-group-prepend">
                        <label class="input-group-text"> Date Period </label>
                    </div>
                    <select class="form-control" @change="filterRange"
                            v-model="getSelectedValue">
                        <option value="">Please Select</option>
                        <option value="0">Last 24 hours</option>
                        <option value="1">Last Week</option>
                        <option value="2">This Month</option>
                        <option value="3">Last Month</option>
                        <option value="4">Last 3 Months</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="right-clum">
            <div class="right-cpt-item">
                <form class="form-inline filter-bar">
                    <div class="form-group">
                        Search: <input type="text" v-model="filterText" class="form-control search-feild form-control-sm" @keyup="doFilterDateWithSearch"
                                       :placeholder="searchPlaceholder">
                    </div>
                </form>
            </div>
        </div>

    </div>

</template>

<script>
    import DatePicker from 'vuejs-datepicker';

    export default {
        components: {
            DatePicker
        },
        data() {
            return {
                getSelectedValue: '',
                isFrom: false,
                isTo: false,
                isDisable: true,
                dateFilter: {
                    fromFilterDate: '',
                    toFilterDate: ''
                },
                filterText: '',
                stopTableReload: false
            }
        },
        props: {
            disconnectFilters : {
                type: Boolean,
                default: false
            },
            searchPlaceholder: {
                type: String,
                default: ''
            }
        },
        methods: {
            disableDates(mode, date) {
                let modified = new Date(Date.now());
                switch (mode) {
                    case 'to':
                        modified = date ? new Date(date) : new Date(new Date().setFullYear(new Date().getFullYear() - 1));
                        break;
                    case  'from':
                        modified = date ? new Date(date) : new Date(Date.now());
                        break;
                }
                return modified;
            },
            filterRange() {
                let self = this;
                let start = '';
                let end = '';
                let firstOfThisMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                switch (this.getSelectedValue) {
                    case '0':
                        start = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
                        end = moment().format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '1':
                        start = moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss');
                        end = moment().format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '2':
                        start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        end = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '3':
                        start = moment(firstOfThisMonth).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        end = moment(firstOfThisMonth).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    case '4':
                        start = moment(firstOfThisMonth).subtract(3, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        end = moment(firstOfThisMonth).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        self.dateFilter.fromFilterDate = start;
                        self.dateFilter.toFilterDate = end;
                        this.isFrom = true;
                        this.isTo = true;
                        this.isDisable = false;
                        break;
                    default:
                        start = '';
                        end = '';
                        this.isFrom = false;
                        this.isTo = false;
                        this.isDisable = true;
                }

                this.doFilterDateWithSearch();
            },
            checkOnChaneTo() {
                this.getSelectedValue = "";
                this.isTo = true;
                this.doFilterDateWithSearch();
            },
            checkOnChaneFrom() {
                this.getSelectedValue = "";
                this.isFrom = true;
                this.isDisable = false;
                this.doFilterDateWithSearch();
            },
            doFilterDate() {
                if (this.isFrom && this.isTo) {
                    this.$events.fire('filter-date', this.dateFilter);
                }
            },
            doFilterDateWithSearch() {
                if(((this.isFrom && this.isTo) || this.disconnectFilters) && !this.stopTableReload){
                    this.$events.fire('filter-date-and-search-bar', this.dateFilter, this.filterText);
                }
            },
        },
        mounted() {
            let self = this;
            let start = moment().subtract(1, 'days').format('YYYY-MM-DD');
            let end = moment().format('YYYY-MM-DD HH:mm:ss');
            self.dateFilter.fromFilterDate = '';
            self.dateFilter.toFilterDate = '';
            this.isFrom = false;
            this.isTo = false;
            this.isDisable = false;
        },
        events: {
            // autoFireDateRangeFilter(filterData) {
            //     this.doFilterDateWithSearch(filterData);
            // }
        }
    }
</script>

<style scoped lang="scss">


</style>
