<template>
    <div>
        <form id="company-create-form">

            <div class="step-content active" id="profile">

                <fieldset class="form-group profile-areas">

                    <div class="row">

                        <div class="col-sm-4">

                            <div class="form-group profile">

                                <label>Upload Profile Picture</label>

                                <div class="profile-area">

                                    <form action="#" class="profile" id="my-awesome-dropzone">
                                        <div class="dz-message">
                                            <div>
                                                <uploader ref="logoImage"
                                                          :id="'logo'"
                                                          :maxFiles="1"
                                                          v-validate="'required'"
                                                          data-vv-as="Logo"
                                                          class="text-center"
                                                          :acceptedFiles="'image/*'"
                                                          v-on:file-added="addLogo"
                                                          name="logo"></uploader>
                                            </div>

                                        </div>
                                    </form>

                                </div>
                                <error-message :message="errors.first('logo')"></error-message>
                            </div>

                        </div>

                        <div class="col-md-8">

                            <div class="form-group manage-customer-map enterprise-map">
                                <location-map ref="locationMap"
                                              :placeholder="'Your Address'"
                                              :id="'managerRegisterAddress'"
                                              :address="data.address"
                                              class="col-xs-12 gmap2"
                                              name="managerRegisterAddress"
                                              v-validate="'required'"
                                              data-vv-as="Address"
                                              v-on:address-selected="getAddress"/>
                                <error-message :message="errors.first('managerRegisterAddress')"></error-message>
                            </div>
                        </div>

                    </div>


                    <div class="row">

                        <div class="col-sm-4">
                            <div class="form-group filter-input">
                                <label>Title*</label>
                                <select class="form-control" v-model="data.title"
                                        name="title"
                                        id="title"
                                        v-validate="'required'"
                                        data-vv-as="title">
                                    <option v-for="title in titles" :value="title.id">{{ title.label }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>First Name*</label>
                                <input id="iFirstName" name="first_name" class="form-control" placeholder="First Name"
                                       v-validate="'required'"
                                       v-model="data.first_name"
                                       data-vv-as="First name"
                                       type="text">
                                <error-message :message="errors.first('first_name')"></error-message>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Last Name*</label>
                                <input id="iLastName" name="last_name" class="form-control" placeholder="Last Name"
                                       type="text"
                                       v-validate="'required'"
                                       data-vv-as="Last Name"
                                       v-model="data.last_name">
                                <error-message :message="errors.first('last_name')"></error-message>
                            </div>
                        </div>

                    </div>


                </fieldset>

                <div class=" new-form-theme enterprise-profile-info" >
                    <h5 class="form-header" style="margin-bottom: 15px !important;">
                        Contact Information
                    </h5>
                    <div class="row">

                        <div class="col-sm-4">


                            <label>Phone Number</label>

                            <div class="input-group mobile-group">

                                <select class="form-control" v-model="data.phone.code" data-vv-as="country code">
                                    <option value="+61">+61</option>
                                    <option value="+64">+64</option>
                                </select>

                                <div class="input-group-append">
                                    <input placeholder="Phone Number" type="text" class="form-control"
                                           v-model="data.phone.number" name="phone.number" v-validate="'numeric|max:10|min:9'" data-vv-as="phone number">
                                </div>

                                <error-message :message="errors.first('phone.code')"></error-message>
                                <error-message :message="errors.first('phone.number')"></error-message>


                            </div>

                        </div>

                        <div class="col-sm-4">


                            <label>Mobile Number*</label>

                            <div class="input-group mobile-group">

                                <select class="form-control" v-model="data.mobile.code" v-validate="'required'"
                                        name="mobile.code" data-vv-as="Mobile Code">
                                    <option value="+61">+61</option>
                                    <option value="+64">+64</option>
                                </select>

                                <div class="input-group-append">
                                    <input class="form-control" placeholder="Mobile Number" type="text"
                                           v-model="data.mobile.number" v-validate="'required|numeric|max:10|min:9'"
                                           data-vv-as="Mobile Number" name="mobile.number">
                                </div>

                                <error-message :message="errors.first('mobile.code')"></error-message>
                                <error-message :message="errors.first('mobile.number')"></error-message>


                            </div>

                        </div>

                    </div>
                </div>

                <div class=" new-form-theme enterprise-profile-info" >
                    <h5 class="form-header" style="margin-bottom: 15px !important;">
                        Account Information
                    </h5>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Email Address</label>
                                <input placeholder="Email Address" name="admin.email" type="text" class="form-control"
                                       data-vv-as="Admin Email Address"
                                       v-model="data.admin.email" v-validate="'required'" disabled>
                                <error-message :message="errors.first('admin.email')"></error-message>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 25px;">

                        <div class="col-sm-12">

                            <div class="form-group">

                                <label class="switch" style="margin-top: -2px; margin-right: 5px;">

                                    <input type="checkbox" v-model="data.setPassword">

                                    <span class="slider round"></span>

                                </label>

                                <div class="switch-label">Set Password</div>

                            </div>

                        </div>

                    </div>

                    <div class="row" style="margin-top: 15px;" v-if="data.setPassword">

                        <div class="col-sm-12">
                            <div role="alert" class="alert alert-info alert-dismissible fade show">
                                <strong>Password Criteria</strong>
                                <ul>
                                    <li>At least ten characters</li>
                                    <li>At least one uppercase character</li>
                                    <li>At least one number</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-sm-4">

                            <div class="form-group password-item"
                                 :class="show_current_password=='password'?'password-hide':'password-show'">
                                <label>Current Password</label>
                                <input placeholder="Current Password"
                                       name="admin.current_pw"
                                       :type="show_current_password"
                                       class="form-control"
                                       v-validate="'required'"
                                       data-vv-as="current password"
                                       v-model="data.admin.current_pw"
                                       aria-required="false"
                                       aria-invalid="false">
                                <a href="#" class="pasword-eye"
                                   @click.prevent="show_current_password = (show_current_password=='input'?'password':'input')"></a>
                                <span data-v-2d4315e8="" class="text-danger"></span>
                            </div>
                            <error-message :message="errors.first('admin.current_pw')"></error-message>

                        </div>

                        <div class="col-sm-4">

                            <div class="form-group password-item"
                                 :class="show_password=='password'?'password-hide':'password-show'">
                                <label>New Password*</label>
                                <input placeholder="New Password"
                                       name="admin.new_pw"
                                       :type="show_password"
                                       class="form-control"
                                       :true-value="1" :false-value="0"
                                       v-validate="'required|complex_password'"
                                       data-vv-as="new password"
                                       v-model="data.admin.new_pw"
                                       ref="password"
                                       aria-required="false"
                                       aria-invalid="false">
                                <a href="#" class="pasword-eye"
                                   @click.prevent="show_password = (show_password=='input'?'password':'input')"></a>
                                <span data-v-2d4315e8="" class="text-danger"></span>
                            </div>
                            <error-message :message="errors.first('admin.new_pw')"></error-message>

                        </div>

                        <div class="col-sm-4"
                             :class="show_confirm_password=='password'?'password-hide':'password-show'">

                            <div class="form-group password-item">
                                <label>Confirm Password*</label>
                                <input placeholder="Confirm Password"
                                       name="admin.confirm_pw"
                                       :type="show_confirm_password"
                                       class="form-control"
                                       v-validate="'required|confirmed:password'"
                                       data-vv-as="confirm password"
                                       v-model="data.admin.confirm_pw"
                                       aria-required="false"
                                       aria-invalid="false">
                                <a href="#" class="pasword-eye"
                                   @click.prevent="show_confirm_password = (show_confirm_password=='input'?'password':'input')"></a>
                                <span data-v-2d4315e8="" class="text-danger"></span>
                            </div>
                            <error-message :message="errors.first('admin.confirm_pw')"></error-message>
                        </div>

                    </div>
                </div>

            </div>

        </form>

        <div class="row">
            <div class="col-sm-12">
                <div class="text-right last-actions">
                    <button class="btn btn-primary btn-lg" @click="submit">Update</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import MapContainer from '../../../components/elements/Map';
    import DropZoneUploader from "../../../components/file_uploader/DropzoneUploader";
    import LocationMap from "../../../components/elements/Map";
    import ProfileUploader from './ProfileUploader';
    import Uploader from "../../../components/file_uploader/Uploader";
    import ErrorMessage from '../../../components/validation/ErrorMessage';
    import Util from '../../../helpers/util';

    export default {
        name: 'CommitteeMemberManageGeneral',
        mixins: [Util],
        components: {
            // DropZoneUploader,
            MapContainer,
            LocationMap,
            ProfileUploader,
            Uploader,
            ErrorMessage
        },

        props: {
            email_address: {
                type: String,
                default: ''
            },
            form_data: {
                type: Object,
                default: function () {
                    return {
                        titles: [],
                        countries: []
                    };
                }
            }
        },
        data() {
            return {
                currencies: {},
                show_password: 'password',
                show_confirm_password: 'password',
                show_current_password: 'password',
            };
        },
        computed:{
            ...mapGetters({
                user: "userdata/user",
                data: "profile/general",
                formData: "profile/formData",
            }),
            types(){
                return this.formData.types;
            },
            titles(){
                return this.formData.titles;
            }
        },
        methods: {
            getAddress(location) {
                this.data.location = location;
            },
            addLogo(id, files) {
                this.data.logo = files[0];
            },
            submit() {
                let self = this;
                this.$validator.validate().then(function (res) {
                    if (res) {
                        self.$toast.loading(true);
                        let objectToFormData = require('object-to-formdata');
                        XhrRequest.send(XhrRequest.XHR_POST_METHOD, 'committee/update/general', objectToFormData(self.data), true)
                            .then((response) => {
                                self.$toast.loading(false);
                                self.$toast.success('Profile Updated Successfully', true, true);
                            })
                            .catch((error) => {
                                self.$toast.loading(false);
                                self.$toast.error('Profile Update failed. Please try again', true, true);
                                console.error(error);
                            });
                    } else {
                        self.$el.querySelector('[name="' + self.$validator.errors.items[0].field + '"]').scrollIntoView(false)
                    }
                });
            }
        },
        mounted() {
            let self = this;
            this.$nextTick(() => {
                if (typeof self.$refs.locationMap != undefined){
                    self.$refs.locationMap.setAddress(this.data.address, true);
                }
                self.$refs.logoImage.thumbnail(this.data.logo, true);
            })
        },
        beforeDestroy() {
        },
        watch: {
            'data.logo': function (val) {
                if (typeof val === 'string'){
                    this.$refs.logoImage.thumbnail(val, true);
                }
            }
        },
        events: {}
    };
</script>


<style lang="scss">

    @import "../../../assets/styles/sass/company/views/company-profile";
    @import "../../../assets/styles/sass/_components/switch/switch.scss";
    @import '../../../assets/styles/sass/_components/radio/radio.scss';

    .profile {

        label {

            width: 100%;
            float: left;
            text-align: center;
            margin-bottom: 0px;
            border: 1px solid #d2d6e0;
            border-bottom: none;
            border-radius: 0.25rem 0.25rem 0 0;
            padding: 7px 15px;

        }

        .profile-area {
            float: left;
            width: 100%;
            background-color: #ececec;
            margin-bottom: 15px;
            padding-top: 0;
            border-radius: 0 0 5px 5px;
            min-height: 200px !important;
        }

    }

    #customerRegisterAddress {

        height: 199px !important;
        border-radius: 0 0 6px 6px !important;

    }

</style>
