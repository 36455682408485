<template>
    <div class="container signup-form">

        <div class="row">

            <div class="col-md-5 col-centered">

<!--                <form class="form-horizontal">-->

                    <div class="row">

                        <div class="col-sm-12">

                            <div class="signup-box customer authatication-box digit-code-area">


                                <div class="row">

                                    <div class="col-sm-12">

                                        <div class="logo-header">

                                            <img src="../../assets/img/i4tradies.png">

                                            <h2>Two-Factor Authentication</h2>

                                        </div>

                                    </div>

                                </div>


                                <div class="img-type">
                                    <img v-if="user && user.profile_logo" :src="user.profile_logo" />
                                    <img v-else src="../../assets/img/user.png" />
                                </div>


                                <div class="input-below digit-code-area" id="pin-codefilelds">
                                    <p class="p-text-2">
                                        {{ verificationHelpText }}
                                    </p>
                                    <pincode-input
                                            v-model="code"
                                            placeholder="0"
                                            class="outer-input"
                                            :length="6"
                                    />
                                    <span class="text-danager" v-if="hasError">{{ verificationErrorText }}</span>
                                </div>


                                <a class="need-way" href="#" data-toggle="modal" data-target="#myModal">
                                    {{ resendCodeHelpText }}
                                </a>


                                <div class="btn-area" style="margin-top: 30px;">
                                    <button class="btn btn-primary" @click="verifyCode">
                                        Continue
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>

<!--                </form>-->

            </div>

        </div>

        <!-- Modal -->
        <div id="myModal" class="modal fade another-way-modal" role="dialog">
            <div class="modal-dialog">

                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Need another way to authenticate?</h4>
                    </div>
                    <div class="modal-body">
                        <p style="float: left;"><img class="img-phone" src="img/sms-auth.png"/></p>
                        <h4>Text Message</h4>

<!--                        <p class="sub">We will send a 6-digit code-->
<!--                            to {{\App\I4t\Facades\Util::maskPhoneNumber($user->contact()['mobile'])}} to get you set-->
<!--                            up.</p>-->
                    </div>
                    <div class="modal-footer">
<!--                        <form class="form-horizontal" method="POST" action="{{ route('2fa.send.sms') }}">-->
<!--                            <button type="submit" class="btn btn-default btn-go">Text me the code</button>-->
<!--                        </form>-->
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>

    import PincodeInput from 'vue-pincode-input';
    import { mapState, mapActions, mapGetters } from 'vuex';
    export default {
        name: "VerifyTwoFactorAuth",
        components:{
            PincodeInput
        },
        data() {
            return {
                hasError: false,
                verificationErrorText: 'Invalid PIN code. Try again please',
                code:null
            }
        },
        computed:{
            ...mapGetters({
                user: "userdata/user"
            }),

            verificationHelpText() {
                if (this.$route.params?.verify_method === 1){
                    return 'Enter the 6-digit code from your Code Generator';
                }else if (this.$route.params?.verify_method === 2){
                    return 'When you receive your 6-digit code via SMS, <br/>enter it to continue';
                }
            },

            resendCodeHelpText() {
                if (this.$route.params?.verify_method === 1){
                    return 'Need another way to authenticate?';
                }else if (this.$route.params?.verify_method === 2){
                    return 'Didn\'t receive the SMS? Resend!';
                }
            }
        },
        mounted(){
            console.log("verify_method", !this.user)
            if (!this.user){
                this.$router.push({
                    name: 'Login'
                })
            }

        },
        methods: {
            ...mapActions({
                verifyTwoFactorCode: "auth/verifyTwoFactorCode"
            }),
            verifyCode(){
                this.$toast.loading(true);
                this.verifyTwoFactorCode(this.code).then(res => {
                    this.$toast.loading(false);
                   if (res){
                       this.$router.push({
                           name: 'dashboard'
                       })
                   }else{
                       this.hasError = true;
                   }
                });
            }
        }

    }
</script>

<style scoped>

</style>