<template>
    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer blue-table">

        <div class="row filters" v-if="showDateFilter">
            <div class="col-md-3 first-col">
                <vue-filter v-if="bShowFilter"/>
            </div>
            <div class="col-md-6 second-col" v-if="showDateFilter">
                <span class="top-message">{{bDateRangeText}}</span>
                <date-range/>
            </div>
        </div>

        <div class="row filters admin-status-selection" v-else-if="bShowFilter||bShowItemsPerPage">
            <div class="col-md-3 second-col status-selection" >
              <div v-if="showDropDown" class="suplier-select">
                <span>{{DropDownText}}</span>
                <drop-down :options="dropDown"></drop-down>
              </div>
            </div>
            <div class="col-md-5"></div>
            <div class="col-md-4 search-filter">
              <vue-filter v-if="bShowFilter" :filterSearch="filterSearch"/>
            </div>
        </div>

        <div class="row filters" v-if="showDateFilter2">
            <div class="col-md-3 first-col">
                <vue-filter v-if="bShowFilter"/>
            </div>
            <div class="col-md-6 second-col" v-if="showDateFilter2">
                <span class="top-message">{{bDateRangeText2}}</span>
                <date-range2/>
            </div>
        </div>

        <div class="row filters" v-if="showDateFilter2WithSearch">
            <div class="col-md-12">
                <span class="top-message">{{bDateFilter2Text}}</span>
                <date-range-with-search ref="dateRangeSearch" :disconnect-filters="disconnectDateFilter2WithSearch"/>
            </div>
        </div>

        <div class="row filters" v-if="showDateFilter2WithSearchExtraFilter">
            <div class="col-md-12">
                <span class="top-message">{{bDateFilter2Text}}</span>
                <assets-pre-approve-filters ref="dateRangeSearch" :disconnect-filters="disconnectDateFilter2WithSearch" :show-extra-filter="true"/>
            </div>
        </div>

        <div class="row filters custom-filter-section" v-if="bShowCustomFilter">
            <div class="col-md-9">
                <slot name="customfilters"></slot>
            </div>
        </div>

        <div class="row filters admin-status-selection" v-if="bShowFilterCustomList">
            <div class="col-md-2 second-col status-selection" >
                <div v-if="showDropDown" class="suplier-select">
                    <span>{{DropDownText}}</span>
                    <drop-down :options="dropDown"></drop-down>
                </div>
            </div>
            <slot name="customfilterslist"></slot>
            <div class="col-md-4 search-filter">
                <vue-filter :filterSearch="filterSearch"/>
            </div>
        </div>

        <div class="row">
            <vue-table :id="tableId"
                       :css="finalCss.table"
                       :width="tableWidth"
                       :class="tableClasses"
                       :fields="fields"
                       :api-url="apiUrlCombined"
                       :multi-sort="isMultisort"
                       :sort-order="sortOrder"
                       :api-mode="apiMode"
                       :data-path="dataPath"
                       :data="data"
                       :http-options="httpOptions"
                       :data-manager="dataManager"
                       :pagination-path="paginationPath"
                       :append-params="moreParams"
                       :track-by="trackBy"
                       :perPage="itemsPerPage"
                       :paginationButtons="paginationButtons"
                       :show-filter="showFilter"
                       :show-items-per-page="showItemsPerPage"
                       :show-pagination-info="showPaginationInfo"
                       :show-pagination="showPagination"
                       :row-class="onRowClass"
                       :show-custom-filter="showCustomFilter"
                       ref="vuetable"
                       @vuetable:row-clicked="onRowClicked"
                       @vuetable:cell-clicked="onCellClicked"
                       @vuetable:pagination-data="onPaginationData"
                       @vuetable-pagination:change-page="onChangePage"
                       @vuetable:refresh="onRefreshDataTable"
                       @vuetable:load-success="loadSuccess"
                       @vuetable:loading="loading"
                       @vuetable:checkbox-toggled="toggledCheckbox"
                       @vuetable:checkbox-toggled-all="allToggledCheckbox"
            >
                <template :slot="slotName" slot-scope="props"
                          v-for="(slotName) in  $scopedSlots?Object.keys($scopedSlots):null">
                    <slot :name="slotName" :row-data="props.rowData" :row-index="props.rowIndex"
                          :row-field="props.rowField"></slot>
                </template>
            </vue-table>
        </div>
        <div class="row filter-row" v-if="bShowPaginationInfo || showPagination">
            <div class="col-md-3 vuet-pagination-info">
                <vuetable-pagination-info v-if="bShowPaginationInfo"
                                          ref="paginationInfo"
                                          :noDataTemplate="'Showing 0 entries'"
                                          :infoTemplate="paginationInfoTemplate"
                />
            </div>
            <div class="col-md-6">
                <vuetable-pagination
                        ref="pagination"
                        v-if="showPagination"
                        :css="finalCss.pagination"
                        :buttonSettings="paginationButtons"
                        @vuetable-pagination:change-page="onChangePage"
                />
            </div>
            <div class="col-md-3 show-entries">
              <per-page-records/>
            </div>
        </div>
    </div>
</template>

<script>
    // import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import Vue from 'vue';
    import VueTable from 'vuetable-2/src/components/Vuetable';
    import VuetablePagination from './vuetable2/VuetablePaginate';
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo';
    import VuetableDropDown from 'vuetable-2/src/components/VuetablePaginationDropdown';
    import VueFilter from './vuetable2/FilterBar';
    import PerPageRecords from './vuetable2/PerPageRecords';
    import DateRange from './vuetable2/DateRange';
    import DateRange2 from './vuetable2/DateRange2';
    import DateRangeWithSearch from './vuetable2/DateRangeWithSearch';
    import DropDown from './vuetable2/Dropdown';
    import DateRangeWithSearchExtraFilter from "./vuetable2/AssetsPreApproveFilters";
    import AssetsPreApproveFilters from "./vuetable2/AssetsPreApproveFilters";
    import {apiBaseUrl} from "../../config/apiConfig";
    import appConstants from "../../const/AppConstants";

    export default {
        name: 'VueDataTable',
        components: {
            AssetsPreApproveFilters,
            DateRangeWithSearchExtraFilter,
            PerPageRecords,
            VueTable,
            VuetablePagination,
            VuetablePaginationInfo,
            VueFilter,
            VuetableDropDown,
            DateRange,
            DateRange2,
            DateRangeWithSearch,
            DropDown
        },
        props: {
            filterSearch: {
                type: String,
                default: ''
            },
            dateRangeText: {
                type: String,
                default: 'Please select the Date Range to identify Jobs to be exported to Xero'
            },
            dateRangeText2: {
                type: String,
                default: ''
            },
            itemsToshow: {
                default: 10
            },
             showFilter: {
                type: Boolean,
                default: true
            },
            ShowFilterCustomList:{
                type: Boolean,
                default: false
            },
            showCustomFilter:{
                type : Boolean,
                default:false
            },
            showExtraFilter:{
                type : Boolean,
                default:false
            },
            showItemsPerPage: {
                type: Boolean,
                default: false
            },
            showPaginationInfo: {
                type: Boolean,
                default: true
            },
            tableId: {
                type: String,
                default: 'dataTable'
            },
            tableWidth: {
                type: String,
                default: '100%'
            },
            tableClasses: {
                type: String,
                default: 'table'
            },
            fields: {
                type: Array,
                default: []
            },
            apiUrl: {
                type: String
            },
            paginationPath: {
                type: String,
                default: 'result'
            },
            dataPath: {
                type: String,
                default: 'result.data'
            },
            sortOrder: {
                type: Array,
                default() {
                    return [];
                }
            },
            dataManager: {
                type: Function,
                default: function () {
                    return ''
                }
            },
            onRowClass: {
                type: Function,
                default: function () {
                    return ''
                }
            },
            showPagination: {
                type: Boolean,
                default: true
            },
            css: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            isMultisort: {
                type: Boolean,
                default: true
            },
            apiMode: {
                type: Boolean,
                default: true
            },
            trackBy: {
                type: String,
                default: 'id'
            },
            paginationButtons: {
                type: Object
            },
            appendParams: {
                type: Object
            },
            data: {},
            DropDownText: {
                default: 'Status'
            },
            emptyMessage: {
                type: String,
                default: null
            },
            searchPlaceholder: {
                type: String,
                default: ''
            },
            enableAuth: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                httpOptions:{},
                finalCss: {},
                itemsPerPage: this.itemsToshow,
                defaultCss: {
                    table: {
                        tableClass: 'table dataTable no-footer',
                        ascendingIcon: 'sorting_asc',
                        descendingIcon: 'sorting_desc'
                    },
                    pagination: {
                        wrapperClass: 'dataTables_paginate paging_simple_numbers',
                        listWrapper: 'pagination',
                        activeClass: 'page-item active',
                        disabledClass: 'disabled',
                        pageClass: 'paginate_button page-item',
                        linkClass: 'link',
                        icons: {
                            first: 'first-page',
                            prev: 'previous-page',
                            next: 'next-page',
                            last: 'last-page',
                        },
                    },
                    icons: {
                        first: 'glyphicon glyphicon-step-backward',
                        prev: 'glyphicon glyphicon-chevron-left',
                        next: 'glyphicon glyphicon-chevron-right',
                        last: 'glyphicon glyphicon-step-forward',
                    },
                },
                moreParams: this.appendParams,
                paginationInfoTemplate: 'Showing {from} to {to} of {total} entries',
                bShowFilter: this.showFilter,
                bShowFilterCustomList : this.ShowFilterCustomList,
                bShowCustomFilter : this.showCustomFilter,
                bShowExtraFilter : this.showExtraFilter,
                bShowItemsPerPage: this.showItemsPerPage,
                bShowPaginationInfo: this.showPaginationInfo,
                showDateFilter: false,
                showDateFilter2: false,
                bDateRangeText: this.dateRangeText,
                bDateRangeText2: this.dateRangeText2,
                showDateFilter2WithSearch: false,
                showDateFilter2WithSearchPerPageRecord: true,
                showDateFilter2WithSearchExtraFilter: false,
                showDateFilter2WithSearchExtraFilterPerPageRecord: false,

                bDateFilter2Text: '',
                sentReqCount: 0,
                disconnectDateFilter2WithSearch: false,
                showDropDown: false,
                dropDown: {
                    default: null
                },
                apiUrlCombined:'',
                selectedRowIndex : ''
            };
        },
        methods: {
            showDropDownField(status, options) {
                this.showDropDown = status;
                this.dropDown = options;
            },
            showDateField(status) {
                this.showDateFilter = status;
            },
            showDateField2(status) {
                this.showDateFilter2 = status;
            },
            showDateField2WithSearch(status) {
                this.showDateFilter2WithSearch = status;
            },
            showDateField2WithSearchPerPageRecord(status) {
                this.showDateFilter2WithSearchPerPageRecord = status;
            },
            showDateField2WithSearchExtraFilter(status) {
                this.showDateFilter2WithSearchExtraFilter = status;
            },
            showDateField2WithSearchExtraFilterPerPageRecord(status) {
                this.showDateFilter2WithSearchExtraFilterPerPageRecord = status;
            },

            disconnectDateFilterWithSearch(status) {
                this.disconnectDateFilter2WithSearch = status;
            },
            selectedRows() {
                return this.$refs.vuetable.selectedTo;
            },
            deSelectAllRows() {
                return this.$refs.vuetable.toggleAllCheckboxes('__checkbox', {target: {checked: false}});
            },
            refreshDataTable() {
                this.$refs.vuetable.refresh();
            },
            hardReload() {
                this.$refs.vuetable.resetData();
                this.$refs.vuetable.reload();
            },
            reloadDataTable(appendParams = false) {
                if (appendParams)
                    this.appendParams = appendParams;
                this.$refs.vuetable.reload();
            },
            onRefreshDataTable() {
                this.$emit('onRefreshDataTable');
            },
            onPaginationData(paginationData) {
                try {
                    if (this.bShowPaginationInfo) {
                        this.$refs.paginationInfo.setPaginationData(paginationData);
                    }
                    this.$refs.pagination.setPaginationData(paginationData);
                    //this.$refs.dropdown.setPaginationData(paginationData)
                } catch (e) {
                }
            },
            loadSuccess(response) {
                if (!response.data.result.length) {
                    let emptyMessage = this.emptyMessage ? this.emptyMessage : 'No Data Available';
                    this.setEmptyResultText(emptyMessage);
                }
                this.$emit('loadSuccess', response);
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },
            onCellClicked(data, field, event) {
                this.$emit('onCellClicked', data, field, event);
            },
            onFilterSet(filterText) {
                this.appendParams.filter = filterText;
                Vue.nextTick(() => this.$refs.vuetable.refresh());
            },
            onFilterReset() {
                delete this.appendParams.filter;
                Vue.nextTick(() => this.$refs.vuetable.refresh());
            },
            onRowClicked(dataItem, event) {
                this.$emit('onRowClicked', dataItem, event);
            },
            toggledCheckbox(payload, dataItem) {
                this.$emit('toggledCheckbox', payload, dataItem);
            },
            allToggledCheckbox(payload, dataItem) {
                this.$emit('allToggledCheckbox', payload, this.$refs.vuetable.tableData);
            },
            getAllData(){
              return this.$refs.vuetable.tableData;
            },

            setDefaults() {
                this.finalCss = Object.assign(this.defaultCss, Object.assign({}, this.css));
                if(this.enableAuth) {
                    try {
                        this.httpOptions = {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('access_credentials')).access_token,
                                'app-id' : appConstants.COMMUNITY_SELECT_APP_ID
                            }
                        };
                    } catch (e) { console.log('Authentication Error.'); }
                }
            },
            normalizeFields() {
                this.$refs.vuetable.normalizeFields();

            },
            loading() {
                this.setEmptyResultText('Loading...');
            },
            setEmptyResultText(text) {
                let td = document.createElement('td');
                td.innerHTML = text;
                td.className = 'vuetable-empty-result text-center';
                td.setAttribute("colspan", this.fields.length);
                if (this.$refs.vuetable.$el.innerHTML.indexOf('vuetable-empty-result') > 0)
                    this.$refs.vuetable.$el.querySelector('td.vuetable-empty-result').replaceWith(td);
            }
        },
        beforeMount() {
            this.setDefaults();
        }
        ,
        watch: {
            'itemsPerPage'(val, oldVal) {
                this.$nextTick(function () {
                    this.$refs.vuetable.refresh();
                });
            },
            'paginationComponent'(val, oldVal) {
                this.$nextTick(function () {
                    this.$refs.pagination.setPaginationData(this.$refs.vuetable.tablePagination);
                });
            },
            apiUrl(val, oldVal) {
                this.apiUrlCombined = apiBaseUrl + this.apiUrl;
            }
        },
        events: {
            'filter-set'(filterText, thisReqId) {
                console.log('filterset');
                let self = this;
                this.sentReqCount++;
                if (self.showDateFilter) {
                    self.$events.fire('autoFireDateRangeFilter', filterText);
                } else if (self.showDropDown) {
                    self.$events.fire('autoFireDropDownFilter', filterText);
                } else {
                    if (thisReqId == this.sentReqCount) {
                        if (filterText.length >= 3) {
                            self.moreParams = {
                                filter: filterText
                            };
                            Vue.nextTick(() => self.$refs.vuetable.refresh());
                        }

                        if (filterText.length == 0) {
                            self.moreParams = {};
                            Vue.nextTick(() => self.$refs.vuetable.refresh());
                        }
                    }
                }

            },
            'filter-reset'() {
                this.moreParams = {},
                    Vue.nextTick(() => this.$refs.vuetable.refresh());
            },
            'filter-date'(dates) {
                this.moreParams = {
                    dates
                };
                Vue.nextTick(() => this.$refs.vuetable.refresh());
                this.$events.fire('autoFireDateRangeFilterAdminDashboard');
            },
            'filter-ddl'(key) {
                this.moreParams = {
                    key
                };
                Vue.nextTick(() => this.$refs.vuetable.refresh());
            },
            'filter-ddl-and-search-bar'(key, filterText) {
                this.moreParams = {
                    filter: filterText,
                    key
                };
                Vue.nextTick(() => this.$refs.vuetable.refresh());
            },
            'filter-date-and-search-bar'(dates, filterText) {
                this.moreParams = {
                    filter: filterText,
                    dates
                };
                Vue.nextTick(() => this.$refs.vuetable.refresh());
            },
            'filter-custom-filter'(filters) {
                this.moreParams = filters;
                Vue.nextTick(() => this.$refs.vuetable.refresh());
            }
        }
    };
</script>

<style scoped>
    @import "../../assets/styles/sass/_components/datatable/vuetable2.scss";
</style>
