<template>
    <div>
        <div class="row">

            <div class="col-sm-6 left-details">

                <div class="left-job-details">

                    <div class="sub-title-job">{{ ((this.$user.role === 'enterprise' || jobInfo.job_detail.company.source === COMPANY_SOURCE_PICA) && jobInfo.job_group_type == 'quotation_request') ? 'Quotation Request' : $terminologies.job.singularTerm }} Details</div>

                    <ul>

                        <li>

                            <div class="img-icons">
                                <i class="fas fa-briefcase"></i>
                            </div>

                            <div class="job-detail-strong">
                                <div v-if="this.$user.role === 'enterprise' || jobInfo.job_detail.company.source === COMPANY_SOURCE_PICA" class="top-name">{{ jobInfo.job_group_type == 'quotation_request' ? 'QR' : 'WO' }} Number</div>
                                <div v-else class="top-name">Job Code</div>
                                <div class="dot-type">:</div>
                                <div class="last-name" style="text-transform: uppercase;"
                                     v-if="jobInfo.job_detail.job_meta_data.job_code">
                                    {{jobInfo.job_group_type == 'quotation_request' ? jobInfo.job_detail.job_meta_data.quotation_request_code :jobInfo.job_detail.job_meta_data.job_code}}
                                </div>
                            </div>

                        </li>

                        <li>

                            <div class="img-icons">
                                <i class="fas fa-level-down-alt"></i>
                            </div>

                            <div class="job-detail-strong">
                                <div class="top-name">Priority</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">
                                    <div>
                                        <div :class="(jobInfo.job_detail.job_meta_data.job_priority == 'Urgent')?'pri_type':''">
                                            {{jobInfo.job_detail.job_meta_data.job_priority}}
<!--                                            <sup>*</sup>-->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </li>

                        <li>

                            <div class="img-icons">
                                <i class="fas fa-stream"></i>
                            </div>

                            <div class="job-detail-strong">
                                <div class="top-name">Service Category</div>
                                <div class="dot-type">:</div>
                                <div class="last-name" :title="jobInfo.job_detail.job_meta_data.job_type">

                                    {{(jobInfo.job_detail.job_meta_data.job_type.length > 22)
                                    ? jobInfo.job_detail.job_meta_data.job_type.slice(0, 22)+'...'
                                    : jobInfo.job_detail.job_meta_data.job_type}}

                                </div>
                            </div>

                        </li>

                        <li v-if="$user.role != 'enterprise'">
                            <div class="img-icons">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000" width="15" height="15"
                                     viewBox="0 0 48 48">
                                    <path d="M40 17.37V8h-9.37L24 1.37 17.37 8H8v9.37L1.37 24 8 30.63V40h9.37L24 46.63 30.63 40H40v-9.37L46.63 24 40 17.37zM24 36c-6.63 0-12-5.37-12-12s5.37-12 12-12 12 5.37 12 12-5.37 12-12 12zm0-20c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"/>
                                </svg>
                            </div>
                            <div class="job-detail-strong">
                                <div class="top-name">Job Source</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">{{jobInfo.job_detail.job_meta_data.job_source}}</div>
                            </div>
                        </li>

                        <li v-if="jobInfo.job_detail.property">
                            <div class="img-icons">
                                <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 612 612"><g><g id="_x31_0_21_"><g>
                                    <path d="M63.311 73.862C28.342 73.862.0 102.204.0 137.172s28.342 63.311 63.311 63.311c34.968.0 63.31-28.342 63.31-63.311S98.279 73.862 63.311 73.862zm0 168.827C28.342 242.689.0 271.032.0 306c0 34.969 28.342 63.311 63.311 63.311 34.968.0 63.31-28.342 63.31-63.311C126.621 271.032 98.279 242.689 63.311 242.689zM63.311 411.518C28.342 411.518.0 439.859.0 474.827c0 34.969 28.342 63.311 63.311 63.311 34.968.0 63.31-28.342 63.31-63.311C126.621 439.859 98.279 411.518 63.311 411.518zM232.138 179.379h337.655c23.319.0 42.207-18.888 42.207-42.207s-18.888-42.207-42.207-42.207H232.138c-23.319.0-42.207 18.888-42.207 42.207S208.819 179.379 232.138 179.379zM569.793 263.793H232.138c-23.319.0-42.207 18.888-42.207 42.207s18.888 42.207 42.207 42.207h337.655C593.112 348.207 612 329.319 612 306S593.112 263.793 569.793 263.793zM569.793 432.621H232.138c-23.319.0-42.207 18.887-42.207 42.206s18.888 42.207 42.207 42.207h337.655c23.319.0 42.207-18.888 42.207-42.207S593.112 432.621 569.793 432.621z">
                                    </path></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                            </div>
                            <div class="job-detail-strong">
                                <div class="top-name">Plan No</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">{{ jobInfo.job_detail.property.parent ? jobInfo.job_detail.property.parent.reference_no : jobInfo.job_detail.property.reference_no}}</div>
                            </div>
                        </li>
                        <li v-if="jobInfo.job_detail.job_meta_data.plan_no">
                            <div class="img-icons">
                                <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 612 612"><g><g id="_x31_0_21_"><g>
                                    <path d="M63.311 73.862C28.342 73.862.0 102.204.0 137.172s28.342 63.311 63.311 63.311c34.968.0 63.31-28.342 63.31-63.311S98.279 73.862 63.311 73.862zm0 168.827C28.342 242.689.0 271.032.0 306c0 34.969 28.342 63.311 63.311 63.311 34.968.0 63.31-28.342 63.31-63.311C126.621 271.032 98.279 242.689 63.311 242.689zM63.311 411.518C28.342 411.518.0 439.859.0 474.827c0 34.969 28.342 63.311 63.311 63.311 34.968.0 63.31-28.342 63.31-63.311C126.621 439.859 98.279 411.518 63.311 411.518zM232.138 179.379h337.655c23.319.0 42.207-18.888 42.207-42.207s-18.888-42.207-42.207-42.207H232.138c-23.319.0-42.207 18.888-42.207 42.207S208.819 179.379 232.138 179.379zM569.793 263.793H232.138c-23.319.0-42.207 18.888-42.207 42.207s18.888 42.207 42.207 42.207h337.655C593.112 348.207 612 329.319 612 306S593.112 263.793 569.793 263.793zM569.793 432.621H232.138c-23.319.0-42.207 18.887-42.207 42.206s18.888 42.207 42.207 42.207h337.655c23.319.0 42.207-18.888 42.207-42.207S593.112 432.621 569.793 432.621z">
                                    </path></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                            </div>
                            <div class="job-detail-strong">
                                <div class="top-name">Plan No</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">{{jobInfo.job_detail.job_meta_data.plan_no}}</div>
                            </div>
                        </li>

                        <li>

                            <div class="img-icons">
                                <i class="fas fa-calendar-alt"></i>
                            </div>

                            <div class="job-detail-strong" v-if="jobInfo.job_detail!==undefined">
                                <div class="top-name">Created Date</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">
                                    {{jobInfo.job_detail.job_meta_data.dates.available_at.datetime_formatted}}
                                </div>
                            </div>

                        </li>

                        <li>

                            <div class="img-icons">
                                <i class="fas fa-file"></i>
                            </div>

                            <div class="job-detail-strong" v-if="this.$user.role == 'enterprise'">
                                <div class="top-name">Trades {{$terminologies.company.singularTerm}}</div>
                                <div class="dot-type">:</div>

                                <div class="last-name">
                                    <p v-if="jobInfo.job_detail.company.id == 96">
                                        {{(jobInfo.job_detail.company.name.length > 25)
                                        ? jobInfo.job_detail.company.name.slice(0, 25)+'...'
                                        : jobInfo.job_detail.company.name}}
                                    </p>
                                    <a href="#" @click.prevent="showCompany" :title="jobInfo.job_detail.company.name" v-else>
                                        {{(jobInfo.job_detail.company.name.length > 25)
                                        ? jobInfo.job_detail.company.name.slice(0, 25)+'...'
                                        : jobInfo.job_detail.company.name}}
                                    </a>
                                </div>

                            </div>

                            <div class="job-detail-strong" v-else>
                                <div class="top-name">Trades {{$terminologies.company.singularTerm}}</div>
                                <div class="dot-type">:</div>

                                <div class="last-name">
                                   {{jobInfo.job_detail.company.name}}
                                </div>

                            </div>

                            <div class="job-detail-strong" v-else>
                                <div class="top-name">Resources</div>
                                <div class="dot-type">:</div>

                                <div class="last-name" v-for="resources in jobInfo.job_detail.job_company_resource" :title="resources.label">
                                    {{(resources.label && resources.label.length > 25)
                                    ? resources.label.slice(0, 25)+'...'
                                    : resources.label}}

                                </div>

                            </div>

                        </li>

                    </ul>

                </div>

            </div>

            <div class="col-sm-6 right-details">

                <div class="left-job-details">

                    <div class="sub-title-job">{{ jobInfo.job_group_type == 'quotation_request' ? '' : $terminologies.job.singularTerm}} Location</div>

                    <div class="location-name">
                        {{jobInfo.job_detail.job_meta_data.job_location.address}}
                    </div>

                    <div class="job-location-right">

                        <map-container :showInputBar="false" :id="'job_details_map'"
                                       :address="jobInfo.job_detail.job_meta_data.job_location.address"></map-container>

                    </div>


                </div>

            </div>

        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import Create from "../../store/forms/job/create";
    import MapContainer from "./../../components/elements/Map";
    import {createHelpers} from "vuex-map-fields";
    //import ShowCompanyDetails from './ShowCompanyDetails';

    const {mapFields} = createHelpers({
        getterType: "job_create/getField",
        mutationType: "job_create/updateField"
    });
    export default {
        name: "JobDetails",
        computed: {
            ...mapFields(Create)
        },
        mounted() {
            console.log('job info 2', this.jobInfo);
        },

        methods: {
            showCompany() {
                //this.$refs.ShowCompanyDetailsModal.showCompanyDetailModal();
            },
        },
        components: {
            MapContainer,
            //ShowCompanyDetails
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/sass/company/views/job-manage";
</style>
