<template>
    <div>
        <vue-dropzone id="fileUploader1" ref="dropzone" class="file-uploader service-image" :options="getDropOptions()"
                      v-on:vdropzone-file-added="fileAdded" v-on:vdropzone-files-added="dropzoneChanged"
                      v-on:vdropzone-thumbnail="fileReady"
                      v-on:vdropzone-file-added-manually="dropzoneChanged">
            <slot name="label"></slot>
        </vue-dropzone>

        <div class="modal fade" v-bind:class="{show:showCropper}" id="ImageCropperModal" tabindex="-1" role="dialog"
             aria-labelledby="modalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalLabel">Crop the image</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click="showCropper=false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="img-container">
                            <vue-cropper
                                    id="image"
                                    ref='cropper'
                                    :guides="true"
                                    :view-mode="2"
                                    drag-mode="crop"
                                    :auto-crop-area="0.5"
                                    :min-container-width="450"
                                    :min-container-height="450"
                                    :background="true"
                                    :rotatable="true"
                                    :src="imgSrc"
                                    alt="Source Image"
                                    :img-style="{ 'width': '100%', 'height': '100%' }">
                                <slot></slot>
                            </vue-cropper>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row" id="actions">
                            <div class="col-md-12 docs-buttons">
                                <div class="row col-md-12">
                                    <button type="button" class="btn btn-primary" data-method="setDragMode"
                                            @click="cropImage" v-if="imgSrc != ''"
                                            data-option="crop" title="Crop" id="crop">
                                        <span class="docs-tooltip" data-toggle="tooltip"
                                              title="cropper.setDragMode(&quot;crop&quot;)">
                                          <span class="fa fa-crop"></span>
                                        </span>
                                    </button>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vueDropzone from "vue2-dropzone";
    import VueCropper from 'vue-cropperjs';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'dropZoneUploader',
        data: () => ({
            dropOptions: {
                url: "/test",
                maxFilesize: 10, // MB
                maxFiles: 5,
                chunkSize: 500, // Bytes
                thumbnailWidth: 150, // px
                thumbnailHeight: 150,
                addRemoveLinks: true,
                uploadMultiple: true,
                paramName: 'file',
                createImageThumbnails: true,
                dictDefaultMessage: '',
                autoProcessQueue: false,
                thumbnailUseRaw: true,
                file: null,
                autoDiscover: false,
                acceptedFiles: ""
            },
            imgSrc: '',
            cropImg: '',
            showCropper: false,
            fileName: "",
            dropzoneChange: false
        }),
        methods: {
            addImage() {
                return this.dropzoneChange;
            },
            checkFileAdded() {
                if (this.$refs.dropzone.getQueuedFiles().length > 0) {
                    return true;
                }
                return false;

            },
            getDropOptions() {
                return Object.assign(this.dropOptions, this.options);
            },
            removeAllFiles() {
                this.$refs.dropzone.removeAllFiles(true)
            },
            addDefaultImage(dataURI) {
                let file = this.dataURItoBlob(dataURI);
                this.$refs.dropzone.addFile(file);
                this.dropzoneChange = false;
            },
            fileReady(file) {
                let files = this.$refs.dropzone.getAcceptedFiles();

                if (files[0].onlyOnce)
                    return false;


                files.forEach((file, i) => {
                    file.dataColumn = this.dataColumn;
                    file.inBucket = true;
                    files[i] = file;
                });

                this.$emit('file-added', this.id, files, this.parent, this.dataColumn);
            },
            dropzoneChanged() {
                if (this.file.croped || (this.file.type !== 'image/jpeg' && this.file.type !== 'image/png'))
                    return;

                this.showCropper = this.croppable;
                let self = this;
                let dataUrl = this.file.dataURL;
                self.imgSrc = dataUrl;
                self.fileName = this.file.name;
                self.$refs.cropper.replace(dataUrl);
                self.dropzoneChange = true;
            },
            fileAdded(file) {
                this.file = file;
            },
            cropImage() {
                this.$refs.dropzone.removeFile(this.file);
                this.showCropper = false;
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
                let file = this.dataURItoBlob(this.cropImg);
                file.name = this.fileName;
                file.croped = true;
                this.$refs.dropzone.addFile(file);
            },
            rotate(ratio) {
                this.$refs.cropper.rotate(ratio);
            },
            dataURItoBlob(dataURI) {
                let byteString = atob(dataURI.split(',')[1]);
                let ab = new ArrayBuffer(byteString.length);
                let ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ab], {type: 'image/jpeg'});
            }
        },
        props: {
            id: {type: String, required: true},
            croppable: {type: Boolean},
            parent: {type: String},
            dataColumn: {type: Object},
            images: {type: Array},
            columns: {type: Array},
            options: {type: Object},
        },
        mounted() {

        },
        components: {
            vueDropzone,
            VueCropper
        },
        watch: {
            images() {
                let self = this;
                this.images.forEach((file, i) => {
                    if (!file.inBucket && this.columns[i] !== undefined && self.columns[i].type === self.dataColumn.type) {

                        if (self.columns[i].license_id === self.dataColumn.license_id) {
                            self.showCropper = false;
                            file.croped = true;
                            self.$refs.dropzone.addFile(file);

                        }
                    }
                });
            }

        }
    };
</script>

<style scoped>
    .show {
        display: block !important;
    }

</style>
