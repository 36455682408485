<template>
    <div class="misc-job-details">
        <div class="row">

            <div class="col-sm-12 left-details">

                <div class="left-job-details">

                    <div class="sub-title-job">Quotation Details</div>

                    <ul>

                        <li v-if="job_details.misc_meta_data.reference_no">

                            <div class="img-icons">
                                <i class="fas fa-briefcase"></i>
                            </div>

                            <div class="job-detail-strong" >
                                <div class="top-name">Reference No</div>
                                <div class="dot-type">:</div>
                                <div class="last-name" style="text-transform: uppercase;">
                                    {{job_details.misc_meta_data.reference_no}}
                                </div>
                            </div>

                        </li>

                        <li v-if="job_details.service_category && job_details.service_category.name">

                            <div class="img-icons">
                                <i class="fas fa-stream"></i>
                            </div>

                            <div class="job-detail-strong">
                                <div class="top-name">Service Category</div>
                                <div class="dot-type">:</div>
                                <div class="last-name" :title="'Hard Coded'">

                                    {{job_details.service_category.name}}

                                </div>
                            </div>

                        </li>

                        <li v-if="job_details.job_detail.job_meta_data.plan_number">

                            <div class="img-icons">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000" width="15" height="15"
                                     viewBox="0 0 48 48">
                                    <path d="M40 17.37V8h-9.37L24 1.37 17.37 8H8v9.37L1.37 24 8 30.63V40h9.37L24 46.63 30.63 40H40v-9.37L46.63 24 40 17.37zM24 36c-6.63 0-12-5.37-12-12s5.37-12 12-12 12 5.37 12 12-5.37 12-12 12zm0-20c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"/>
                                </svg>
                            </div>

                            <div class="job-detail-strong">
                                <div class="top-name">Plan No</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">{{ job_details.job_detail.job_meta_data.plan_number }}</div>
                            </div>

                        </li>

                        <li v-if="job_details.misc_meta_data !==undefined && job_details.misc_meta_data.notes != null">
                            <div class="img-icons">
                              <i class="far fa-clipboard"></i>
                            </div>

                            <div class="job-detail-strong">
                                <div class="top-name">Note</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">
                                    {{job_details.misc_meta_data.notes}}
                                </div>
                            </div>
                        </li>

                        <li v-if="job_details.misc_meta_data !==undefined && job_details.misc_meta_data.resources.length > 0">
                            <div class="img-icons">
                              <i class="fas fa-paperclip"></i>
                            </div>

                            <div class="job-detail-strong">
                                <div class="top-name">Attachments</div>
                                <div class="dot-type">:</div>
                                <div class="last-name">

                                    <template v-for="imageItem in job_details.misc_meta_data.resources">
                                        <div class="job-detail-img">
                                            <a :href="imageItem.attachment" target="_blank">
                                                <img id="job-log-pdf" src="@/assets/img/pdf-a.svg" v-if="imageItem.ext == 'pdf'">
                                                <img id="job-log-img" :src="imageItem.attachment" v-else>
                                            </a>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </li>

                    </ul>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "JobDetails",
        props: ['job_details'],
        mounted() {
        },
        methods: {
            formattedDate(createdDateTime){
                return moment(createdDateTime).format('YYYY-MM-DD');
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/sass/company/views/job-manage";
    .left-job-details ul li .job-detail-strong .top-name {
        min-width: 142px !important;
    }

    .job-lg-img{
        width: 150px;
        display: inline-block;
        margin-left: 10px;
    }

    .job-lg-img img {
        left: 0px;
        position: absolute;
        right: 0px;
        width: 80%;
        height: auto;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: 0 auto;
    }
</style>
