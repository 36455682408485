<template>
    <div class="filter-bar">
        <form class="form-inline">
            <div class="form-group">
                Search: <input type="text" v-model="filterText" class="form-control form-control-sm" @keyup="doFilter"
                               :placeholder="filterSearch">
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: {
            filterSearch: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                filterText: '',
                sentReqCount: 0
            }
        },
        methods: {
            doFilter() {
                let self = this;
                self.sentReqCount++;
                setTimeout(function () {
                    self.$parent.$options.events['filter-set'](self.filterText, self.sentReqCount);
                }, 500);
            },
            resetFilter() {
                this.filterText = '';
                this.$events.fire('filter-reset')
            }
        }
    }
</script>
<style scoped>

</style>
