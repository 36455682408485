<template>
    <div>
        <bread-crumb :links="[{name:'home', label:'Home'},{name:'job.manage', label:'Job Manage'}]"/>
        <div class="row">

            <div class="col-sm-12">

                <div class="element-wrapper" style="padding-bottom: 0rem;">

                    <div class="element-box-tp datatable-area">


                        <div class="row">

                            <div class="col-12">
                                <h5 class="form-header">Manage Job</h5>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2 text-left left-scale" id="job-manage-section">
                <job-manage :id="jobId"></job-manage>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumb from '../../../components/_global/breadcrumb/BreadCrumb';
    import JobManage from '../../../components/job/manage';

    export default {
        name: 'Manage',
        components: {JobManage, BreadCrumb},
        data() {
            return {jobId:  this.$route.params.id}
        },
        mounted() {

        },
    };
</script>
