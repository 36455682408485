<template>
<div class="main-login-area">

    <div class="disable-device" id="disable-screen">

      <div class="inner-connection">
        <div class="img-dis">
          <img src="../../assets/img/disconnect.png"/>
        </div>

        <div class="dis-title">
          <h2>Your Device is Not Supported</h2>
          <p>Please try again with a Desktop or a Laptop</p>

          <p class="danger-message">If you are already on a Desktop or a Laptop.<br/>
            Then make sure to Maximise your web browser window <br/>and try again.</p>
        </div>
      </div>
    </div>

    <div class=" login-form">
      <div class="container">
          <div class="row">

            <div class="col-md-5 col-centered">

              <div class="main-login">

                <div class="row">

                  <div class="col-sm-12">

                    <div class="logo-header">

                      <img src="../../assets/img/i4tradies.png"/>

                      <h2>Access your Trades & Property<br>Management Services</h2>

                      <p>Please provide your Email Address & Password to Access
                        <br>
                        <span>i4T Dashboard</span> on<img src="../../assets/img/google.png"/>
                      </p>

                    </div>

                  </div>

                </div>

                <div class="row">

                  <div class="col-sm-12">

                    <div class="login-box">


                      <form autocomplete="off" id="login-form" method="POST" @submit="loginSubmit">

                        <fieldset class="styled-input">

                          <div class="form-group">
                            <input oninvalid="setCustomValidity('Please enter a Valid Email Address')"
                                   id="email" name="email" class="input-login" autocomplete="off"
                                   type="email" v-model="username" required autofocus>
                            <label for="email">Email Address </label>
                            <span class="help-block" v-if="usernameError">
                                <strong>{{usernameError}}</strong>
                            </span>

                          </div>

                        </fieldset>

                        <fieldset class="styled-input">
                          <div class="form-group">
                            <input oninvalid="this.setCustomValidity('Please enter Valid Password')"
                                   id="password" class="input-login" required="required"
                                   autocomplete="off" name="password" type="password" v-model="password">
                            <label for="password">Password </label>
                            <span class="help-block" v-if="passwordError">
                                <strong>{{passwordError}}</strong>
                            </span>
                          </div>
                        </fieldset>

                        <div class="row">

                          <div class="col-sm-6">
                            <a class="btn btn-link pull-left" @click="$router.push({ name: 'forgot_password'})">
                              Reset Password?
                            </a>
                          </div>

                        </div>


                        <input type="submit" class="submit-btn" value="Sign In">

                      </form>

                    </div>

                  </div>

                </div>
              </div>

              <div class="terms-text">
                <a href="https://www.i4tradies.com.au/terms" target="_blank">Terms & Conditions</a> | <a
                  href="https://www.i4tradies.com.au/privacy" target="_blank">Privacy Policy</a>
              </div>

            </div>

          </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: "Login",
  data: () => {
    return {
      username: '',
      password: '',
      usernameError: '',
      passwordError:'',
      authError:''
    }
  },
  computed:{
    ...mapGetters({
      user: "userdata/user"
    })
  },
  methods:{
    ...mapActions({
      login: "auth/login"
    }),
    loginSubmit(e){
      e.preventDefault();
      this.$toast.loading(true);
      this.login({
        username: this.username,
        password: this.password,
      }).then(response => {
        if (response.access_token){
          this.$toast.loading(false);
          if(this.user?.two_factor > 0){
            this.$router.push({name: 'checkpoint', params:{ verify_method: this.user?.two_factor}})
          }else{
            this.$router.push('/dashboard')
          }
        }
      }).catch(err => {
        this.passwordError = err.data.message;
        this.$toast.loading(false);
      });
    }
  }
}
</script>

<style scoped>
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css";
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/less/gird.less";
@import "~@/assets/styles/sass/login.scss";
.main-login-area {
  background-image: url('~@/assets/img/bottom.png') !important;
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 47% 16%;
  background-color: rgba(247, 247, 247, 0.4);
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

}
.login-form{
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

#disable-screen {
  display: none;
}



@media (min-width: 768px){
  .container {
    width: 750px;
  }
}
@media (min-width: 992px){
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px){
  .container {
    width: 1170px;
  }
}


</style>
