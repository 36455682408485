import { getField, updateField } from 'vuex-map-fields';
import ProfileService from "../../../services/ProfileService";
import UserService from "../../../services/UserService";

export default {
    namespaced: true,
    state: {
        general: {
            customer_id: null,
            logo: null,
            title: 1,
            enterprise_manager_type_id: 1,
            first_name: '',
            last_name: '',
            location: {},
            address: '',
            status: 1,
            setPassword: 0,
            mobile: {
                code: '',
                number: ''
            },
            phone: {
                code: '',
                number: ''
            },
            admin: {
                id: '',
                email: '',
                current_pw: '',
                new_pw: '',
                confirm_pw: '',
            },
        },
        settings:{
            notificationSettings:{},
            applicationSettings: {}
        },
        security:{},
        formData:{
            titles:[],
            types:[],
            notificationSettings:{},
            smsNotifications:{},
            pushNotifications:{},
            emailNotifications:{},
        },
    },
    getters: {
        getField,
        general: (state) => state.general,
        settings: (state) => state.settings,
        security: state => state.security,
        formData: state => state.formData,
    },
    mutations: {
        updateField,
        setCommitteeMemberData(state,response) {
            console.log("response====>", response)
            let tenantData = response.tenantData.tenant;
            state.general.customer_id = tenantData.id;
            state.general.logo = response.tenantData.tenant_profile_image;
            state.general.title = tenantData.title_id == 'null' ? '' : tenantData.title_id;
            state.general.first_name = tenantData.first_name;
            state.general.last_name = tenantData.last_name;
            state.general.location = {
                address: tenantData.address,
                coordinates: {
                    lat: tenantData.lat,
                    lng: tenantData.lng
                }
            };
            state.general.address = tenantData.address;
            state.general.status = 1;
            state.general.setPassword = 0;
            state.general.mobile = {
                code : tenantData.mobile_area_code,
                number: tenantData.mobile
            };
            state.general.phone = {
                code: tenantData.phone_area_code,
                number: tenantData.phone
            };
            state.general.admin = {
                id: tenantData.user_id,
                email: tenantData.email,
                current_pw: '',
                new_pw: '',
                confirm_pw: '',
            };
            state.formData.types = response.formData.types;
            state.formData.titles = response.formData.titles;
        },
        setSettings: (state, response) => {
            state.settings.notificationSettings = response.tenantData.tenant_settings.notification_permissions;
            state.settings.applicationSettings = response.tenantData.tenant_settings.tenant_settings;
        },
        setFormData: (state, response) => {
            state.formData.titles = response.formData.titles;
            state.formData.types = response.formData.types;
            state.formData.notificationSettings = response.formData.tenant_notification_settings;
            state.formData.sms_notifications = response.formData.sms_notifications;
            state.formData.email_notifications = response.formData.email_notifications;
            state.formData.push_notifications = response.formData.push_notifications;
        },
        clearProfileData(state) {
            state = {}
        }
    },
    actions: {
        fetch({commit, dispatch}, tenantId) {
            return new Promise((resolve, reject) => {
                ProfileService.fetch(tenantId).then(async response => {

                    await commit('setCommitteeMemberData', response);
                    await commit('setSettings', response);
                    await commit('setFormData', response);

                    resolve(response);
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        updateSettings({commit, dispatch}, settings){
            return new Promise((resolve, reject) => {
                ProfileService.updateSettings(settings).then(response => {
                    resolve(response)
                }).catch(function (error) {
                    reject(error);
                });
            });
        },
        clearProfileData({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearProfileData');
                resolve(true);
            });
        }
    },

};