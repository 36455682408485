import Vue from 'vue';

export default {

    _setTerminologies(terminologies) {

        let _settings = {
            PRIMARY_PROPERTY: 'system_primary_property',
            SECONDARY_PROPERTY: 'system_secondary_property',
            MANAGER: 'system_manager',
            OCCUPANT: 'system_occupant',
            OWNER: 'system_owner',
            LANDLORD: 'system_land_lord',
            JOB: 'system_job',
            QUOTE_REQUEST: 'system_quote_request',
            TENANT: 'system_tenant',
            COMPANY: 'system_company'
        };

        let _termType = {
            PLURAL_TERM: 'plural_term',
            SINGULAR_TERM: 'singular_term'
        };

        let _default = {
            property: {
                singularTerm: 'Property',
                pluralTerm: 'Properties'
            },
            secondary_property: {
                singularTerm: 'Secondary Property',
                pluralTerm: 'Secondary Properties'
            },
            manager: {
                singularTerm: 'Property Manager',
                pluralTerm: 'Property Managers'
            },
            occupant: {
                singularTerm: 'Occupant',
                pluralTerm: 'Occupants'
            },
            owner: {
                singularTerm: 'Owner',
                pluralTerm: 'Owners'
            },
            landlord: {
                singularTerm: 'Land Lord',
                pluralTerm: 'Land Lords'
            },
            job: {
                singularTerm: 'Job',
                pluralTerm: 'Jobs'
            },
            quote_request: {
                singularTerm: 'Quote Request',
                pluralTerm: 'Quote Requests'
            },
            tenant: {
                singularTerm: 'Tenant',
                pluralTerm: 'Tenants'
            },
            company: {
                singularTerm: 'Company',
                pluralTerm: 'Companies'
            }
        };

        Vue.prototype.$terminologies = {
            property: {
                singularTerm: (terminologies[_settings.PRIMARY_PROPERTY])? terminologies[_settings.PRIMARY_PROPERTY][_termType.SINGULAR_TERM] : _default.property.singularTerm,
                pluralTerm: (terminologies[_settings.PRIMARY_PROPERTY])? terminologies[_settings.PRIMARY_PROPERTY][_termType.PLURAL_TERM] : _default.property.pluralTerm,
            },
            secondary_property: {
                singularTerm: (terminologies[_settings.SECONDARY_PROPERTY])? terminologies[_settings.SECONDARY_PROPERTY][_termType.SINGULAR_TERM] : _default.secondary_property.singularTerm,
                pluralTerm: (terminologies[_settings.SECONDARY_PROPERTY])? terminologies[_settings.SECONDARY_PROPERTY][_termType.PLURAL_TERM] : _default.secondary_property.pluralTerm,
            },
            manager: {
                singularTerm: (terminologies[_settings.MANAGER])? terminologies[_settings.MANAGER][_termType.SINGULAR_TERM] : _default.manager.singularTerm,
                pluralTerm: (terminologies[_settings.MANAGER])? terminologies[_settings.MANAGER][_termType.PLURAL_TERM] : _default.manager.pluralTerm,
            },
            occupant: {
                singularTerm: (terminologies[_settings.OCCUPANT])? terminologies[_settings.OCCUPANT][_termType.SINGULAR_TERM] : _default.occupant.singularTerm,
                pluralTerm: (terminologies[_settings.OCCUPANT])? terminologies[_settings.OCCUPANT][_termType.PLURAL_TERM] : _default.occupant.pluralTerm,
            },
            owner: {
                singularTerm: (terminologies[_settings.OWNER])? terminologies[_settings.OWNER][_termType.SINGULAR_TERM] : _default.owner.singularTerm,
                pluralTerm: (terminologies[_settings.OWNER])? terminologies[_settings.OWNER][_termType.PLURAL_TERM] : _default.owner.pluralTerm,
            },
            landlord: {
                singularTerm: (terminologies[_settings.LANDLORD])? terminologies[_settings.LANDLORD][_termType.SINGULAR_TERM] : _default.landlord.singularTerm,
                pluralTerm: (terminologies[_settings.LANDLORD])? terminologies[_settings.LANDLORD][_termType.PLURAL_TERM] : _default.landlord.pluralTerm,
            },
            job: {
                singularTerm: (terminologies[_settings.JOB])? terminologies[_settings.JOB][_termType.SINGULAR_TERM] : _default.job.singularTerm,
                pluralTerm: (terminologies[_settings.JOB])? terminologies[_settings.JOB][_termType.PLURAL_TERM] : _default.job.pluralTerm,
            },
            quote_request: {
                singularTerm: (terminologies[_settings.QUOTE_REQUEST])? terminologies[_settings.QUOTE_REQUEST][_termType.SINGULAR_TERM] : _default.quote_request.singularTerm,
                pluralTerm: (terminologies[_settings.QUOTE_REQUEST])? terminologies[_settings.QUOTE_REQUEST][_termType.PLURAL_TERM] : _default.quote_request.pluralTerm,
            },
            tenant: {
                singularTerm: (terminologies[_settings.TENANT])? terminologies[_settings.TENANT][_termType.SINGULAR_TERM] : _default.tenant.singularTerm,
                pluralTerm: (terminologies[_settings.TENANT])? terminologies[_settings.TENANT][_termType.PLURAL_TERM] : _default.tenant.pluralTerm,
            },
            company: {
                singularTerm: (terminologies[_settings.COMPANY])? terminologies[_settings.COMPANY][_termType.SINGULAR_TERM] : _default.company.singularTerm,
                pluralTerm: (terminologies[_settings.COMPANY])? terminologies[_settings.COMPANY][_termType.PLURAL_TERM] : _default.company.pluralTerm,
            }

        }

    }
}
