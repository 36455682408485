<template>
    <div class="container">
        <h3>Popover Example</h3>
        <a href="#" data-toggle="popover" title="Popover Header" data-content="Some content inside the popover">Toggle
            popover</a>
    </div>
</template>


<script>
    export default {}
</script>
