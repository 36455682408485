<template>
    <div v-if="loading">
        <div class="element-box job-request-form new-form-theme mqr-view job-view-section1">

            <div class="row">

                <div class="col-sm-12">
                    <div class="ph-item">

                        <div class="ph-col-1">
                            <div class="ph-avatar"></div>
                         </div>

                        <div class="ph-col-6">
                            <div class="ph-row">
                                <div class="ph-col-4 big"></div>
                                <div class="ph-col-8 empty"></div>
                                <div class="ph-col-6"></div>
                                <div class="ph-col-6 empty"></div>
                                <div class="ph-col-4"></div>
                                <div class="ph-col-8 empty"></div>
                                <div class="ph-col-2"></div>
                                <div class="ph-col-10 empty"></div>
                            </div>
                        </div>

                        <div class="ph-col-12">
                            <div class="ph-row">
                                <div class="ph-col-12 empty"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 empty"></div>
                            </div>
                        </div>

                        <div class="ph-col-6">
                            <div class="ph-row">
                                <div class="ph-col-10 big"></div>
                                <div class="ph-col-2 empty big"></div>
                                <div class="ph-col-4"></div>
                                <div class="ph-col-8 empty"></div>
                                <div class="ph-col-6"></div>
                                <div class="ph-col-6 empty"></div>
                                <div class="ph-col-10"></div>
                                <div class="ph-col-4"></div>
                                <div class="ph-col-8 empty"></div>
                                <div class="ph-col-6"></div>
                                <div class="ph-col-6 empty"></div>
                                <div class="ph-col-10"></div>
                            </div>
                        </div>
                        <div class="ph-col-6">
                            <div class="ph-map"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="element-box job-request-form new-form-theme mqr-view job-view-section1" id="jobManageParent">

            <div class="row">

                <div class="col-sm-12">
                    <job-profile-header :job_details="data"></job-profile-header>
                    <job-details :job_details="data"></job-details>
                </div>

            </div>

        </div>

        <div class="requested-suppliers-table">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import JobProfileHeader from "./JobProfileHeader";
    import JobDetails from "./JobDetails";
    import QuotationService from "../../services/QuotationService";

    export default {
        name: "JobView",
        components: {
            JobProfileHeader,
            JobDetails
        },
        props: ['id'],
        data(){
            return {
                loading: true,
                data: []
            }
        },
        methods:{
            get(){
                let self = this;
                this.$parent.getDetail().then((response) => {
                    self.data = response.data.result;
                    self.loading = false;
                });
            },
            requestList(){
                return this.$parent.requestList();
            }
        },
        mounted() {
            this.get();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/sass/_components/placeholder/placeholder.scss";

</style>