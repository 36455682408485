<template>
  <div id="app-layout">

    <!-- <n-progress></n-progress> -->

    <search-suggestion></search-suggestion>

    <div class="layout-w">

      <main-navigation v-bind:data="session"></main-navigation>

      <mobile-navigation v-bind:data="session"></mobile-navigation>

      <div class="content-w">

        <top-header v-bind:data="session" v-if="isAuthenticated"></top-header>

        <div class="content-i">

          <div id="scroll-container" class="content-box" :style="scrollContainerStyle"
               v-on:scroll="handleScroll">

            <router-view :key="$route.fullPath"></router-view>

          </div>

        </div>
      </div>
    </div>
    <basic-footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchSuggestion from '@/components/search/Suggestions.vue';
import MobileNavigation from '@/components/navigation/MobileNavigation.vue';
import MainNavigation from '@/components/navigation/Navigation.vue';
import TopHeader from '@/components/navigation/TopHeader.vue';
import PopOut from '@/components/_global/popups/PopOut.vue';
import SuccessNotification from '@/components/_global/notifications/Success.vue';
import ErrorNotification from '@/components/_global/notifications/Error.vue';
import WarningNotification from '@/components/_global/notifications/Warning.vue';
import InfoNotification from '@/components/_global/notifications/Info.vue';
import BasicFooter from '@/components/_global/footer/BasicFooter';
// import NProgress from '@/components/_global/loader/NProgress.vue';
import BreadCrumb from '@/components/_global/breadcrumb/BreadCrumb';
import {mapGetters} from "vuex";
import AuthService from "../services/AuthService";

export default {
  name: 'Home',
  components: {
    BasicFooter,
    MainNavigation,
    SearchSuggestion,
    MobileNavigation,
    TopHeader,
    PopOut,
    SuccessNotification,
    ErrorNotification,
    WarningNotification,
    InfoNotification,
    BreadCrumb,
    // NProgress,
  },
  data() {
    return {
      session: {
        logo: {
          name: this.user?.app_name,
          show: true,
          path: this.user?.app_logo,
          beta: this.user?.beta,
          brand: this.user?.brand_logo,
          deviceCheckView: false
        },
        plus_menu: {
          show: false,
        },
        admin: this.$user?.admin
      },
      window: {
        width: 0
      },
      scrollContainerStyle: '',
      enterpriseData: {},
      showInvitationApprovedModal: false,
      showServiceModal: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'userdata/user'
    }),
    isAuthenticated(){
      return AuthService.isAuthenticated;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {},
  methods:{
    handleScroll($event) {


      let elmnt = document.getElementById("job-manage-section");

      if (typeof elmnt != 'undefined') {

        let el = document.getElementById("scroll-container");
        let jobmanagecontentwidth = 0;
        if (document.getElementById('job-manage-section'))
          jobmanagecontentwidth = document.getElementById('job-manage-section').clientWidth;

        // if (el.scrollTop > 285) {

        //   $('#job-manage-section .middle-box').css({
        //     'max-width': jobmanagecontentwidth - 10,
        //   });

        //   $("#job-manage-section").addClass('fixed-job-manage-action');
        // } else {
        //   $("#job-manage-section").removeClass('fixed-job-manage-action');
        // }
      }

    },
  },
  created() {
    console.log(window.Echo);
    let self = this;
    window.Echo.listen('private-new_activity_' + this.user.id, '.recent_activity', (process) => {
      console.log(process);
      process.time = moment();
      if (this.$user.id != process.user_id && process.message.length) {
        try {
          self.$events.emit('newActivity', process);
        } catch (e) { }
        new Noty({
          text: process,
          dismissQueue: true,
          timeout: 8000,
          type: 'i4t-info',
          force: true,
          layout: "bottomRight",
          theme: 'metroui',
          maxVisible: 3,
          callbacks: {
            onClick: function () {
              if(process.job_group_type && process.job_group_id && process.job_group_type == 'quotation_request') {
                self.$router.push({name: 'quote_request_view', params: {id: process.job_group_id}})
              } else {
                self.$router.push({name: 'work_order_dashboard'})
              }
            }

          }
        }).show();
      }

    });
  }
}
</script>
