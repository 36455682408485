<template>
    <modal :name="name"
           :id="name"
           :delay="delay"
           :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable"
           :scrollable="scrollable"
           :reset="reset"
           :clickToClose="clickToClose"
           :transition="transition"
           :overlayTransition="overlayTransition"
           :classes="classes"
           :width="width"
           :height="height"
           :minWidth="minWidth"
           :minHeight="minHeight"
           :maxWidth="maxWidth"
           :maxHeight="maxHeight"
           :pivotX="pivotX"
           :pivotY="pivotY"
           :root="root"
           @before-open="beforeOpen()"
           @opened="opened()"
           @before-close="beforeClose()"
           @closed="closed()"
           :headerTitle="headerTitle"
           :showFooter="showFooter"
           :showHeader="showHeader"
           :hasRoute="hasRoute"
           v-on:scroll.native="handleScroll"
            :hideIconEvent="hideIconEvent">
        <div class="vue-modal-dialog">
            <div class="modal-content">
                <div class="modal-header" v-if="showHeader">
                    <h5 class="modal-title" v-if="headerTitle.length" v-text="headerTitle"></h5>
                    <slot name="header"></slot>
                    <button aria-label="Close" class="close" type="button" @click="hide()"
                            v-if="!hideClose">
                        <span aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
     <path data-v-456c6d1f=""
           d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z"></path>
 </svg>
                    </span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer" v-if="showFooter">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
        <div @click="doScroll" class="popuparrow" v-if="scrollToTop" id="scrolltop">

            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="26px" height="26px" viewBox="0 0 306 306" style="enable-background:new 0 0 306 306;"
                 xml:space="preserve">
            <g>
                <g id="expand-less">
                    <polygon points="153,58.65 0,211.65 35.7,247.35 153,130.05 270.3,247.35 306,211.65 		"/>
                </g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
                <g>
            </g>
            </svg>


        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import VueJsModal from 'vue-js-modal';

    Vue.use(VueJsModal);

    export default {
        name: 'IForVueJsModal',
        components: {VueJsModal},
        props: {
            jobDetailView: {
                type: Boolean,
                default: false
            },
            scrollToTop: {
                type: Boolean,
                default: false
            },
            showFooter: {

                type: Boolean,
                default: true
            },
            showHeader: {
                type: Boolean,
                default: true
            },
            hasRoute: {
                type: Boolean,
                default: true
            },
            name: {
                type: String,
                default: 'modal'
            },
            headerTitle: {
                type: String,
                default: ''
            },
            delay: {
                default: 0
            },
            resizable: {
                default: false
            },
            adaptive: {
                default: false
            },
            draggable: {
                default: false
            },
            scrollable: {
                default: true
            },
            reset: {
                default: false
            },
            clickToClose: {
                default: true
            },
            transition: {
                default: 'pop-out'
            },
            overlayTransition: {
                type: String,
                default: 'overlay-fade'
            },
            classes: {
                default: function () {
                    return [];
                }
            },
            width: {
                default: 'auto'
            },
            height: {
                default: 'auto'
            },
            minWidth: {
                default: 500
            },
            minHeight: {
                default: 0
            },
            maxWidth: {},
            maxHeight: {},
            pivotX: {
                default: 0.5
            },
            pivotY: {
                default: 0.5
            },
            root: {
                default: null
            },
            hideClose: {
                default: 0
            },
            hideIconEvent: {
                default : false
            }
        },
        data() {
            return {
                showScrollTotButton: false
            };
        },
        methods: {
            doScroll() {
                let elmnt = document.getElementById(this.name);
                elmnt.scrollTop = 0;
            },
            handleScroll(event) {
                if (this.jobDetailView) {
                    let elmnt = document.getElementById(this.name);


                    if (elmnt.scrollTop > 100) {
                        this.showScrollTotButton = true;
                        $('.job-summary-details').hide();
                        $("#scrolltop").fadeIn();
                        $(".action-btn-details").addClass('fixed-job-action');

                    } else {
                        this.showScrollTotButton = false;
                        $('.job-summary-details').show();
                        $("#scrolltop").fadeOut();
                        $(".action-btn-details").removeClass('fixed-job-action');
                    }

                }

            },

            beforeOpen(event) {
                this.$emit('beforeOpen', event);
            },
            opened(event) {
                if (this.jobDetailView)
                    window.addEventListener('scroll', this.handleScroll);
                this.$emit('opened', event);
            },
            beforeClose(event) {
                this.$emit('beforeClose', event);
            },
            closed(event) {
                if (this.jobDetailView)
                    window.removeEventListener('scroll', this.handleScroll);
                if (this.hasRoute) {
                    let lastIndex = (this.$route.path.lastIndexOf('/')) ? this.$route.path.lastIndexOf('/') : 0;
                    (lastIndex) ? this.$router.push(this.$route.path.slice(0, lastIndex)) : this.$router.push('/');
                }
                this.$emit('closed', event);
            },
            show() {
                this.$modal.show(this.name);
            },
            hide() {
                if (this.hideIconEvent == true){
                    this.$emit('hideClick');
                }else{
                    this.$modal.hide(this.name);
                }
            },
            hideModal() {
                this.$modal.hide(this.name);
            }
        }
    };
</script>
