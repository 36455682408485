<template>
  <div class="wo-dashboard">
    <bread-crumb :links="[{name:'home', label:'Home'},{name:'customer_list', label: 'Work Orders'}]"/>
    <work-orders-list ref="woList"></work-orders-list>

    <view-modal v-on:closed="manageOpen=false" class="full-modal job-view-modal" ref="view" transition="pop-out"
                :width="'1252'" name="viewJob" :hasRoute="false" :showFooter="false" :jobDetailView="true"
                :scrollToTop="true"
                :headerTitle="'Job Detail View'" :pivotY="0.2">

      <template slot="body">
        <job-manage :id="jobId"></job-manage>
      </template>
    </view-modal>
    <view-modal v-on:closed="rateClosed()" name="committeeMemberRateModal" class="full-modal job-view-modal workoder-mdal" :width="500" ref="committeeMemberRateModal"
               transition="pop-out" :hasRoute="false"
               :scrollToTop="true"
               :headerTitle="'Rate Supplier'" :pivotY="0.2">

      <template slot="body">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <h4>How would you rate your experience with</h4>
              <h3>{{rateModalData.supplier_details}}</h3>
              <div class="form-group">
                <star-rating v-model="rate.star_rating" :star-size="30" :show-rating="false"></star-rating>
                <span class="error">{{rate.error}}</span>
              </div>
              <div class="form-group">
                <textarea v-model="rate.comment" class="form-control" rows="5" placeholder="What was your experience like?"></textarea>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button class="btn btn-primary" @click="rateSupplier">Submit</button>
      </template>

    </view-modal>
  </div>

</template>

<script>
import WorkOrdersList from "./WorkOrdersList";
import BreadCrumb from "../../components/_global/breadcrumb/BreadCrumb";
import ViewModal from "../../components/modal/VueJsModal";
import JobManage from "../../components/job/manage";
import swal from "sweetalert2";
import AppConstants from "../../const/AppConstants";
import StarRating from 'vue-star-rating';
//import ErrorMessage from "../../components/validation/ErrorMessage";

export default {
  name: "WODashboard",
  data() {
    return {
      jobId: null,
      manageOpen: false,
      rate: {
        error: '',
        star_rating: null,
        comment:'',
        error_message: 'Please rate before submit'
      },
      rateModalData: {
        supplier_details: ''
      }
    }
  },
  components: {
    WorkOrdersList,
    BreadCrumb,
    ViewModal,
    JobManage,
    StarRating
  },
  methods: {
    openJob(id) {
      this.jobId = id;
      console.log(this.$refs.view);
      this.$refs.view.show();
    },
    openRateModal(id, data) {
      this.rate.error = '';
      this.jobId = id;
      this.rateModalData = data;
      this.$refs.committeeMemberRateModal.show();
      this.rate.star_rating = null;
      this.rate.comment = '';
    },
    rateSupplier(id) {
      let self = this;
      if(self.rate.star_rating == null) {
        self.rate.error = self.rate.error_message;
        return;
      }
      new swal({
        title: 'Are you sure you want to <br />Rate this Supplier ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        reverseButtons: true,
        customClass: 'archive-modal',
      }).then((result) => {
        if (result.value) {
          self.$toast.loading(true);
          self.$root.workflow({
            'job_id': self.jobId,
            'process': AppConstants.WORKFLOW.JOB_CUSTOMER_RATED,
            'dics_rating': '',
            'star_rating': self.rate.star_rating,
            'comments': self.rate.comment
          }, function(response) {
            self.$toast.loading(false);
            self.$refs.woList.$refs.woList.hardReload();
            self.$refs.committeeMemberRateModal.hide();
          });
        }
      });
    },
    rateClosed() {
      this.rate.star_rating = null;
      this.rate.comment = '';
    }
  },
  watch: {
    'rate.star_rating': {
      handler: function(newValue) {
        if(newValue == null) {
          this.rate.error = this.rate.error_message;
        }
        if(newValue != null) {
          this.rate.error = '';
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
  .wo-dashboard span.error {
    color: red;
  }
</style>
