<template>
    <div>
        <div class="row">

            <div class="col-sm-12">

                <div class="element-wrapper el-full-height" style="padding-bottom: 0rem;">

                    <div class="element-box-tp datatable-area">

                        <div class="row">

                            <div class="col-12">
                                <h5 class="form-header">
                                    Profile & Account Settings
                                </h5>
                            </div>


                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="row">

            <div class="col-sm-12">

                <div class="row main-compo">

                    <div class="col-md-12 text-left left-scale">

                        <div class="element-wrapper el-full-height">

                            <div class="element-box new-form-theme company-profile-section el-full-height enterrprise-area">

                                <ul class="nav nav-tabs" role="tablist">


                                    <li class="nav-item">
                                        <a class="nav-link"
                                           v-bind:class="{ 'active': $route.name == 'i4tradies_profile_general' }"
                                           data-toggle="tab" @click.prevent="changeTab(1)"
                                           role="tab" v-can="{name: 'i4tradies_profile_general', perm: 'view'}"><i class="far fa-user"></i> Profile Information</a>
                                    </li>


                                    <li class="nav-item">
                                        <a class="nav-link"
                                           v-bind:class="{ 'active': $route.name == 'i4tradies_profile_settings' }"
                                           v-can="{name: 'i4tradies_profile_settings', perm: 'view'}"
                                           data-toggle="tab" @click.prevent="changeTab(2)" role="tab">
                                            <svg data-v-1866018d="" class="svg-inline--fa fa-cog fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="cog" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M444.788 291.1l42.616 24.599c4.867 2.809 7.126 8.618 5.459 13.985-11.07 35.642-29.97 67.842-54.689 94.586a12.016 12.016 0 0 1-14.832 2.254l-42.584-24.595a191.577 191.577 0 0 1-60.759 35.13v49.182a12.01 12.01 0 0 1-9.377 11.718c-34.956 7.85-72.499 8.256-109.219.007-5.49-1.233-9.403-6.096-9.403-11.723v-49.184a191.555 191.555 0 0 1-60.759-35.13l-42.584 24.595a12.016 12.016 0 0 1-14.832-2.254c-24.718-26.744-43.619-58.944-54.689-94.586-1.667-5.366.592-11.175 5.459-13.985L67.212 291.1a193.48 193.48 0 0 1 0-70.199l-42.616-24.599c-4.867-2.809-7.126-8.618-5.459-13.985 11.07-35.642 29.97-67.842 54.689-94.586a12.016 12.016 0 0 1 14.832-2.254l42.584 24.595a191.577 191.577 0 0 1 60.759-35.13V25.759a12.01 12.01 0 0 1 9.377-11.718c34.956-7.85 72.499-8.256 109.219-.007 5.49 1.233 9.403 6.096 9.403 11.723v49.184a191.555 191.555 0 0 1 60.759 35.13l42.584-24.595a12.016 12.016 0 0 1 14.832 2.254c24.718 26.744 43.619 58.944 54.689 94.586 1.667 5.366-.592 11.175-5.459 13.985L444.788 220.9a193.485 193.485 0 0 1 0 70.2zM336 256c0-44.112-35.888-80-80-80s-80 35.888-80 80 35.888 80 80 80 80-35.888 80-80z"></path></svg>
                                            Settings</a>
                                    </li>


                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="{ 'active': $route.name == 'i4tradies_profile_security' }" v-can="{name: 'i4tradies_profile_security', perm: 'view'}"
                                           data-toggle="tab" @click.prevent="changeTab(3)"
                                           role="tab"><i class="fa fa-lock" aria-hidden="true"></i>
                                            Security</a>
                                    </li>

<!--                                  <li class="nav-item">
                                    <a class="nav-link" v-bind:class="{ 'active': $route.name == 'i4tradies_profile_properties' }" v-can="{name: 'i4tradies_profile_properties', perm: 'view'}"
                                       data-toggle="tab" @click.prevent="changeTab(4)"
                                       role="tab"><i class="fa fa-home" aria-hidden="true"></i>
                                      Properties</a>
                                  </li>-->

                                </ul>

                                <div class="tab-content" role="tablist">
                                    <router-view ref="enterprise_settings"></router-view>
                                </div>

                            </div>

                        </div>

                    </div>

<!--                    <right-side></right-side>-->

                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "ProfileSetting",
        components: {},
        computed:{
            ...mapGetters({
                user: "userdata/user"
            }),
        },
        created() {
            this.fetchCommitteeMember(this.user?.property_tenant?.id);
            this.fetchProperties();
            this.fetchRemoteAvailableProperties();
        },
        methods: {
            ...mapActions({
              fetchCommitteeMember: "profile/fetch",
              fetchProperties: "profile/fetchProperties",
              fetchRemoteAvailableProperties: "profile/fetchRemoteAvailableProperties",
            }),
            changeTab: function (id) {
                switch (id) {
                    case 1:
                        this.$router.push({name: 'i4tradies_profile_general'});
                        break;

                    case 2:
                        this.$router.push({name: 'i4tradies_profile_settings'});
                        break;

                    case 3:
                        this.$router.push({name: 'i4tradies_profile_security'});
                        break;

                    case 4:
                      this.$router.push({name: 'i4tradies_profile_properties'});
                      break;
                }
            }

        }
    }


</script>

<style scoped>
    .no-right-padding {
        padding-right: 0;
    }

    .nav-tabs .nav-link, .nav-tabs .nav-item .nav-link {
        display: block !important;
    }

</style>
