<template>
    <div class="table-records-pages">
        <label>
            Show
            <select name="records_per_page" class="form-control form-control-sm" v-model="$parent.itemsPerPage">
                <option :value="10">10</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
            </select>
            entries
        </label>
    </div>
</template>

<script>
    export default {
        name: "PerPageRecords"
    }
</script>

<style scoped>
</style>