import {Navigation} from '@/helpers/Navigation';

export default {
    namespaced: true,

    state: {
        main: [],
        top: [],
        plus: [],
        types: ['Main', 'Top', 'Plus'],
        formData: {
            id:'',
            label:'',
            type:'',
            parent_id:'',
            resource_id: '',
            icon:'',
            svg: '',
            description:'',
        },
    },
    getters: {
        main: state => state.main,
        top: state => state.top,
        plus: state => state.plus,
        getNavigationTypes : state => state.types,
        setFormData(state) {
            return state.formData;
        },
        getFormData : state => state.formData,
    },
    mutations: {
        SET_NAVIGATION (state, navigation) {
            state.main = navigation.main;
            state.top = navigation.top;
            // state.plus = navigation.plus;
        },
        setFormData(state, formData) {
            state.formData = Object.assign(state.formData, formData);
        }
    },
    actions: {
        fetchMenuEntries({commit}) {
            new Navigation().load((response) => {
                commit('SET_NAVIGATION', response);
            });
        },
    }
};