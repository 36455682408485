<template>

    <div class="news-section" @click="hideNotification()" id="news-popup-left" v-if="systemAlert.visibility">

        <div class="news-mark"></div>

        <router-link :to="{ name: 'news_list', params: { id: systemAlert.id }}">
            <div class="news-circle">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
            <g>
            <path d="M437.333,64c-14.165,0-26.368,8.619-36.629,22.891L61.035,206.229C54.528,196.992,46.315,192,37.333,192
            C16.043,192,0,219.52,0,256s16.043,64,37.333,64c8.981,0,17.195-4.992,23.701-14.229l64.405,22.635l-12.48,20.821
            c-4.117,6.869-6.293,14.741-6.293,22.741c0,18.197,10.901,34.304,27.776,41.045l78.528,31.403
            c5.995,2.389,12.245,3.541,18.411,3.541c18.539,0,36.416-10.304,45.077-27.669l16.512-33.045l107.712,37.845
            C410.944,439.381,423.168,448,437.333,448C485.824,448,512,349.077,512,256S485.824,64,437.333,64z M238.272,401.216
            c-1.6,3.2-6.101,4.971-9.451,3.563l-78.528-31.403l-0.747-2.219l17.003-28.331l85.845,30.165L238.272,401.216z M437.333,405.333
            c-9.579,0-32-50.795-32-149.333s22.421-149.333,32-149.333c9.579,0,32,50.795,32,149.333S446.912,405.333,437.333,405.333z"/>
            </g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
                    <g>
            </g>
            </svg>

            </div>

            <div class="news-event-ttle">i4T News</div>

            <span class="date-published">Published: {{ moment(systemAlert.start_at).format('ll')}}</span>

            <div class="news-desc">
                <h4>{{ (systemAlert.title.length > 37) ? systemAlert.title.substr(0,37)+'...' : systemAlert.title
                    }}</h4>
                <p>{{ formatMessage() }}Read More</p>
            </div>

            <div class="close-event" @click.prevent="hideNotification">
                <svg width="24" version="1.1" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 64 64"
                     xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 64 64">
                    <g>
                        <path fill="#1D1D1B"
                              d="M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59   c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59   c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0   L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"/>
                    </g>
                </svg>
            </div>
        </router-link>

    </div>

</template>

<script>
    export default {
        name: "SystemNewsAlert",
        data() {
            return {
                moment: moment,
                readmore: false,
                text: 'Read More'
            }
        },
        computed: {
            systemAlert() {
                return this.$store.state.NewsAlert.systemAlert;
            }
        },
        methods: {
            hideNotification() {
                this.$store.commit('NewsAlert/hideNotification');
                this.$store.dispatch('NewsAlert/markRead', { 'id' : this.systemAlert.id });
            },
            formatMessage() {
                if (this.readmore) {
                    return this.systemAlert.message.replace(/<[^>]*>?/gm, '');
                } else {
                    return this.systemAlert.message.replace(/<[^>]*>?/gm, '').substr(0, 150) + '... ';
                }
            },
            toggleRead() {
                this.readmore = !this.readmore;
                this.text = this.readmore ? 'Read Less' : 'Read More';
            }
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/sass/_components/news/news";
</style>