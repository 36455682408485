<template>
    <div class="main-login-area">

        <div class="disable-device" id="disable-screen">

            <div class="inner-connection">
                <div class="img-dis">
                    <img src="../../assets/img/disconnect.png"/>
                </div>

                <div class="dis-title">
                    <h2>Your Device is Not Supported</h2>
                    <p>Please try again with a Desktop or a Laptop</p>

                    <p class="danger-message">If you are already on a Desktop or a Laptop.<br/>
                        Then make sure to Maximise your web browser window <br/>and try again.</p>
                </div>
            </div>
        </div>

        <div class=" login-form">
            <div class="container">
                <div class="row">

                    <div class="col-md-5 col-centered">

                        <div class="main-login">

                            <div class="row">

                                <div class="col-sm-12">

                                    <div class="logo-header">

                                        <img src="../../assets/img/i4tradies.png"/>

                                        <h2>  Let's Reset your Password</h2>

                                        <p>Please enter your Email Address and we will send you a Password Reset Link</p>

                                    </div>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-12">

                                    <div class="login-box">
                                        <div class="alert alert-success" v-if="success">
                                            {{ successMessage }}
                                        </div>

                                        <form autocomplete="off" id="login-form" method="POST" @submit="requestPasswordResetLink">

                                            <fieldset class="styled-input">

                                                <div class="form-group">
                                                    <input oninvalid="setCustomValidity('Please enter a Valid Email Address')"
                                                           id="email" name="email" class="input-login" autocomplete="off"
                                                           type="email" v-model="username" required autofocus>
                                                    <label for="email">Email Address </label>
                                                    <span class="help-block" v-if="usernameError">
                                                        <strong>{{usernameError}}</strong>
                                                    </span>

                                                </div>

                                            </fieldset>

                                            <div class="row">

                                                <div class="col-sm-6">
                                                    <a class="btn btn-link" @click="$router.push({name:'Login'})">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="#000" viewBox="0 0 48 48">
                                                            <path d="M30.83 14.83L28 12 16 24l12 12 2.83-2.83L21.66 24z"></path>
                                                        </svg> Back to Login
                                                    </a>
                                                </div>

                                            </div>


                                            <input type="submit" class="submit-btn" value="Send Password Reset Link">

                                        </form>

                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="terms-text">
                            <a href="https://www.i4tradies.com.au/terms" target="_blank">Terms & Conditions</a> | <a
                                href="https://www.i4tradies.com.au/privacy" target="_blank">Privacy Policy</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    export default {
        name: "ForgotPassword",
        data: () => {
            return {
                username: '',
                usernameError: '',
                success: false,
                successMessage:''
            }
        },
        methods:{
            ...mapActions({
                sendPasswordResetLink: "auth/sendPasswordResetLink"
            }),
            requestPasswordResetLink(e){
                e.preventDefault();
                this.$toast.loading(true);
                this.sendPasswordResetLink(this.username).then(response => {
                    this.$toast.loading(false);
                    if (response){
                        this.success = true;
                        this.successMessage = response;
                    }
                }).catch(err => {
                    this.$toast.loading(false);
                    this.usernameError = 'The email address not found. Please try again'
                });
            }
        }
    }
</script>

<style scoped>
    @import "../../assets/styles/sass/login.scss";
    .main-login-area {
        background-image: url('../../assets/img/bottom.png') !important;
        background-repeat: repeat-x;
        background-position: bottom;
        background-size: 47% 16%;
        background-color: rgba(247, 247, 247, 0.4);
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

    }
    .login-form{
        display: table-cell;
        vertical-align: middle;
        width: 100%;
    }

    #disable-screen {
        display: none;
    }



    @media (min-width: 768px){
        .container {
            width: 750px;
        }
    }
    @media (min-width: 992px){
        .container {
            width: 970px;
        }
    }
    @media (min-width: 1200px){
        .container {
            width: 1170px;
        }
    }


</style>
