<template>
    <div class="element-box new-form-theme timeline-sction vcontainer"
         style="background-color: #ffffff !important;">
        <div class="row">
            <div class="col-sm-12">
                <div class="inner-title-section">
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="form-header" style="padding-bottom: 0px; border-bottom: none;">
                                Quotations
                            </h5>

                        </div>

                        <div class="col-md-6 text-right">
                            <button type="button" class="btn btn-primary btn-sm bulk-rejection-btn" v-if="selectAllBtnEnable" @click="allToggledCheckbox(selectAllBtnStatus)">{{selectAllBtnName}}</button> &nbsp;
                            <button type="button" class="btn btn-primary btn-sm bulk-rejection-btn" v-if="enableBulkReject" @click="openBulkRejection">Bulk Rejection</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="element-wrapper">

                    <div class="element-box new-form-theme">

                        <div class="tab-content">
                            <div class="row job-dashboard-section">
                                <div class="col-sm-12">
                                    <div class="table-responsive add-stus-table quotations-preview-table">
                                        <vue-table2
                                                ref="contentQuotation"
                                                :fields="columns"
                                                :api-url="apiUrl"
                                                :sort-order="initSort"
                                                :show-filter="false"
                                                :show-pagination="true"
                                                :show-pagination-info="true"
                                                :emptyMessage="'Processing...'"
                                                v-on:onRowClicked="requestRowClick"
                                                v-on:toggledCheckbox="toggledCheckbox"
                                                v-on:allToggledCheckbox="allToggledCheckbox"
                                                @loadSuccess="loadSuccess"
                                                :on-row-class="onRowClass">

                                            <template slot="company" slot-scope="props">
                                                <div class="quotations-user-row">
                                                    <div class="user-with-avatar">
                                                        <img alt=""
                                                             :src="(!props.rowData.company.logo) ? '/img/user.png' : props.rowData.company.logo"/>
                                                        <span>
                                                        {{(props.rowData.company.name.length > 50)
                                                        ? props.rowData.company.name.slice(0, 50)+'...'
                                                        : props.rowData.company.name}}

                                                        <div class="lighter">
                                                        {{(props.rowData.company.address.length > 60)
                                                            ? props.rowData.company.address.slice(0, 60)+'...'
                                                            : props.rowData.company.address}}
                                                        </div>
                                                        <div class="star-rating" v-html="starRatings(props.rowData.company.star_rating)"></div>
                                                    </span>
                                                    </div>
                                                    <div class="quotations-user-label">
                                                      <div v-if="preferred_label == props.rowData.company.label" class="label label-success">{{preferred_label}}</div>
                                                      <div v-if="blacklist_label == props.rowData.company.label" class="label label-danger">{{blacklist_label}}</div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template slot="status" slot-scope="props">
                                                <div class="stat-labl">
                                                    <span :class="getStatusClass(props.rowData.job.job_company_quotation_status)"></span>
                                                    {{ props.rowData.job.job_company_quotation_status }}
                                                </div>
                                            </template>
                                            <template slot="checkbox" slot-scope="props">
                                                <input type="checkbox" name="checkbox"  :id="props.rowData.job.id+'checkbox'" @change="toggledCheckbox($event, props.rowData)" :disabled="checkRejectAction(props.rowData)">
                                            </template>
                                            <template slot="quote_value" slot-scope="props" >
                                                <div v-if="((props.rowData.job.selected_quotation && props.rowData.job.selected_quotation.type != 'external') || (props.rowData.job.selected_quotation &&  props.rowData.job.selected_quotation.type == 'external' && parseFloat(props.rowData.job.selected_quotation.external_amount) > 0))">
                                                    <template v-if="props.rowData.job.selected_quotation.type == 'external'">
                                                        <span></span> {{ separateThousandsValues(roundPrices(props.rowData.job.selected_quotation.external_amount))
                                                        }}
                                                    </template>
                                                    <template v-else>
                                                        <span></span> {{ separateThousandsValues(roundPrices(props.rowData.job.quotation_grand_total))
                                                        }}
                                                    </template>
                                                </div>
                                                <div v-else>
                                                    <span>--</span>
                                                </div>
                                            </template>

                                        </vue-table2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <reject ref="reject_modal"></reject>
        <approve ref="approve_modal"></approve>

    </div>

</template>

<script>
    import Vue from "vue";
    import Swal from "sweetalert2";
    import VueTable2 from "../../components/datatable/VueTable2";
    import MultipleQuotationJobListAction from "./MultipleQuotationJobListAction";
    import Reject from "./Reject";
    import Approve from "./Approve";
    import AppConstants from "../../const/AppConstants";
    import Utils from "../../helpers/util";

    Vue.component('multiple-quotation-list-actions', MultipleQuotationJobListAction);

    export default {
        components:{
            Approve,
            VueTable2,
            Reject
        },
        data() {
            return {
                listData: null,
                apiUrl: '',
                columns:[
                    {
                        /*name: '__checkbox',
                        title: '',
                        width: '100px',*/
                        name: '__slot:checkbox',
                        title: '',
                        width: '40px',
                    },
                    {
                        name: '__slot:company',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        title: 'Supplier',
                        width: '450px',
                        callback: (val) => {
                            let count = val.length;
                            if (count > 5) {
                                return val.slice(0, 35) + ' ...';
                            } else {
                                return val;
                            }
                        }
                    },
                    {
                        name: 'job.quotation_request_code',
                        title: 'QR Number',
                        width: '120px',
                    },
                    {
                        name: '__slot:quote_value',
                        title: 'Quotation Amount',
                        width: '120px',
                    },
                    {
                        name: '__slot:status',
                        title: 'Status',
                        width: '200px',
                        sortField: 'status'

                    },
                    {
                        name: '__component:multiple-quotation-list-actions',
                        title: 'Action',
                        width: '100px',
                    }
                ],
                initSort: [],
                enableBulkReject:false,
                checkedRejectedList : [],
                selectAllBtnName : 'Select All',
                selectAllBtnStatus : true,
                selectAllBtnEnable : false,
                preferred_label :AppConstants.SUPPLIER_STATUS.EXT_STATUS_PREFERRED_DISPLAY,
                blacklist_label :AppConstants.SUPPLIER_STATUS.EXT_STATUS_BLACKLIST_DISPLAY,
            }
        },
        name: "MultiRequestItemsList",
        props: ['multi_request_id'],
        methods: {
            formattedDate(quotation_due_date){
                if(quotation_due_date)
                    return moment(quotation_due_date).format('DD-MM-YYYY');
                else
                    return '--';
            },
            showPdf(quotation_id, job_id, type){
                this.$refs.contentQuotation.selectedRowIndex = job_id;
                this.$parent.$parent.showPdf(quotation_id, job_id, type);
            },
            showQuotationRequest(job_id){
                this.$parent.$parent.showQuotationRequest(job_id);
            },
            openRejectModal(job_id, quotation_id, multiple = false) {
                let self = this;

                setTimeout(function () {
                    self.$refs.reject_modal.openPopup(job_id, quotation_id, multiple);
                }, 500)
            },
            tableAPIUrl() {
                this.apiUrl = 'committee/job/multiple-quotation/requests/'+this.multi_request_id;
            },
            requestRowClick(rowData, index, event){
                this.$refs.contentQuotation.selectedRowIndex = rowData.job.id;
                if (rowData.job.quotation_id != undefined){
                    this.showPdf(rowData.job.quotation_id, rowData.job.id, rowData.job.selected_quotation.type);
                }
            },
            openApproveModal(job_id, quotation_id) {
                let self = this;

                setTimeout(function () {
                    self.$refs.approve_modal.openPopup(job_id, quotation_id);
                }, 500)
            },
            toggledCheckbox(event, dataItem){

                let self = this;

                if (event.target.checked) {
                    self.checkedRejectedList.push(
                        {
                            'job_id' : dataItem.job.id,
                            'quotation_id' : dataItem.job.quotation_id
                        }
                        );
                }else{

                    let getIndex = self.checkedRejectedList.findIndex((element, index) => {
                        if (element.job_id == dataItem.job.id) {
                            if (index == 0){
                                return "0";
                            }else{
                                return index;
                            }
                        }
                    });

                    self.checkedRejectedList.splice(getIndex, 1);
                }

                if (self.checkedRejectedList.length > 0){
                    self.enableBulkReject = true;
                }else{
                    self.enableBulkReject = false;
                }
            },
            allToggledCheckbox(type = true){

                let self = this;
                let data = this.$refs.contentQuotation.getAllData();
                let eventCheckbox = {
                    target : {
                        checked : type
                    }
                };

                self.checkedRejectedList = [];

                if (data.length > 0){
                    if (type == true){

                        self.selectAllBtnName = 'Deselect All';
                        self.selectAllBtnStatus = false;

                        $.each(data, function(key, value) {
                            if (value.job.quotation_id != undefined &&  value.job.workflow.key == AppConstants.WORKFLOW.EXTERNAL_CM_QUOTE_APPROVAL_PENDING){
                                $('#'+value.job.id+'checkbox').prop('checked', true);
                                self.toggledCheckbox(eventCheckbox, value);
                            }
                        });
                    }else{
                        $.each(data, function(key, value) {
                            if (value.job.quotation_id != undefined &&  value.job.workflow.key == AppConstants.WORKFLOW.EXTERNAL_CM_QUOTE_APPROVAL_PENDING){
                                $('#'+value.job.id+'checkbox').prop('checked', false);
                            }
                        });

                        self.enableBulkReject = false;
                        self.selectAllBtnName = 'Select All';
                        self.selectAllBtnStatus = true;
                    }
                }
            },
            openBulkRejection()
            {
                this.openRejectModal(this.checkedRejectedList, '',true);
            },
            checkRejectAction(rowData) {
                let result = true;
                if (rowData.job.quotation_id != undefined &&  rowData.job.workflow.key == AppConstants.WORKFLOW.EXTERNAL_CM_QUOTE_APPROVAL_PENDING){
                    result = false;
                }
                return result;
            },
            getStatusClass(status)
            {
                return status.replace(/\s+/g, '_').toLowerCase();
            },
            loadSuccess(response){
                let self = this;
                let checkDisabledArray = [];
                let data = response.data.result.data;
                let status = '';

                if (data.length > 0) {
                    $.each(data, function (key, value) {
                        status = self.checkRejectAction(value);
                        if (status == true) {
                            checkDisabledArray.push(value);
                        }
                    });

                    if (data.length == checkDisabledArray.length){
                        this.selectAllBtnEnable = false;
                    }else{
                        this.selectAllBtnEnable = true;
                    }
                }
            },
            onRowClass(dataItem, index) {
                if (this.$refs.contentQuotation.selectedRowIndex === dataItem.job.id){
                    return 'active';
                }
            },
            starRatings(rating_count){
                return Utils.methods.starRatings(rating_count)
            },
            separateThousandsValues(val){
                return Number(val).toLocaleString('au', { minimumFractionDigits: 2, maximumFractionDigits:2 });
            },
        },
        mounted() {
            this.tableAPIUrl();
        },
    }

</script>

