import Vue from 'vue';
import axios from 'axios';
import {apiBaseUrl} from "../config/apiConfig";
import appConstants from '../const/AppConstants';
import swal from 'sweetalert2/dist/sweetalert2.js'
import store from '../store';

let oAxios = {

        //defined http methods
        XHR_GET_METHOD: 'get',
        XHR_POST_METHOD: 'post',
        XHR_PUT_METHOD: 'put',
        XHR_DELETE_METHOD: 'delete',
        XHR_HEADER_METHOD: 'header',
        XHR_PATCH_METHOD: 'patch',
        XHR_OPTIONS_METHOD: 'options',

        //defined response types
        RESPONSE_TYPE_JSON: 'application/json',

        //local variables
        timeout: 550000,
        api_version: 'v2',

        // Default headers
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-I4T-Version': 'v2.0',
            'Content-Encoding': 'gzip',
            'app-id': appConstants.COMMUNITY_SELECT_APP_ID,
        },

        loader: false,
        failed:false,

        STATUS_SUCCESS: 200,
        STATUS_ERROR_UNAUTHORIZED: 401,
        STATUS_ERROR_BAD_REQUEST: 400,
        STATUS_ERROR_UNPROCESSABLE_ENTRY: 422,

        //initialize variables and response type
        _init: function (method, url, data, progress, responseType, options) {

            this.url = apiBaseUrl + url;
            this.method = method;
            this.data = data;
            this.responseType = responseType;
            this.displayError = true;

            //axios options can be set here.
            this.timeout = (options.hasOwnProperty('timeout')) ? options.timeout : this.timeout;
            this.headers = (options.hasOwnProperty('headers')) ? options.headers : this.headers;
            this.displayError = (options.hasOwnProperty('displayError')) ? options.displayError : this.displayError;

            //set the loader - Keep this commented until find a solution for loader issue
            // if (typeof progress !== 'undefined' && progress == true) {
            //      this.loader = true;
            //      Vue.prototype.$toast.loading(true);
            // }

        },

        //create the axios instance with settings
        _createInstance: function (config) {
            const httpClient = axios.create();
            httpClient.defaults.timeout = this.timeout;
            httpClient.defaults.headers = this.headers;

            const accessCredentials = JSON.parse(localStorage.getItem('access_credentials'));
            if (accessCredentials?.access_token && Object.entries(accessCredentials)?.length > 0){
                httpClient.defaults.headers = Object.assign(
                    {},
                    httpClient.defaults.headers,
                    {
                        common: {
                            Authorization : 'Bearer ' + accessCredentials.access_token
                        }
                    }
                );
            }

            let self = this;
            //setting interceptors
            httpClient.interceptors.response.use(function (response) {
                self.loader = false;
                Vue.prototype.$toast.loading(false);
                return response;

            }, function (error) {
                if (error?.response?.status === 401 && config?.url !== apiBaseUrl+'auth'){

                    swal({
                        title: "Session Expired!",
                        text: "Your session has expired. Please login to continue",
                        type: "warning",
                        confirmButtonText: "OK",
                        reverseButtons: true,
                        imageWidth: 55,
                        imageHeight: 60,
                        imageAlt: "Logout",
                    }).then((result) => {
                        localStorage.removeItem('access_credentials');
                        window.location.href = '/login'
                    });

                }
                self.loader = false;
                Vue.prototype.$toast.loading(false);
                //return promise reject object to component
                return Promise.reject(error.response);
            });

            return httpClient(config);
        },

        /**
         *
         * @param method
         * @param url
         * @param data
         * @param loading
         * @param responseType
         * @param options
         */

        api: function (method, url, data, loading, responseType, options = {}) {

            url = '/' + this.api_version + '/' + url;
            this._init(method, url, data, loading, responseType, options);

            return this._createInstance({
                method: this.method,
                url: this.url,
                data: this.data
            });
        },

        /**
         *
         * @param method
         * @param url
         * @param data
         * @param loading
         * @param responseType
         * @param options
         */
        send: function (method, url, data, loading, responseType, options = {}) {

            let self = this;

            this._init(method, url, data, loading, responseType, options);

            return this._createInstance({
                method: this.method,
                url: this.url,
                data: this.data
            });
        },

        /**
         *
         * @param url
         * @param data
         * @param responseType
         * @param loading
         * @param options
         */
        get: function (url, data, responseType, loading, options = {}) {

            this._init(this.XHR_GET_METHOD, url, data, loading, responseType, options);

            return this._createInstance({
                method: this.method,
                url: this.url,
                params: this.data
            });
        }

    }
;

export default oAxios;
