<template>
    <div>
        <div class="service-action" v-if="rowData.workflow_tree!==undefined">
            <a href="#" @click.prevent="view($event)" title="View">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="17" height="17" viewBox="0 0 55.312 55.312" style="enable-background:new 0 0 55.312 55.312;"
                     xml:space="preserve">
                    <g>
                        <g>
                            <g>
                                <path d="M28.042,45.438c-9.428,0-18.675-5.251-27.485-15.606c-0.684-0.804-0.744-1.965-0.148-2.835
                                    c0.479-0.7,11.914-17.124,27.963-17.124c9.511,0,18.429,5.75,26.506,17.089c0.564,0.793,0.579,1.852,0.037,2.659
                                    C54.482,30.266,44.105,45.438,28.042,45.438z M5.348,28.211c7.487,8.332,15.115,12.553,22.694,12.553
                                    c11.041,0,19.297-9.045,22.025-12.465c-6.859-9.126-14.151-13.752-21.694-13.752C17.329,14.546,8.36,24.447,5.348,28.211z"/>
                            </g>
                            <path d="M27.656,16.407c-6.212,0-11.247,5.035-11.247,11.248c0,6.211,5.035,11.247,11.247,11.247
                                c6.211,0,11.247-5.036,11.247-11.247C38.903,21.443,33.868,16.407,27.656,16.407z M31.685,27.822
                                c-2.503,0-4.532-2.029-4.532-4.531c0-2.504,2.029-4.533,4.532-4.533c2.504,0,4.533,2.029,4.533,4.533
                                C36.218,25.792,34.188,27.822,31.685,27.822z"/>
                        </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            </a>
            <a href="#" v-if="!rowData.rated && rowData.workflow_tree!==undefined && rowData.workflow_tree.includes('customer_rated')" @click.prevent="rateOpen($event)" title="Rate Supplier">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="22px" viewBox="0 0 24 24" width="22px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none"/><path d="M0,0h24v24H0V0z" fill="none"/></g><g><path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"/></g></svg>
            </a>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';

    export default {
        name: "ListAction",
        components: {},
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        computed: {
            jobId() {
                return this.rowData.id;
            }
        },
        data() {
            return {
            }
        },
        methods: {
            view(e) {
                this.$parent.$parent.$parent.$parent.openJob(this.rowData.id);
            },
            rateOpen(e) {

                e.stopPropagation();
                console.log(e);
                this.$parent.$parent.$parent.$parent.openRateModal(this.rowData.id, {supplier_details: this.rowData.supplier_details});
            },
            archive(e) {
                e.stopPropagation();
                let self = this;
            }
        },
    }
</script>
<style lang="scss">
    @import "../../assets/styles/sass/_components/radio/popup-radio";
</style>

