import Axios from "./AxiosService";
import store from '../store';
import {BaseService} from "./BaseService";
import acl from "../plugins/acl";

class AuthService extends BaseService{

    login(user) {
        return Axios
            .send(Axios.XHR_POST_METHOD,'auth', {
                username: user.username,
                password: user.password,
                client_id: process.env.VUE_APP_I4T_CLIENT_ID,
                client_secret: process.env.VUE_APP_I4T_CLIENT_SECRET,
                grant_type: 'password',
                scope: '',
            })
            .then(response => {
                if (response.data.result.access_token) {
                    localStorage.setItem('access_credentials', JSON.stringify(response.data.result));
                }
                return response.data;
            });
    }

    logout() {
        return new Promise((resolve, reject) => {
            Axios
                .send(Axios.XHR_POST_METHOD,'logout',{}, true)
                .then(async response => {
                    if (response) {
                        await localStorage.removeItem('access_credentials');
                    }
                    resolve(response);
                });
        })

    }

    isAuthenticated() {
        const isAuthenticated = JSON.parse(localStorage.getItem('access_credentials')) ? Object.entries(JSON.parse(localStorage.getItem('access_credentials')))?.length > 0 : false;
        if (isAuthenticated){
            store.commit('auth/setIsAuthenticated', true);
        }
        return isAuthenticated;
    }

    isAuthorized(to, from) {
        return this.$app.config.globalProperties.$acl.hasRoutePermission(to, from)
        // return new Promise((resolve, reject) => {
        //     resolve(this.isAuthenticated());
        // });
    }

    verifyTwoFactorCode(code){
        return new Promise((resolve, reject) => {
            Axios.send(Axios.XHR_POST_METHOD,'2fa/verify',{key: code}, true)
                .then(async response => {
                    resolve(response);
                }).catch(err => {
                reject(err);
            });
        })
    }

    sendPasswordResetLink(email){
        return new Promise((resolve, reject) => {
            Axios.send(Axios.XHR_POST_METHOD,'password/email',{email: email}, true)
                .then(async response => {
                    resolve(response);
                }).catch(err => {
                reject(err);
            });
        })
    }

    resetPassword(postData){
        return new Promise((resolve, reject) => {
            Axios.send(Axios.XHR_POST_METHOD,'password/reset',postData, true)
                .then(async response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
            });
        })
    }
}

export default new AuthService();