<template>
    <canvas id="line-chart"></canvas>
</template>
<script>
    import { Line } from 'vue-chartjs'

    export default {
        extends: Line,
        name: "line-chart",
        props: ['data', 'options'],
        mounted () {
            //this.renderChart(this.data, this.options)
            this.createChart(this.data, this.options);
        },
        methods: {
            createChart(data, options) {
                const ctx = document.getElementById('line-chart');
                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: data,
                    options: options
                });
            },

            reload() {
                this.chart.resize();
            }
        }
    }
</script>