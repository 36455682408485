export class Navigation {

    constructor() {
        this.init();
    }

    init() {
        // INIT MENU TO ACTIVATE ON HOVER
        let menu_timer;
        $('.menu-activated-on-hover').on('mouseenter', 'ul.main-menu > li.has-sub-menu', function () {
            let $elem = $(this);
            clearTimeout(menu_timer);
            $elem.closest('ul').addClass('has-current').find('> li').removeClass('current');
            $elem.addClass('current');
        });

        $('.menu-activated-on-hover').on('mouseleave', 'ul.main-menu > li.has-sub-menu', function () {
            var $elem = $(this);
            menu_timer = setTimeout(function () {
                $elem.removeClass('current').closest('ul').removeClass('has-current');
            }, 30);
        });

        // INIT MENU TO ACTIVATE ON CLICK
        $('.menu-activated-on-click').on('click', 'li.has-sub-menu > a', function (event) {

            let $elem = $(this).closest('li');
            if ($elem.hasClass('current')) {
                $elem.removeClass('current');
            } else {
                $elem.closest('ul').find('li.current').removeClass('current');
                $elem.addClass('current');
            }
            return false;
        });

        $('.menu-activated-on-click').on('click', 'li.has-sub-menu li', function (event) {
            $('.menu-activated-on-click li.has-sub-menu li').removeClass('current');
        });

        $('.menu-activated-on-click').on('click', 'li', function (event) {

            /*
             * commented following lines due to console error
             */
            // each(function() {
            $('.menu-activated-on-click li.selected, .menu-activated-on-click li.has-sub-menu').removeClass('current');
            // });
            $(this).addClass('current');
        });


    }

    //Loading menu from server
    load(callback) {
        let oThis = this;
        XhrRequest.send(XhrRequest.XHR_GET_METHOD, 'system/navigation', {})
            .then(function (response) {
                callback(response.data.result);
                oThis.init();
            }).catch(function (error) {

        });
    }
}