import Axios from "./AxiosService";
import {BaseService} from "./BaseService";

class QuotationService extends BaseService{
    searchManager(){
        return Axios
            .send(Axios.XHR_GET_METHOD,'all/strata-managers', {})
            .then(response => {
                return response;
            });
    };

    status(){
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/status-groups?type=regular', {})
            .then(response => {
                return response;
            });
    };
}

export default new QuotationService();