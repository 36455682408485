<template>

    <div>
        <div v-if="loading">
            <div class="element-box job-request-form new-form-theme job-view-section1">

                <div class="row">

                    <div class="col-sm-12">
                        <div class="ph-item">

                            <div class="ph-col-1">
                                <div class="ph-avatar"></div>
                            </div>

                            <div class="ph-col-6">
                                <div class="ph-row">
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 empty"></div>
                                    <div class="ph-col-6"></div>
                                    <div class="ph-col-6 empty"></div>
                                    <div class="ph-col-4"></div>
                                    <div class="ph-col-8 empty"></div>
                                    <div class="ph-col-2"></div>
                                    <div class="ph-col-10 empty"></div>
                                </div>
                            </div>

                            <div class="ph-col-12">
                                <div class="ph-row">
                                    <div class="ph-col-12 empty"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 empty"></div>
                                </div>
                            </div>

                            <div class="ph-col-6">
                                <div class="ph-row">
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 empty big"></div>
                                    <div class="ph-col-4"></div>
                                    <div class="ph-col-8 empty"></div>
                                    <div class="ph-col-6"></div>
                                    <div class="ph-col-6 empty"></div>
                                    <div class="ph-col-10"></div>
                                    <div class="ph-col-4"></div>
                                    <div class="ph-col-8 empty"></div>
                                    <div class="ph-col-6"></div>
                                    <div class="ph-col-6 empty"></div>
                                    <div class="ph-col-10"></div>
                                </div>
                            </div>
                            <div class="ph-col-6">
                                <div class="ph-map"></div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="element-box job-request-form new-form-theme job-view-section1" id="jobManageParent">
                <div class="row">
                    <div class="col-sm-12">
                        <job-profile-header :jobId="id" :est_btn="false" :asg_btn="true" :params="params"
                                            :viewOnly="mode"
                                            :hideExtra="hideExtra"
                                            @refresh="get"
                                            @edit-loaded="loadFinished">
                            <job-details></job-details>
                        </job-profile-header>
                    </div>
                </div>
            </div>

            <div class="element-box new-form-theme job-view-section1"
                 v-show="jobInfo.job_detail.has_quote_requests && user && user.role == 'company'">

                <quotation-list ref="quotation_req_list" v-if="user.role == 'company'" :job-id="id"></quotation-list>

            </div>

            <div class="element-box new-form-theme vcontainer special-instructions-div"
                 style="border: solid 1px #ececec;margin: 0.5rem 0;border-radius: 6px;"
                 v-if="jobInfo.job_detail.job_meta_data.special_instructions">

                <div class="row">
                    <div class="col-sm-12">
                        <div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h5 class="form-header">Special Instructions</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="txt-nl">
                    {{jobInfo.job_detail.job_meta_data.special_instructions}}
                </div>
            </div>

            <div class="element-box new-form-theme job-images-section">
                <div class="row">
                    <div class="col-sm-12">
                        <div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h5 class="form-header">
                                        {{jobInfo.job_group_type == 'quotation_request' ? 'Quotation Request' :
                                        $terminologies.job.singularTerm }} Images
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <carousel :scrollPerPage="true" :perPageCustom="[[200, 2], [768,8]]"
                          v-if="checkAttachmentCount(jobInfo.job_detail.job_meta_data.started_images,'images') > 0">
                    <slide v-for="image in jobInfo.job_detail.job_meta_data.started_images" :key="image.id"
                           v-if="checkValidityImages(image) == 'image'">
                        <div class="image-slider-img">
                            <div class="zoom-img"></div>
                            <a v-if="image.ext=='pdf'" :href="image.image" target="_blank">
                                <img id="pdf-job-placeholder" :src="image.min" :key="image.image">
                            </a>

                            <img v-else v-viewer="{url: 'data-source',inline:false,container:'.job-view-section1'}"
                                 :src="image.min"
                                 :alt="image.original_name ? image.original_name : ''"
                                 :data-source="image.image"
                                 :key="image.image">
                        </div>
                    </slide>
                </carousel>
                <div v-else><img src="@/assets/images/no_image.png"></div>

            </div>

            <div class="element-box new-form-theme job-images-section"
                 v-if="checkAttachmentCount(jobInfo.job_detail.job_meta_data.started_images, 'plan_attachments') > 0">

                <div class="row">

                    <div class="col-sm-12">

                        <div>

                            <div class="row">


                                <div class="col-md-12">
                                    <h5 class="form-header">
                                        Plan Specific Attachments
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <carousel :scrollPerPage="true" :perPageCustom="[[200, 2], [768,8]]"
                          v-if="jobInfo.job_detail.job_meta_data.started_images.filter(function(s) { return !s.deleted; }).length">
                    <slide v-for="image in jobInfo.job_detail.job_meta_data.started_images" :key="image.id"
                           v-if="checkValidityImages(image) == 'plan_specific_attachment'">
                        <div class="image-slider-img">
                            <div class="zoom-img"></div>
                            <img v-if="image.ext=='png' || image.ext=='jpg' || image.ext=='jpeg' || image.ext=='gif'"
                                 v-viewer="{url: 'data-source',inline:false,container:'.job-view-section1'}"
                                 :src="image.min"
                                 :data-source="image.image"
                                 :key="image.image">

                            <a v-else :href="image.image" target="_blank">
                                <img id="pdf-job-placeholder2" :src="image.min" :key="image.image">
                            </a>


                        </div>
                    </slide>
                </carousel>
                <div v-else><img src="@/assets/images/no_image.png"></div>


            </div>

            <job-log @loadMore="loadMore" :id="id" :loadingJob="loadingJob" :logCount="logCount"
                     :logLoad="logLoad"></job-log>

        </div>
        <!-- Open attachment acknowledgement modal -->
        <view-modal class="full-modal job-view-modal psaa-moadl" ref="attachmentsAcknowledgementTest"
                    transition="pop-out"
                    :width="'600'" :hasRoute="false"
                    :headerTitle="acknowledgementModalTitle" :clickToClose="false" :hideClose="1">

            <template slot="body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-info marg-top-0" role="alert">
                            <b>Hint | </b> {{acknowledgementHintText}}
                        </div>
                    </div>
                </div>
                <div class="row" v-if="hasAcknowledgementAttachments">
                    <div class="col-md-3"
                         v-for="image in acknowledgementAttachments"
                         :key="'akn-'+image.id">
                        <div class="psaa-block">
                            <a :href="image.image" target="_blank">
                                <img :src="image.min" :key="'img-'+image.image">
                            </a>
                            <a :href="image.image" target="_blank" @click="image.linkClicked = true">
                                <p>{{getFilename(image.image)}}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="checkbox-main-div">
                    <div class="row width100" v-if="hasAcknowledgementAttachments">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label>
                                <div class="custm-checkbox">
                                    <input type="checkbox" value="1" v-model="acknowledge_check">
                                    <span>
                                    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 459 459"
                                         style="enable-background:new 0 0 459 459"><g><g id="check-box-outline"><path
                                            d="M124.95 181.05l-35.7 35.7L204 331.5l255-255-35.7-35.7L204 260.1 124.95 181.05zM408 408H51V51h255V0H51C22.95.0.0 22.95.0 51v357c0 28.05 22.95 51 51 51h357c28.05.0 51-22.95 51-51V204h-51V408z"/></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                                    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 459 459"
                                         style="enable-background:new 0 0 459 459"><g><g id="check-box-outline-blank"><path
                                            d="M408 51v357H51V51H408m0-51H51C22.95.0.0 22.95.0 51v357c0 28.05 22.95 51 51 51h357c28.05.0 51-22.95 51-51V51C459 22.95 436.05.0 408 0V0z"/></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                                </span>
                                </div>
                                I here by acknowledge that I have read above document(s).</label>
                        </div>
                    </div>
                </div>
                    <div class="row width100" v-if="isQuoteRequest">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label>
                                <div class="custm-checkbox">
                                    <input type="checkbox" value="1" v-model="terms_check">
                                    <span>
                                    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 459 459"
                                         style="enable-background:new 0 0 459 459"><g><g id="check-box-outline"><path
                                            d="M124.95 181.05l-35.7 35.7L204 331.5l255-255-35.7-35.7L204 260.1 124.95 181.05zM408 408H51V51h255V0H51C22.95.0.0 22.95.0 51v357c0 28.05 22.95 51 51 51h357c28.05.0 51-22.95 51-51V204h-51V408z"/></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                                    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 459 459"
                                         style="enable-background:new 0 0 459 459"><g><g id="check-box-outline-blank"><path
                                            d="M408 51v357H51V51H408m0-51H51C22.95.0.0 22.95.0 51v357c0 28.05 22.95 51 51 51h357c28.05.0 51-22.95 51-51V51C459 22.95 436.05.0 408 0V0z"/></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                                </span>
                                </div>
                                I have read and accepted <a :href="termsAndConditionUrl" target="_blank">Terms &
                                Conditions.</a></label>
                        </div>
                    </div>
                </div>
                </div>
            </template>
            <template slot="footer">
                <!--                acknowlegements-->
                <div class="row width100 btn-center" v-if="hasAcknowledgementAttachments">
                    <div class="col-md-12">
                        <button class="btn gray-btn btn-sm" @click="cancel()">Cancel</button>
                        <button @click.prevent="acknowledge()" class="btn btn-primary btn-sm" type="button"
                                :disabled="(!acknowledge_check || !terms_check)" v-if="isQuoteRequest">Acknowledge
                        </button>
                        <button @click.prevent="acknowledge()" class="btn btn-primary btn-sm" type="button" v-else
                                :disabled="!acknowledge_check ">Acknowledge
                        </button>
                    </div>
                </div>
                <!--                T&C-->
                <div class="row width100 btn-center" v-else>
                    <div class="col-md-12">
                        <button class="btn gray-btn btn-sm" @click="cancel()">Cancel</button>
                        <button @click.prevent="acceptTerms()" class="btn btn-primary btn-sm" type="button"
                                :disabled="!terms_check">Confirm
                        </button>
                    </div>
                </div>
            </template>
        </view-modal>
        <!-- Open attachment acknowledgement modal -->
    </div>

</template>

<script>
    import JobLog from './JobLog';
    import JobProfileHeader from './JobProfileHeader';
    import Create from '../../store/forms/job/create';
    import JobDetails from './JobDetails';
    import {createHelpers} from 'vuex-map-fields';
    import {Carousel, Slide} from 'vue-carousel';
    import ViewModal from "../modal/VueJsModal";
    import swal from "sweetalert2";
    import {mapGetters} from "vuex";
    import AuthService from "../../services/AuthService";

    const {mapFields} = createHelpers({
        getterType: 'job_create/getField',
        mutationType: 'job_create/updateField',
    });

    export default {
        name: 'JobManage',
        props: ['id', 'params', 'viewOnly', 'hideExtra'],
        components: {
            JobDetails,
            JobProfileHeader,
            JobLog,
            Carousel,
            Slide,
            ViewModal
        },
        data() {
            return {
                slickOptions: {
                    slidesToShow: 3,
                    // Any other options that can be got from plugin documentation
                },
                estStartDate: (this.params) ? this.params.est_start_date : false,
                mode: this.viewOnly,
                loadingJob: false,
                loading: true,
                jobLogFrom: 1,
                logCount: 1,
                logLoad: false,
                acknowledgementAttachments: [],
                acknowledge_check: false,
                terms_check: false,
                acknowledgementModalTitle: 'Acknowledge',
                acknowledgementHintText: 'There are Plan Specific Documents attached to this job. It is Mandatory to download and read below document(s) before continuing with the Job',
                termsAndConditionUrl: '',
                hasAcknowledgementAttachments: true,
                isQuoteRequest:false
            };
        },
        methods: {
            loadFinished() {
                this.loading = false;
            },
            create() {
                let self = this;
                XhrRequest.send(XhrRequest.XHR_GET_METHOD, '/job/create', {}, false)
                    .then((response) => {
                        self.createData = response.data.result;
                        self.edit(self.job_id);
                    })
                    .catch((error) => {
                    });
            },
            get(id = false, limit = 0, from = 1, append = false) {
                let self = this;
                id = id ? id : this.id;
                let prefix = '';
                if (this.loadingJob)
                    return false;

                this.loadingJob = this.loading = true;
                XhrRequest.send(XhrRequest.XHR_GET_METHOD, prefix + 'job/' + id + '?limit=' + limit + '&from=' + from + '&initial_load=0&web=true', {}, false, XhrRequest.responseType, 60000).then((response) => {

                    self.loaded = true;
                    self.loadingJob = false;
                    self.logCount = response.data.result.job_detail.log.job_process && response.data.result.job_detail.log.job_process.length;

                    if (append)
                        self.$store.commit('job_create/appendLog', response.data.result);
                    else
                        self.$store.commit('job_create/setJobInfo', response.data.result);

                    console.log('job info 1', this.jobInfo);

                    self.loading = false;
                    self.logLoad = response.data.result.job_detail.log_load;
                    console.log(response);

                    if(self.jobInfo.job_group_type == 'quotation_request'){
                        self.isQuoteRequest = true;
                        self.acknowledgementModalTitle='Acknowledge';

                    }else{
                        self.acknowledgementModalTitle='Plan Specific Documents';
                    }
                    /*!* Open attachment acknowledgement modal *!*/
                    let data = {id: self.id, company_id: self.user.company_id, created_enterprise_id: self.jobInfo.created_enterprise_id};
                    let openModal = false;
                    if (self.user.role === "company" && self.jobInfo.created_enterprise_id == PICA_CREATED_COMPANY && self.jobInfo.status_key =='new') {

                        self.processAcknowledgementAttachments(response.data.result.job_detail.job_meta_data.started_images);
                        console.log(self.acknowledgementAttachments);
                        console.log(self.acknowledgementAttachments.length);
                        if (self.acknowledgementAttachments.length > 0) {
                            XhrRequest.send(XhrRequest.XHR_POST_METHOD, 'plan-specific-attachments/acknowledgement/check', data, true, XhrRequest.responseType)
                                .then(response => {
                                    if (response.data.result.status) {
                                        openModal = true;
                                        self.hasAcknowledgementAttachments = true;
                                        self.termsAndConditionUrl = response.data.result.terms_condition_url;
                                        console.log(response.data.result);
                                        self.$refs.attachmentsAcknowledgementTest.show();
                                    }
                                });
                        } else {
                            if(self.jobInfo.job_group_type == PICA_QUOTATION_REQUEST){
                                XhrRequest.send(XhrRequest.XHR_POST_METHOD, 'supplier-terms-conditions/acknowledgement/check', data, true, XhrRequest.responseType)
                                    .then(response => {
                                        if (response.data.result.status) {
                                            openModal = true;
                                            self.termsAndConditionUrl = response.data.result.terms_condition_url;
                                            self.hasAcknowledgementAttachments = false;
                                            self.acknowledgementModalTitle = 'Terms & Conditions';
                                            self.acknowledgementHintText = 'You have to click the Terms & Conditions link and read the content to acknowledge them prior to sending the Quotation.';
                                            self.$refs.attachmentsAcknowledgementTest.show();
                                        }
                                    });
                            }
                        }
                    }
                    /*!* END Open attachment acknowledgement modal *!*/

                    /*!*self.jobLogFrom = from;*!*/

                    if (typeof self.$refs.quotation_req_list != "undefined" && self.user.role == 'company') {
                        self.$refs.quotation_req_list.reloadTable();
                        self.$refs.quotation_req_list.fetchQuoteRequestList();
                    }

                    if (typeof self.$refs.asset_approval_list != "undefined" && self.user.role == 'company') {
                        self.$refs.asset_approval_list.reloadTable();
                    }

                })
                    .catch((error) => {
                        self.$toast.formError('Something went wrong!');
                        console.error('Manage Modal Error', error);
                    });
            },
            loadMore() {
                this.jobLogFrom = this.jobLogFrom + 25;
                this.get(this.id, 0, this.jobLogFrom, true);
            },
            checkValidity(image) {
                let mandatory_read = image.data;
                if (!image.deleted && image.data && mandatory_read.mandatory_read) {
                    return true;
                } else {
                    return false;
                }
            },
            processAcknowledgementAttachments(attachments) {
                let attachment_array = [];
                let self = this;
                $.each(attachments, function (key, attachment) {
                    if (self.checkValidity(attachment) == true) {
                        attachment_array.push(attachment);
                    }
                });
                self.acknowledgementAttachments = attachment_array;
            },
            getFilename(url) {
                if (url) {
                    var m = url.toString().match(/.*\/(.+?)\./);
                    if (m && m.length > 1) {
                        return m[1];
                    }
                }
                return "";
            },
            acknowledge() {
                let self = this;
                let data = {id: this.id, company_id: this.user.company_id, job_group_type: self.jobInfo.job_group_type};
                XhrRequest.send(XhrRequest.XHR_POST_METHOD, 'plan-specific-attachments/acknowledgement', data, true, XhrRequest.responseType)
                    .then(response => {
                        if (response.data.result) {
                            // setTimeout(() => self.$refs.attachmentsAcknowledgement.hide(), 1500);
                            this.acknowledge_check = false;
                            self.$refs.attachmentsAcknowledgementTest.hide();
                            Vue.prototype.$toast.success('Plan specific attachments acknowledged successfully.');

                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            checkValidityImages(image) {
                if (image.data && image.data.plan_specific_attachment && !image.deleted && image.data.plan_specific_attachment == true) {
                    return 'plan_specific_attachment';
                } else {
                    return 'image';
                }
            },
            checkAttachmentCount(images, type) {
                let images_count = 0;
                let attachments_count = 0;
                images.filter(function (image) {
                    if (image.data && image.data.plan_specific_attachment && !image.deleted && image.data.plan_specific_attachment == true) {
                        attachments_count++;
                    } else {
                        images_count++
                    }
                });
                if (type == 'images') {
                    console.log(images_count);
                    return images_count;
                } else {
                    console.log(attachments_count);
                    return attachments_count;
                }
            },
            cancel() {

                this.$router.push(
                    {
                        name: 'job_dashboard',
                        params: {
                            'workflow': 'new',
                            // 'sub_workflow': ''
                        }
                    }
                );
            },
            acceptTerms() {
                let self = this;
                console.log(this);
                let data = {id: this.id, company_id: this.user.company_id};
                XhrRequest.send(XhrRequest.XHR_POST_METHOD, 'supplier-terms-conditions/acknowledgement', data, true, XhrRequest.responseType)
                    .then(response => {
                        if (response.data.result) {
                            // setTimeout(() => self.$refs.attachmentsAcknowledgement.hide(), 1500);
                            this.acknowledge_check = false;
                            self.$refs.attachmentsAcknowledgementTest.hide();
                            // Vue.prototype.$toast.success('Terms And Conditions acknowledged successfully.');

                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

        },
        computed: {
            ...mapFields(Create),
            ...mapGetters({
                user: 'userdata/user'
            }),
            ...mapGetters({
                terminologies: 'userdata/terminologies'
            }),
            isAuthenticated(){
                return AuthService.isAuthenticated;
            }
        },
        mounted() {
            let self = this;
            this.get(this.id, 0, 1);
            let el = document.getElementById("scroll-container");
            el.addEventListener('scroll', function (e) {
                let dif = el.scrollHeight - el.scrollTop - el.offsetHeight;

                if (dif <= 1000) {
                    //self.get(self.id, 10, self.jobLogFrom + 1, true);
                }
            });

            if(!this.$terminologies)
                this.$terminologies = this.terminologies;
        },
        watch: {
            '$store.getters.getData.recentActivity': {
                handler: function (val) {
                    if (val.job_id == this.id)
                        this.get(this.id);
                },
                deep: true
            }
        },
        created() {
            let self = this;
            /*window.Echo.listen('private-file-uploaded-' + this.id, '.file-uploaded', (process) => {
                self.$store.commit('job_create/addImageToProcess', process);
            });*/
        }
    };
</script>

<style lang="scss">
    @import "../../assets/styles/sass/_components/switch/switch";
    @import "../../assets/styles/sass/company/common/radio_button";
    @import "../../assets/styles/sass/company/views/edit-job";
    @import "../../assets/styles/sass/_components/placeholder/placeholder";

    .assets-pre-approve-list .table-responsive {
        min-height: 174px !important;
    }
    .txt-nl{
        word-break: break-word;
        max-height: 300px;
        overflow-y: auto;
        white-space: pre-line;
    }
</style>
