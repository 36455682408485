import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import WODashboard from '../views/work_orders/WODashboard.vue'
import QRDashboard from '../views/quote_requests/QRDashboard.vue'
import QuotationView from "../views/quote_requests/QuotationView";
import Login from '../views/auth/Login.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';
import ProfileSetting from '../views/profile/ProfileSetting';
import ProfileGeneral from '../views/profile/manage/General';
import NotificationSetting from '../views/profile/manage/NotificationSetting';
import Security from '../views/profile/manage/Security';
import ScanAuthenticator from '../components/system/ScanAuthenticator';
import ManualScanAuthenticator from '../components/system/ManualScanAuthenticator';
import VerifyTwoFactorAuth from '../views/auth/VerifyTwoFactorAuth';
import Manage from "../views/job/manage/Manage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {auth: true},
    redirect: { name: 'dashboard' },
    children:[
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {auth: true},
        props: true
      },
      {
        path: '/work_orders/dashboard',
        name: 'work_order_dashboard',
        component: WODashboard,
        meta: {auth: true},
        props: true
      },
      {
        path: '/quotations/dashboard',
        name: 'quote_request_dashboard',
        component: QRDashboard,
        meta: {auth: true},
        props: true
      },
      {
        name: 'quote_request_view',
        path: '/quotations/:id',
        component: QuotationView,
        meta: {auth: true},
        props: true
      },
      {
        name: 'i4tradies_profile_manage',
        path: '/manage',
        meta: {auth: true},
        component: ProfileSetting,
        redirect: {name: 'i4tradies_profile_general'},
        children: [
          {
            name: 'i4tradies_profile_general',
            path: '/manage/general',
            component: ProfileGeneral,
            props: true,
            meta: {auth: true}
          },
          {
            name: 'i4tradies_profile_settings',
            path: '/manage/settings',
            component: NotificationSetting,
            meta: {auth: true},
            props: true
          },
          {
            name: 'i4tradies_profile_security',
            path: '/manage/security',
            component: Security,
            meta: {auth: true},
            children: [
              {
                name: 'scan_authenticator',
                path: '/security/scan_authenticator',
                meta: {auth: true},
                component: ScanAuthenticator
              },
              {
                name: 'manual_scan_authenticator',
                path: '/security/manual_scan_authenticator',
                meta: {auth: true},
                component: ManualScanAuthenticator
              }
            ]
          }
        ]
      },
      {
        name: 'job_invoice',
        path: '/job/invoice/:jobId/:key?',
        meta: {translation: ['auth', 'validation', 'passwords']},
        props: true
      },
      {
        name: 'job_manage',
        path: '/job/:id/manage',
        component: Manage,
        meta: {translation: ['auth'], auth: true},
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPassword
  },
  {
    path: '/password/reset/:token',
    name: 'reset_password',
    component: ResetPassword
  },
  {
    path: '/checkpoint',
    name: 'checkpoint',
    component: VerifyTwoFactorAuth
  },
  {
    path: '/navigation',
    name: 'navigation',
    component: Dashboard,
    meta: {auth: true},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        Vue.prototype.$toast.loading(true);
    }
    next();
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
  Vue.prototype.$toast.loading(false);
});

router.beforeEach((to, from, next) => {
    //clear toast if loaded any
    // Vue.toasted.clear();
  console.log("to", to, "from", from)
  Vue.prototype.$acl.hasRoutePermission(to, from).then((has) => {

    if(has.auth === false){
      next({
        path:'/login'
      })
    }else{
      //no permissions
      if (has.hasPermission === false) {
        if (from.path != '/') {
          next({
            path: from.path,
            query: {redirect: to.fullPath}
          });
        }
        Vue.prototype.$toast.error('Component Disabled! Please check Company Configurations or contact Administrator.');
        next(false);

      } else {
        //has permissions -> proceed to the route
        next();
      }
    }


  }).catch((error) => {
    console.log("error", error)
    next(false);
    //Vue.prototype.$toast.error('Auth Errror. Try again later');
  });
    
});

export default router
