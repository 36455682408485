<template>
    <span class="balloon franchisee" v-if="type==1 && parent_id != null">Franchisee</span>
    <span class="balloon franchisor" v-else-if="type==2">Franchisor</span>
    <span class="balloon group" v-else-if="type==3">Group</span>
    <span class="balloon group" v-else-if="type==1 && parent_id == null && label != null">{{text}}</span>
    <span class="balloon group" v-else-if="label != null">{{text}}</span>
</template>

<script>
    export default {
        name: 'CompanyTypeBadge',
        props: {
            type : Number,
            parent_id : Number,
            text : ''
        },
        data(){
            return {
                label : this.text
            }
        }
    };
</script>
<style scoped>
</style>