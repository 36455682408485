<template>
  <div>
    <div class="top-bar" id="top_header_bar">
      <div class="logo-section header-left-section">
        <div class="collapse-menu-box" id="collpase-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#000"
            viewBox="0 0 48 48"
          >
            <path d="M6 36h36v-4H6v4zm0-10h36v-4H6v4zm0-14v4h36v-4H6z" />
          </svg>
        </div>
        <div class="collapse-menu-box" id="expand-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#000"
            viewBox="0 0 48 48"
          >
            <path d="M6 36h36v-4H6v4zm0-10h36v-4H6v4zm0-14v4h36v-4H6z" />
          </svg>
        </div>
        <a href="#" @click.prevent="redirectDashboard()" class="main-link">
          <img :src="user ? user.app_logo : ''" />
          <span>{{ user ? user.app_name : '' }}</span>
          <!-- <sup>{{data.logo.beta}}</sup>-->
<!--          <div class="extra-logo">-->
<!--            <img :src="user ? user.brand_logo : '' " />-->
<!--          </div>-->
        </a>
<!--        <div class="badge-row">-->
<!--          <div class="subcont-bg">-->
<!--            <div class="badge">-->
<!--              <img src="img/icons/subcontractor.png" />-->
<!--              <span></span>-->
<!--            </div>-->
<!--            <div class="badgelabel">Subcontractor</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!--------------------
            START - Top Menu Controls
            -------------------->
      <div class="header-right-section">
<!--        <div class="top-menu-controls">-->
<!--          <div-->
<!--            class="element-search autosuggest-search-activator text-left"-->
<!--          ></div>-->
<!--        </div>-->
        <div class="top-company-name user-badge-type round-profile">
          <span class="porfile-round-piture">
            <img :src="user ? user.profile_logo : ''" />
          </span>
          <span>{{ user ? user.name : '' }}</span>
          <div class="middle-connect">
            <div class="mid-line"></div>
          </div>
          <company-type-badge v-if="user && user.badge" :type="user.user_type" :parent_id="user.parent_id"
                              :text="user.badge"></company-type-badge>
        </div>
<!--        <div class="color-style-bright switch-account">-->
<!--          <i class="fas fa-arrow-alt-circle-left"></i>-->
<!--          Switch Account-->
<!--        </div>-->
        <div class="top-menu-controls loguser-setings">
          <!--------------------
                    START - User avatar and menu in secondary top menu
                    -------------------->
          <div class="logged-user-w">
            <div class="logged-user-i">
              <div class="avatar-w">
                <!--<img alt="" :src="$user.logo">-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  width="21"
                  height="21"
                  viewBox="0 0 48 48"
                >
                  <path
                    d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97L29 4.84c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97L9.9 10.1c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31C9.06 22.69 9 23.34 9 24s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zM24 31c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
                  />
                </svg>
              </div>
              <div class="logged-user-menu color-style-bright logged-drop-box">
                <i class="up-span"></i>
                <span class="bank-space"></span>
                <div class="border-crve">
                  <div class="logged-user-avatar-info">
                    <div class="logged-user-info-w">
                      <div class="logged-user-name">{{ user ? user.name : ''}}</div>
                      <div class="logged-user-role">
                        <span class="last_login"
                          ><svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style="enable-background: new 0 0 512 512"
                            xml:space="preserve"
                          >
                            <g>
                              <g>
                                <path
                                  d="M458.667,85.333H53.333C23.936,85.333,0,109.269,0,138.667v234.667c0,29.397,23.936,53.333,53.333,53.333h405.333
                c29.397,0,53.333-23.936,53.333-53.333V138.667C512,109.269,488.064,85.333,458.667,85.333z M490.667,373.333c0,17.643-14.357,32-32,32H53.333c-17.643,0-32-14.357-32-32V138.667c0-17.643,14.357-32,32-32h405.333c17.643,0,32,14.357,32,32V373.333z"
                                />
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M467.456,132.651c-3.307-4.864-9.941-6.08-14.827-2.773L277.675,249.579c-13.184,9.003-30.208,9.003-43.371,0L59.349,129.877c-4.885-3.349-11.52-2.091-14.827,2.773c-3.307,4.864-2.069,11.499,2.795,14.827l174.955,119.701
                c10.24,7.019,21.995,10.517,33.728,10.517s23.488-3.499,33.728-10.517l174.955-119.701C469.547,144.149,470.784,137.515,467.456,132.651z"
                                />
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M189.525,259.819c-3.776-4.501-10.517-5.12-15.04-1.365l-128,106.667c-4.523,3.776-5.141,10.517-1.365,15.04
                c2.112,2.539,5.141,3.84,8.213,3.84c2.411,0,4.843-0.811,6.827-2.475l128-106.667C192.683,271.083,193.301,264.341,189.525,259.819z"
                                />
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M465.515,365.12l-128-106.667c-4.544-3.755-11.264-3.136-15.04,1.365c-3.776,4.544-3.157,11.264,1.365,15.04l128,106.667
                c2.005,1.664,4.416,2.475,6.827,2.475c3.051,0,6.08-1.301,8.213-3.84C470.656,375.616,470.037,368.896,465.515,365.12z"
                                />
                              </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                          </svg>
                          {{ user ? user.email : '' }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="bg-icon">
                    <i class="os-icon os-icon-wallet-loaded"></i>
                  </div>
                  <ul class="right-top-menu">
                    <li v-for="item in items">
                        <router-link :to="{'name' : item.route}">
                            <div class="os-icon" v-bind:class="item.icon" v-if="!item.svg"></div>
                            <div class="os-icon" v-else v-html="item.svg"></div>
                            <span class="item-small">{{item.label}}</span>
                        </router-link>
                    </li>

                    <li>
                      <a @click="confirmLogout">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 48 48"
                        >
                          <path
                            d="M26 6h-4v20h4V6zm9.67 4.33l-2.83 2.83C35.98 15.73 38 19.62 38 24c0 7.73-6.27 14-14 14s-14-6.27-14-14c0-4.38 2.02-8.27 5.16-10.84l-2.83-2.83C8.47 13.63 6 18.52 6 24c0 9.94 8.06 18 18 18s18-8.06 18-18c0-5.48-2.47-10.37-6.33-13.67z"
                          />
                        </svg>
                        <span class="item-small">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--------------------
                    END - User avatar and menu in secondary top menu
                    -------------------->
        </div>
      </div>
      <!--------------------
            END - Top Menu Controls
            -------------------->
    </div>
  </div>
</template>


<script>
import Vue from 'vue';
import {mapGetters, mapActions} from 'vuex';
import CompanyTypeBadge from '../badge/CompanyTypeBadge';
import swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  props: ["data"],
  components: {
    CompanyTypeBadge
  },
  data() {
    return {
      images: {
        icon: "./../../../../../../../images/plus.png",
      },
      clean: function () {
        return "";
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "userdata/user",
      items: "navigation/top"
    }),

    showSearch() {
      return true;
    }
  },
  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),
    redirectDashboard() {
      this.$router.push({
        name: "dashboard",
      });
    },
    initCreateMenu() {
      //Plus Menu
      $(".os-dropdown-trigger").on("mouseenter", function () {
        $(this).addClass("over");
      });
      $(".os-dropdown-trigger").on("mouseleave", function () {
        $(this).removeClass("over");
      });

      $(".os-dropdown-trigger li a").on("click", function () {
        $(".os-dropdown-trigger").removeClass("over");
      });
    },
    confirmLogout() {
      let self = this;
      swal({
        title: "Logout",
        text: "Are you sure you want to logout?",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Proceed",
        reverseButtons: true,
        imageWidth: 55,
        imageHeight: 60,
        imageAlt: "Logout",
      }).then((result) => {
        if (result.value != undefined && result.value) {
          this.logout().then(res => {
            if (res) {
              self.$router.push({
                name: 'Login'
              })
            }
          })
        }
      });
    },

  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/sass/_components/navigation/navigation.scss";
.last_login {
  display: block;
}
</style>
