import Vue from 'vue';
import L from "leaflet";

export class MapService {
    constructor(id = 'map', zoomLevel = 5, inputElement = null, opt = {}) {

        this.zoomLevel = zoomLevel;
        this.centerLat = (opt.centerLat) ? opt.centerLat : -23.753312;
        this.centerLng = (opt.centerLng) ? opt.centerLng : 134.122823;
        this.countries = (opt.country_code) ? opt.country_code : ['au'];

        this.map = L.map(id).setView([this.centerLat, this.centerLng], zoomLevel);
        this.tileLayer = L.tileLayer(
            'https://mts1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            {
                maxZoom: 18
            }
        );
        this.tileLayer.addTo(this.map);
        this.components = {};
        this.map.scrollWheelZoom.disable();

        let self = this;
        this.map.on('click', function () {
            if (self.map.scrollWheelZoom.enabled()) {
                self.map.scrollWheelZoom.disable();
            } else {
                self.map.scrollWheelZoom.enable();
            }
        });

        this.lastCircleBound = null;
        this.inputElement = inputElement;
        this.id = id;

        this.strictBounds = document.getElementById(this.inputElement);
        this.autocomplete = new google.maps.places.Autocomplete(this.strictBounds, {types: ['address']});
        this.autocomplete.setComponentRestrictions({'country': this.countries});
    }

    setAddress(address, callback, selected=false) {
        let self = this;
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': address, 'componentRestrictions':{'country':'AU'}}, function (result, status) {
            if (status == 'OK') {

                let place = result[0];

                let data = {
                    address: address,
                    coordinates: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    },
                    selected: selected
                };

                if(selected) {
                    data.address_components = place.address_components;
                    data.formatted_address = place.formatted_address;
                }

                self.map.setView([data.coordinates.lat, data.coordinates.lng], self.zoomLevel);
                callback(data, true);


            } else {
                callback([], false);
            }
        });
    }

    formatAddress(place) {

        let address_components = place.address_components;
        let country = '';
        let addressElements = [];
        let address = '';

        for (let i = 0; i < address_components.length; i++) {
            let component = address_components[i];
            let addressType = component.types[0];

            switch (addressType) {
                case 'locality':
                    addressElements[0] = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    addressElements[1] = component.long_name;
                    break;
                case 'postal_code':
                    addressElements[2] = component.long_name;
                    break;
                case 'country':
                    country = component.long_name;
                    break;
            }
        }
        address = addressElements.join(' ') + ", " + country;
        if (addressElements[0] !== place.name)
            address = place.name + ', ' + address;

        return address;
    }

    selectAddress(callback) {
        let self = this;

        // google.maps.event.addListener(this.autocomplete, 'place_changed', function () {
        //     let place = self.autocomplete.getPlace();
        //
        //     if (!place.geometry) {
        //         // Vue.prototype.$toast.formError('This address is restricted!');
        //         // self.strictBounds.value = "";
        //         //  callback([], false);
        //         //  return false;
        //     }
        //     let data = {/*
        //         address: self.formatAddress(place),
        //         coordinates: {
        //             lat: place.geometry.location.lat(),
        //             lng: place.geometry.location.lng()
        //         }*/
        //     };
        //     self.setAddress(self.strictBounds.value, function (val) {
        //         callback(val, true);
        //     }, true);
        //
        //
        // });


    }

    addCircle(location, radius) {
        let circle = L.circle([location.lat, location.lng], {
            color: 'rgba(0,0,0,0)',
            fillColor: 'rgb(75, 172, 79)',
            fillOpacity: 0.5,
            radius: radius
        }).addTo(this.map);
        this.map.fitBounds(circle.getBounds());
        return circle;
    }

    addImageMarkers(locations, img = 'img/emp-pin.png', clearPrevious = true) {
        let self = this;
        if (clearPrevious)
            self.clearMapMarkers();

        let marker = null;
        locations.forEach((location) => {
            if (location.lat && location.lng) {
                marker = L.marker([location.lat, location.lng], {
                    icon: self.getPNGMarker(img)
                });

                if (location.popup) {
                    // popup = L.responsivePopup().setContent(location.popup);
                    marker.bindPopup(location.popup);
                }

                marker.addTo(self.map);
            }
        });
    }

    bound(geometry, img = false) {
        let bounds = geometry.viewport;
        let location = geometry.location;

        this.map.fitBounds([
            [bounds.northeast.lat, bounds.northeast.lng],
            [bounds.southwest.lat, bounds.southwest.lng]
        ]);

        if (img)
            L.marker([location.lat, location.lng], {
                icon: this.getPNGMarker(img)
            }).addTo(this.map);
    }

    clearMap() {
        let self = this;
        this.map.eachLayer(function (layer) {
            if (layer._latlng) {
                self.map.removeLayer(layer);
            }
        });

    }

    clearMapMarkers() {
        let self = this;

        this.map.eachLayer(function (layer) {
            if (layer._latlng && !layer._mRadius) {
                self.map.removeLayer(layer);
            }
        });

    }

    getPNGMarker(img = 'img/emp-pin.png') {

        return L.divIcon({
            iconAnchor: [17, 40],
            popupAnchor: [0, 0],
            className: 'marker',
            html: '<div><img class=\'marker_img\' src=\'' + img + '\'></div>'
        });
    }

    getAddressComponent(address_components) {
        let self = this;
        _.each(address_components, function (k, v1) {
            _.each(address_components[v1].types, function (k2, v2) {
                if (self.components)
                    self.components[address_components[v1].types[v2]] = address_components[v1].long_name;
            });
        });
        return this.components;
    }

    centerMapOnMarker() {


        let f = false;
        let l = false;

        let minLng = false;
        let maxLat = false;

        let maxLng = false;
        let minLat = false;


        this.map.eachLayer(function (layer) {


            if (layer._latlng) {
                let c_lanlng = layer.getLatLng();
                let tot = c_lanlng.lat + c_lanlng.lng;

                if (!f) {
                    f = c_lanlng;
                    l = c_lanlng;

                    minLng = c_lanlng.lng;
                    maxLat = c_lanlng.lat;

                    maxLng = c_lanlng.lng;
                    minLat = c_lanlng.lat;
                }

                if (minLng > c_lanlng.lng) {
                    minLng = c_lanlng.lng;
                }
                if (maxLat < c_lanlng.lat) {
                    maxLat = c_lanlng.lat;
                }
                if (maxLng < c_lanlng.lng) {
                    maxLng = c_lanlng.lng;
                }
                if (minLat > c_lanlng.lat) {
                    minLat = c_lanlng.lat;
                }

                f = L.latLng(maxLat, minLng);
                l = L.latLng(minLat, maxLng);

            }
        });

        if (f) {

            let bounds = [f, l];
            this.map.fitBounds(bounds, {
                padding: [10, 10]
            });
        }
    }
}
