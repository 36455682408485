<template>
    <div class="input-group mb-3 table-records-pages">
        <select class="form-control" v-model="filterKey" @change="doFilter">
            <option v-for="option in options" v-bind:value="option.key">{{option.value}}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "Dropdown",
        props: {
            options: {
                Default: null
            }
        },
        data() {
            return {
                filterKey: '',
            }
        },
        methods: {
            doFilter() {
                let self = this;
                self.$events.fire('filter-ddl', self.filterKey);
            },
            doFilterDdlWithSearch(filterData) {
                this.$events.fire('filter-ddl-and-search-bar', this.filterKey, filterData);
            }
        },
        events: {
            autoFireDropDownFilter(filterData) {
                this.doFilterDdlWithSearch(filterData);
            }
        }
    }
</script>

<style scoped>

</style>