<template>

    <div class="el-chart-w">
        <line-chart :data="lineDataCollection" :options="options" :height="150" :ref="'chart'"></line-chart>
    </div>

</template>

<script>
    import LineChart from '../../components/chart/LineChart';
    export default {
        name: 'CashflowGraph',
        props: ['data'],
        components: {LineChart},
        data() {
            return {
                loaded: false,
                lineDataCollection: {
                    labels: this.data.labels,
                    datasets: this.data.datasets
                },
                options: {
                    maintainAspectRatio: false,

                },
                is_empty: false
            };
        },
        methods: {
            reloadChart() {
                this.$refs.chart.reload();
            }
        },

    };

</script>

<style scoped lang="scss">

    #line-chart{
        height: 257px !important;
    }

</style>


