<template>
    <mannual-scan-authenticator ref="MannualScanAuthenticatorModal" name="MannualScanAuthenticatorModal" transition="pop-out" :width="700" :headerTitle="modelTitle">

        <template slot="body">

            <div class="row">

                <div class="col-sm-12">

                    <h4 class="steps-qr">Steps</h4>

                    <ul class="qrcode-area">

                        <li>
                            Get the Google Code Authenticator App from the <a target="_blank" href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8">App Store</a> Andriod
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Play Store</a>.
                        </li>

                        <li>
                            In the App, Tap the <strong>Add</strong> Button in the Navigation Bar.
                        </li>

                        <li>
                            Choose the <strong>Scan Barcode</strong>
                        </li>

                    </ul>

                </div>

            </div>


        </template>

        <template slot="footer">
            <button id="updateButton" class="btn btn-primary" type="button"> Next</button>
        </template>

    </mannual-scan-authenticator>

</template>

<script>

    import ScanAuthenticator from './../modal/VueJsModal';

    export default {
        // name: "ScanAuthenticator",
        methods: {},
        mounted() {
            // alert();
            this.$refs.MannualScanAuthenticatorModal.show();
        },
        components: {
            ScanAuthenticator
        },

        data: () => ({
            modelTitle: 'Set Up Two-Factor Authentication'
        })

    }
</script>

<style scoped lang="scss">

    @import './../../assets/styles/sass/_components/2fa-authentication/authentication.scss';

</style>
