<template>

    <div class="sub-boxes">


        <div class="row">

            <div class="col-sm-12">

                <div class="factor-area">

                    <h3>Two-Factor Authentication</h3>


                    <div class="factor-img">
                        <img src="../../../assets/img/auth-img.png"/>
                    </div>

                    <div class="auth-control">

                        <div class="row">

                            <div class="col-sm-12">

                                <div class="devide-auth">

                                    <label style="cursor: pointer;">

                                        <div class="form-group google-auth">

                                            <div class="auth-option">Enable</div>

                                            <label class="switch">
                                                <input type="checkbox" v-on:change="openModel($event)"
                                                       v-model="google_auth"
                                                       :true-value="1" :false-value="0">
                                                <span class="slider round"></span>
                                            </label>

                                        </div>

                                    </label>

                                </div>

                            </div>

                        </div>


                    </div>
                    <router-view @closed="google_auth=0" :user_id="0" @disable="disableAuth"
                                 @enable="enableAuth"></router-view>

                </div>


            </div>
        </div>


    </div>


</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';

    export default {
        name: 'security',
        data() {
            return {
                google_auth: 0
            }
        },
        computed:{
            ...mapGetters({
                user: "userdata/user"
            }),
        },
        methods: {
            ...mapActions({
                fetchUserData: 'userdata/fetchAuthUserData'
            }),
            openModel(event) {

                let self = this;

                if (event.target.checked) {
                    this.$router.push({name: 'scan_authenticator'});
                } else {
                    swal({
                        title: 'Are you sure  you want to <br />Turn Off Two-Factor  Authentication ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {

                            XhrRequest.send(XhrRequest.XHR_POST_METHOD, '2fa/disable')
                                .then(response => {
                                    self.getUserData();
                                    self.$acl._storeUserData();
                                })
                                .catch(error => {

                                });

                        } else
                            self.google_auth = 1;
                    });
                }

            },
            enableAuth(method = 1) {
                let self = this;
                XhrRequest.send(XhrRequest.XHR_POST_METHOD, '2fa/enable', {method: method}).then(function () {
                    self.getUserData();
                    self.$acl._storeUserData();
                });
            },
            disableAuth() {
                let self = this;
                XhrRequest.send(XhrRequest.XHR_POST_METHOD, '2fa/disable').then(function () {
                    self.getUserData();
                });
            },
            getUserData() {
                let self = this;
                self.fetchUserData().then(response => {
                    let _response = response;
                    self.google_auth = _response.user.two_factor ? 1 : 0;

                    if ((self.user.admin && self.user.role == "enterprise" && _response.user.two_factor == 0) || (self.user.role == "admin" && _response.user.two_factor == 0)) {
                        if (self.$route.query.force) {
                            self.$toast.error('Please enable Two-Factor Authentication as a priority');
                        }
                    }
                })
            }
        },
        mounted() {
            this.getUserData();
        },
        watch: {}
    };
</script>

<style scoped lang="scss">

    @import "../../../assets/styles/sass/_components/switch/switch.scss";
    @import '../../../assets/styles/sass/_components/radio/radio.scss';
    @import '../../../assets/styles/sass/_components/2fa-authentication/authentication.scss';

    .switch {
        width: 39px;
        height: 20px;
        position: relative;
        top: 5px;
    }

    .slider:before {
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 1px;
    }

    .multi-radio {

        .radio-btn-area.align-set {
            padding-top: 0px !important;
            border-right: none !important;
            min-height: 30px;
            padding-bottom: 0;
        }

    }

    .mt-radio {
        float: left !important;
        width: 100% !important;
        padding-bottom: 7px !important;
        border: solid 1px #f5f5f5 !important;
        margin-bottom: 10px !important;
        background-color: #f8f8f8 !important;
        padding-top: 8px !important;
        padding-left: 15px !important;
        margin-top: 3px !important;
        border-radius: 6px !important;
        font-weight: 500 !important;
        color: #636262 !important;
        font-size: 0.9rem !important;
    }

    .sub-setting-option {

        padding-bottom: 15px;
        padding-top: 10px;

        .select-all-button {
            float: right;
            margin-right: 21px;
            margin-top: -25px;
        }

    }

    .list-sub {
        clear: both;
        margin-bottom: 8px;
    }

    .set-colss {
        position: relative;
        top: 5px;
    }

</style>
