import { Validator } from 'vee-validate';

export default {

    install: (Vue, options) => {

        Vue.prototype.$validated = {

            setErrors(obj, response) {
                //const validator = new Validator();
                let errorResponse = response.result.errors;
                let errorFields = Object.keys(errorResponse);

                // insert laravel errors
                errorFields.map(field => {
                    let errorString = errorResponse[field].join(', ');
                    obj.errors.add({ field: field, msg: errorString });
                    //validator.errors.add({ field: field, msg: errorString });
                });
            }
        }
    }
}
