import Axios from "./AxiosService";
import {BaseService} from "./BaseService";
import {ur} from "vuejs-datepicker/dist/locale";

class QuotationService extends BaseService{
    list(queryData){
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/quotations/list?'+queryData, {})
            .then(response => {
                return response;
            });
    };

    quotation(id){
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/quotations/details/'+id, {})
            .then(response => {
                return response;
            });
    };

    searchManager(){
        return Axios
            .send(Axios.XHR_GET_METHOD,'all/strata-managers', {})
            .then(response => {
                return response;
            });
    };

    status(){
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/status-groups?type=quotation_request', {})
            .then(response => {
                return response;
            });
    };

    pdfView(quotation_id, type){
        if (type == 'external'){
            return Axios
                .send(Axios.XHR_GET_METHOD,'committee/quotation/external/sheet/'+quotation_id, {})
                .then(response => {
                    return response;
                });
        }else{
            return Axios
                .send(Axios.XHR_GET_METHOD,'committee/quotation/sheet/'+quotation_id+'/pdf', {})
                .then(response => {
                    return response;
                });
        }
    };

    quotationRequest(jobId){
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/quotation/request/'+jobId+'/download_file', {})
            .then(response => {
                return response;
            });
    };

    rejectReasons(){
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/app/reject_reasons', {})
            .then(response => {
                return response;
            });
    };

    reject(formData){
        return Axios.send(Axios.XHR_POST_METHOD, 'job/workflow', formData, true, '', {
            headers: {
                'Content-Type': 'multipart/form-data'
            }}).then(response => {
                return response;
            });
    };

    approve(formData){
        return Axios.send(Axios.XHR_POST_METHOD, 'job/workflow', formData, true, '', {
            headers: {
                'Content-Type': 'multipart/form-data'
            }}).then(response => {
            return response;
        });
    };

    rejectMultiple(formData){
        return Axios.send(Axios.XHR_POST_METHOD, 'job/call/workflow', formData, true, '', {
            headers: {
                'Content-Type': 'multipart/form-data'
            }})
            .then(response => {
                return response;
            });
    };
}

export default new QuotationService();