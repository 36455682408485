import Axios from "./AxiosService";
import store from '../store';
import {BaseService} from "./BaseService";
import acl from "../plugins/acl";

class ProfileService extends BaseService{

    fetch(tenantId) {
        return Axios
            .send(Axios.XHR_GET_METHOD,'committee/edit/' + tenantId, {}, true)
            .then(response => {
                return response.data;
            });
    }

    updateSettings(settings){
        return Axios.send(Axios.XHR_POST_METHOD,'committee/update/notification-settings', settings)
    }
}

export default new ProfileService();