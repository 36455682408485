<template>
    <div>
        <div action="/" class="dropzone" v-bind:id="id"
             :class="isDisabled ? 'disable-company-certificate-uploader-click' : ''">
            <div class="dz-message" data-dz-message>

                <div class="inner-content-text">
                    <h4 v-if="showHeader" v-text="headerText"></h4>
                    <div class="text-muted">{{subHeaderText}}</div>
                    <i class="fa fa-upload"></i>
                </div>

            </div>
            <div class="fallback">
                <input type="file" name="file" multiple/>
            </div>
        </div>
    </div>
</template>

<script>
    import Dropzone from 'dropzone';

    Dropzone.autoDiscover = false;

    export default {
        name: 'Uploader',
        props: {
            id: {},
            isDisabled: false,
            isDeleteConfirm: false,
            maxFiles: {
                default: 10
            },
            maxFileSize: {
                default: 5
            },
            acceptedFiles: {
                default: null
            },
            showHeader: {
                default: true
            },
            headerText: {
                default: 'Drag file or Click here to Upload'
            },
            subHeaderText: {
                default: '(Allowed File Types: jpg, png, gif, pdf)'
            }
        },
        data() {
            return {
                validated: true,
                autoRemoved: false,
                dropzoneChangeReturn: false
            };
        },

        $_veeValidate: {

            value() {
                return this.files && this.validated;
            },
            // name getter
            name() {
                return this.name;
            }
        },
        mounted() {
            let self = this;
            this.dropzone = new Dropzone('#' + this.id, {
                autoQueue: false,
                addRemoveLinks: true,
                maxFiles: this.maxFiles,
                acceptedFiles: this.acceptedFiles,
                uploadMultiple: true,
                maxFilesize: this.maxFileSize,
                filesizeBase: 1024,
                init: function () {
                    this.on('addedfile', function (file, url) {
                        self.addImage(file, url);
                    });

                    this.on('addedfiles', function (file) {
                        if (file[0].type == 'application/pdf')
                            setTimeout(function(){
                                self.fileReady(file);
                            },1000)
                    });

                    this.on('thumbnail', function (dataUrl) {
                        self.fileReady();
                    });
                    this.on('removedfile', function (file) {
                        if (!self.autoRemoved)
                            self.removeFile(file);
                    });

                    this.on('queuecomplete', function (file) {

                    });
                }
            });

            this.dropzone.on('error', function (file, error) {
                self.error(file, error);
                self.dropzone.removeFile(file);
            });

        },
        methods: {
            changeDropZone() {
                return this.dropzoneChangeReturn;
            },
            addImage(file, url) {
                let self = this;
                if (!url && file instanceof Blob) {
                    url = file.dataURL;

                    let reader = new FileReader();
                    reader.onload = function (event) {
                        let base64String = event.target.result;
                        file.previewElement.addEventListener('click', function (e) {
                            self.$emit('click-file', base64String);
                        });
                    };

                    reader.readAsDataURL(file);
                } else {
                    file.previewElement.addEventListener('click', function (e) {
                        self.$emit('click-file', url);
                    });
                }
                self.dropzoneChangeReturn = true;
                return this.dropzoneChange;
            },
            removeFile(file) {
                this.$emit('file-removed', file);
            },
            checkFileAdded() {
                if (this.dropzone.getQueuedFiles().length > 0) {
                    return true;
                }
                return false;

            },
            processQueue() {
                return this.dropzone.getAcceptedFiles();
            },
            isEmpty() {
                let files = this.dropzone.getAcceptedFiles();
                return files.length > 0 ? false : true;
            },
            isMockFileEmpty() {
                return this.dropzone.getQueuedFiles();
            },
            getDropOptions() {
                return Object.assign(this.dropOptions, this.options);
            },
            removeAllFiles() {
                this.dropzone.removeAllFiles(true);
            },
            addDefaultImage(dataURI = false, column = null, file = false) {
                if (dataURI)
                    file = this.dataURItoBlob(dataURI, column);

                this.dropzone.addFile(file);
                this.dropzoneChange = false;
            },
            ext(url) {
                return (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0]).split('#')[0].substr(url.lastIndexOf('.'));
            },

            thumbnail(imageUrl, unique = false, id = null) {
                let actualImage = imageUrl;

                if (unique) {
                    this.autoRemoved = true;//if removing automatically dont sent event
                    this.removeAllFiles();
                    this.autoRemoved = false;
                }

                if (this.ext(imageUrl) == '.pdf')
                    imageUrl = 'https://i4t.cloud/cdn/assets/images/pdf-icon.jpg';

                let mockFile = {name: 'Already Uploaded', size: 0, _id: id};
                this.dropzone.emit('addedfile', mockFile, actualImage);
                this.dropzone.emit('thumbnail', mockFile, imageUrl);
                this.dropzone.files.push(mockFile); // file must be added manually
                this.validated = true;
                this.files = [mockFile];
            },
            loadDataFromServer(imageUrl) {
                // // TODO - Replace the following with a proper implementation.
                // if (!$('.dz-image-preview').length) {
                //
                //     // Create the mock file:
                //     var mockFile = {name: imageUrl, accepted: true};
                //
                //     this.dropzone.displayExistingFile(mockFile, imageUrl);
                //     // Call the default addedfile event handler
                //     this.dropzone.emit('addedfile', mockFile);
                //
                //     // And optionally show the thumbnail of the file:
                //     this.dropzone.emit('thumbnail', mockFile, imageUrl);
                // }
            },
            fileReady(file = false) {
                // fileReady() {
                // let files = this.dropzone.files;

                // let files = file ? Array.from(file) : this.dropzone.files;
                var files = this.dropzone.files;
                /*if (files[0].onlyOnce)
                    return false;*/

                files.forEach((file, i) => {
                    file.inBucket = true;
                    files[i] = file;
                });

                this.$emit('file-added', this.id, files);
                this.files = files;
                this.$emit('input', this.id, files);
            },
            dropzoneChanged() {

                if (this.file.croped || (this.file.type !== 'image/jpeg' && this.file.type !== 'image/png' && this.file.type !== 'application/pdf'))
                    return;


                this.showCropper = this.croppable;
                let self = this;
                let dataUrl = this.file.dataURL;
                self.imgSrc = dataUrl;
                self.fileName = this.file.name;
                self.$refs.cropper.replace(dataUrl);
                self.dropzoneChange = true;
            },
            fileAdded(file) {
                this.file = file;
            },
            cropImage() {
                this.dropzone.removeFile(this.file);
                this.showCropper = false;
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
                let file = this.dataURItoBlob(this.cropImg);
                file.name = this.fileName;
                file.croped = true;
                this.dropzone.addFile(file);
            },
            rotate(ratio) {
                this.$refs.cropper.rotate(ratio);
            },
            dataURItoBlob(dataURI) {
                let byteString = atob(dataURI.split(',')[1]);
                let ab = new ArrayBuffer(byteString.length);
                let ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ab], {type: 'image/jpeg'});
            },
            error(file, error) {
                this.$emit('error', {file: file, error: error});
            }
        }
    };
</script>
<style lang="scss">
    .disable-company-certificate-uploader-click {
        pointer-events: none;
    }
</style>

<style>
    @import "~dropzone/dist/min/dropzone.min.css";

    .dropzone {
        padding: 0px;
    }

    .dz-progress {
        display: none !important;
    }

    .dropzone .dz-preview .dz-error-message {
        top: 160px;
    }
</style>
