<template>
    <div class="map-container-inner-area">


        <div class="input-group title-with-location">
            <div class="map-wrap-div">
                <div class="input-group-prepend" :id="'input_group_prepend_' + id">
                    <span class="input-group-text">Location <b class="location-marking">*</b></span>
                </div>

                <input :id="'map_' + id" v-model="search" class="form-control"  @change="changeAddress" @keyup.enter="changeAddress" :placeholder="defaultPlaceholder" type="text" v-if="showInputBar" :readonly="isDisabledInputBar" :disabled="isDisabledInputBar" placeholder="Please enter the Address here">
                </div>
                <ul v-show="results.length" class="autocomplete-results">
                    <li v-for="(result, i) in results" :key="i" v-bind:data-id="i" class="autocomplete-result">
                        {{ result.value }}
                    </li>
                </ul>
            </div>
        <div class="address-map">
            <div :id="id" class="map"></div>
        </div>
    </div>
</template>
<script>
    import L from "leaflet";
    import {MapService} from "../../services/MapService";

    export default {
        data() {
            return {
                map: null,
                tileLayer: null,
                layers: [],
                search: '',
                results: [],
                value: "",
                defaultPlaceholder: this.placeholder
            };
        },
        name: "Map",
        $_veeValidate: {

            value() {
                return this.search;
            },
            // name getter
            name() {
                return this.name;
            }
        },
        props: {
            id: {
                type: String
            },
            address: {
                type: String
            },
            placeholder: {
                type: String
            },
            showInputBar: {
                type: Boolean,
                default: true
            },
            clearMap: {
                type: Boolean,
                default: true
            },
            clearPrevious: {
                type: Boolean,
                default: true
            },
            fitBounds: {
                type: Boolean,
                default: true
            },
            addressChanged: {
                type: Boolean,
                default: false
            },
            inputElement: {
                default: null
            },
            isDisabledInputBar: {
                type: Boolean,
                default: false
            },
            selectedOnly: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.$el.value = this.results;
            this.locationAddress();
            this.initMap();
            if (this.address)
                this.setAddress(this.address);
        },
        methods: {
            initMap() {
                let self = this;
                let opt = {};
                if (this.$user?.location) {
                    opt.centerLat = this.$user.location.lat;
                    opt.centerLng = this.$user.location.lng;
                    opt.country_code = (this.$user.country) ? this.$user.country.iso : 'au';
                    opt.zoomLevel = (this.$user.country) ? this.$user.country.zoom_level : 3;
                } else {
                    opt.zoomLevel = 3;
                }
                this.map = new MapService(this.id, opt.zoomLevel, this.inputElement ? this.inputElement : 'map_' + this.id, opt);
                this.map.selectAddress(function (data, status) {
                    if (status) {
                        self.$emit("address-selected", data);
                        self.addImageMarkers([data.coordinates]);
                    } else
                        self.$emit("address-selected", data);

                });
            },
            refresh() {
                this.map.map.invalidateSize();
            },
            clearMapMarkers() {
                this.map.clearMapMarkers();
            },
            addImageMarkers(locations, marker = 'https://static.i4t.cloud/icons/system/default/emp-pin.png') {
                this.map.addImageMarkers(locations, marker);
            },
            addCircle(location, radius) {
                this.map.addCircle(location, radius);
            },
            setAddress(address, manual=false) {
                let self = this;
                if(!self.selectedOnly || manual)
                {
                    this.map.setAddress(address, function (data, status) {
                        if (status) {
                            self.$emit("address-selected", data);
                            self.addImageMarkers([data.coordinates]);
                            self.search = data.address;
                        } else
                            self.$emit("address-selected", data);
                    });
                }
            },
            changeAddress() {
                this.$emit("address-text-entered");
            },
            locationAddress() {
                let addressInputElement = $('#map_'+this.id);
                if(addressInputElement.length) {
                    addressInputElement.on('focus', function () {
                        let pacContainer = $('.pac-container');
                        $(addressInputElement.parent()).append(pacContainer);
                    })
                }
            }
        },
        watch: {
            address() {
                if(this.address)
                    this.setAddress(this.address);
                if (!this.address || !this.address.length)
                    this.map.clearMapMarkers();
            }
        },
        created() {
            let self = this;
            this.$parent.$on('address-modified', function (adresses) {
                self.map.setAddress(adresses.formatted, function (data, status) {
                    data.address = adresses.text;
                    data.map_location = adresses.formatted;
                    if (status) {
                        self.$emit("address-selected", data);
                        self.addImageMarkers([data.coordinates]);
                        self.search = data.address;
                    } else
                        self.$emit("address-selected", data);
                });
            });
        }
    };
</script>

<style>
    @import "~leaflet/dist/leaflet.css";
    @import "../../assets/styles/sass/_components/map/map.scss";

    #employeeMap .leaflet-popup.leaflet-zoom-animated {
        bottom: 30px !important;
    }


    .leaflet-popup.leaflet-zoom-animated {
        /*left: -75px !important;*/
    }

    .leaflet-popup-content {
        /*min-width: 125px;*/
        text-align: center;
    }

    .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
        width: auto !important;
    }
</style>
