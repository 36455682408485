import Vue from 'vue'
import Vuex from 'vuex'
import userdata from "./modules/user/userdata";
import auth from "./modules/auth/auth.module";
import profile from "./modules/profile/profile.module";
import navigation from "./modules/system/navigation.module";
import Create from "./modules/job/create";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userdata: userdata,
    auth: auth,
    navigation: navigation,
    job_create: Create,
    profile: profile,
  }
})
