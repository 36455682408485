<template>
    <div>
        <div class="step-contents">
            <div class="row">
                <div class="col-sm-12">
                    <fieldset class="form-group">

                        <div class="sub-boxes">
                            <div class="row setting-area">
                                <div class="col-sm-12">
                                    <fieldset class="form-group profile-areas radio-button-section-area">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <collapse :selected="true">
                                                    <div slot="collapse-header">
                                                        Notification Settings
                                                    </div>

                                                    <div slot="collapse-body">
                                                        <div class="setting-section">
                                                            <div class="setting-box">
                                                                <div class="setting-content">
                                                                    <div class="row">
                                                                        <div class="col-sm-12">
                                                                            <nav>
                                                                                <div class="nav nav-tabs"
                                                                                     id="notification"
                                                                                     role="tablist">
                                                                                    <a class="nav-item nav-link"
                                                                                       :class="{ active : selectedTab == 'email_notification'}"
                                                                                       data-toggle="tab" role="tab"
                                                                                       aria-controls="nav-company"
                                                                                       href="#email_notification"
                                                                                       @click="tabSelected('email_notification')"
                                                                                       aria-selected="true">Email</a>
                                                                                    <a class="nav-item nav-link"
                                                                                       :class="{ active : selectedTab == 'push_notification'}"
                                                                                       data-toggle="tab" role="tab"
                                                                                       aria-controls="nav-customer"
                                                                                       @click.prevent="tabSelected('push_notification')"
                                                                                       href="#push_notification">
                                                                                        In-App Notifications</a>
                                                                                </div>
                                                                            </nav>
                                                                            <div class="tab-content">

                                                                                <div v-for="feature in preferenceFormData.user_settings.notification_permissions"
                                                                                     class="tab-pane show"
                                                                                     v-if="feature.key == selectedTab"
                                                                                     :id="feature.key"
                                                                                     aria-labelledby="nav-home-tab">
                                                                                    <div class="form-group">
                                                                                        <label class="switch">
                                                                                            <input type="checkbox"
                                                                                                   name="permission.key"
                                                                                                   v-model="feature.selectAll"
                                                                                                   @change="updateSettings(feature.selectAll, feature.name)"
                                                                                                   :disabled="isAdminPermitted(feature.key) == 0">
                                                                                            <span class="slider round"></span>
                                                                                        </label>
                                                                                        <div class="switch-label">
                                                                                            Activate All
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr/>
                                                                                    <div class="row">
                                                                                        <div class="col-md-6 col-xxl-4"
                                                                                             v-for="permission in feature.permissions">
                                                                                            <div class="form-group">
                                                                                                <label class="switch">
                                                                                                    <input type="checkbox"
                                                                                                           :name="permission.key"
                                                                                                           v-model="permission.value"
                                                                                                           @change="updateSelectAll"
                                                                                                           :disabled="isAdminPermitted(feature.key) == 0">
                                                                                                    <span class="slider round"></span>
                                                                                                </label>
                                                                                                <div class="switch-label">
                                                                                                    {{permission.label}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </collapse>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>

                            </div>


                        </div>
                    </fieldset>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="text-right last-actions" style="padding-bottom: 15px;">
                                <button class="btn btn-primary btn-lg" @click="submit">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Collapse from 'vue-collapse';

    export default {
        name: 'OtherSetting',
        components: {
            Collapse
        },
        computed:{
            ...mapGetters({
                user: "userdata/user",
                userData: "profile/general",
                userSettings: "profile/settings",
                formData: "profile/formData",
            }),
        },
        data() {
            return {
                disabledFeatures: ['send_birthday_reminds'],
                preferenceFormData: {
                    fields: {},
                    user_settings:{}
                },
                fetchedData: {},
                oAdminPermissions: {
                    sms: false,
                    email: true,
                    fcm: true
                },
                selectedTab: 'email_notification'
            };
        },
        methods: {
            ...mapActions({
                submitSettings: "profile/updateSettings"
            }),
            setFormFields() {

                let self = this;


                let user_settings = {
                    application_settings: self.userSettings.applicationSettings,
                    notification_permissions: []
                };
                let notificationSettingsFields = self.formData.notificationSettings;
                let notificationPermissions = self.userSettings.notificationSettings;
                console.log("notificationPermissions", self.userSettings)


                if(notificationSettingsFields?.length > 0){
                    let notificationKey = '';
                    notificationSettingsFields.forEach(function (settingField, key, fields) {
                        if(settingField['key'] != "sms_notification") {
                            notificationKey = settingField['key'];

                            let permissions = [];
                            if (self.formData[notificationKey + 's']) {

                                self.formData[notificationKey + 's'].forEach(function (permission) {

                                    let isPermissionActive = 0;
                                    if (notificationPermissions && notificationPermissions.hasOwnProperty(notificationKey)) {
                                        console.log("notificationKey", notificationKey)
                                        notificationPermissions[notificationKey].forEach(function (userPermission) {
                                            if (userPermission.key == permission.key) {
                                                isPermissionActive = (userPermission.status) ? 1 : 0;
                                            }

                                        });
                                    }

                                    permissions.push({
                                        'name': permission.name,
                                        'label': permission.label,
                                        'key': permission.key,
                                        'value': isPermissionActive
                                    });

                                });
                            }

                            user_settings.notification_permissions.push(
                                {
                                    'name': settingField['key'],
                                    'label': settingField['label'],
                                    'key': settingField['key'],
                                    'permissions': permissions
                                }
                            );
                        }
                    });

                    self.preferenceFormData.user_settings = Object.assign({}, user_settings);
                    self.updateSelectAll();
                }


            },

            updateSettings(val, key) {
                this.preferenceFormData.user_settings.notification_permissions.forEach(function (feature, index) {
                    feature.permissions.forEach(function (permission, index) {
                        if (feature.name == key)
                            permission.value = val;
                    });
                });

            },

            updateSelectAll() {
                let hasFalse = 0;
                this.preferenceFormData.user_settings.notification_permissions.forEach(function (feature, index) {
                    hasFalse = 0;
                    feature.permissions.forEach(function (permission, index) {
                        if (!permission.value)
                            hasFalse = !permission.value;
                    });
                    feature.selectAll = !hasFalse;
                });
            },

            isDisabled(key) {
                return (this.disabledFeatures.indexOf(key) >= 0);
            },

            isAdminPermitted(channel) {

                switch (channel) {
                    case 'sms_notification':
                        return this.oAdminPermissions['sms'];
                        break;

                    case 'email_notification':
                        return this.oAdminPermissions['email'];
                        break;

                    case 'push_notification':
                        return this.oAdminPermissions['fcm'];
                        break;

                    default:
                        return 0;
                        break;
                }

            },

            submit() {

                let self = this;
                this.$validator.validate().then(function (res) {
                    self.$toast.loading(true);
                    if (res) {
                        self.submitSettings(self.preferenceFormData.user_settings).then(res => {
                            self.$toast.loading(false);
                            self.$toast.success('Successfully Updated.', true, true);
                        }).catch((error) => {
                            self.$validated.setErrors(self.$validator, error.data);
                            self.$toast.loading(false);
                            self.$toast.error('Failed to update.', true, true);
                        });
                    }
                });
            },

            tabSelected(tab){
                this.selectedTab = tab;
            }
        },
        mounted() {
            console.log("user", this.userData)
            this.setFormFields();
        },
        watch: {}
    };
</script>

<style scoped lang="scss">

    @import "../../../assets/styles/sass/_components/switch/switch.scss";
    @import '../../../assets/styles/sass/_components/radio/radio.scss';

    .switch {
        width: 39px;
        height: 20px;
        position: relative;
        top: 5px;
    }

    .slider:before {
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 1px;
    }

    .multi-radio {

        .radio-btn-area.align-set {
            padding-top: 0px !important;
            border-right: none !important;
            min-height: 30px;
            padding-bottom: 0;
        }

    }

    .mt-radio {
        float: left !important;
        width: 100% !important;
        padding-bottom: 7px !important;
        border: solid 1px #f5f5f5 !important;
        margin-bottom: 10px !important;
        background-color: #f8f8f8 !important;
        padding-top: 8px !important;
        padding-left: 15px !important;
        margin-top: 3px !important;
        border-radius: 6px !important;
        font-weight: 500 !important;
        color: #636262 !important;
        font-size: 0.9rem !important;
    }

    .sub-setting-option {

        padding-bottom: 15px;
        padding-top: 10px;

        .select-all-button {
            float: right;
            margin-right: 21px;
            margin-top: -25px;
        }

    }

    .list-sub {
        clear: both;
        margin-bottom: 8px;
    }

    .set-colss {
        position: relative;
        top: 5px;
    }

</style>
