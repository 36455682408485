import {getField, updateField} from 'vuex-map-fields';
import Vue from 'vue';

const Create = {
    namespaced: true,
    state: {
        job: {
            job_priority_id: 1,
            service_category_id: null,
            job_source_id: null,
            description: '',
            images: [],
            company_resources: [],
            location: {
                lat: '',
                lng: '',
            },
            address: '',
            quotation: {
                est_mode: 0,
                line_items: {},
            },
            customer: {
                profile_image: {},
                user: {},
                recent_locations: [],
            },
            contact_id: '',
            active_step: 1,
            estimation_preference: false
        },
        createData: {},
        jobInfo: {
            job_detail: {
                job_meta_data: {
                    started_images:[],
                    description: '',
                    job_location: {
                        'address': '',
                        'lat': '',
                        'lng': ''
                    }
                },
                customer: {
                    recent_address: {}
                }
            },
            job_group_type: 'regular',
            recurring: {
                id: "",
                recurring_at: "",
                recurring_start_at: "",
                recurring_ends_at: "",
                recurring_repeat_interval: "every_day",
                recurring_frequency: '',
                seasonal_frequencies: {},
                repeat: 0,
            },
            data_id: {},
        },
        selectedCustomer: {}
    },
    getters: {
        getField,
        getFormData: state => {
            let objectToFormData = require('object-to-formdata');
            return objectToFormData(state);
        },
        getArray: state => {
            return state;
        },
        getEstimateData: state => {
            let objectToFormData = require('object-to-formdata');
            return (state.job.estimations);
        }
    },
    mutations: {
        updateField,
        reset(state) {
            state.job = {
                job_priority_id: '',
                service_category_id: '',
                job_source_id: '',
                description: '',
                images: [],
                company_resources: [],
                location: {
                    lat: '',
                    lng: '',
                },
                estimations: {},
                customer: {
                    profile_image: {},
                    recent_address: {},
                    user: {},
                },
                active_step: 1

            };
            state.createData = {};
            state.jobInfo = {
                job_detail: {
                    job_meta_data: {
                        started_images:[],
                        description: '',
                        job_location: {
                            'address': '',
                            'lat': '',
                            'lng': ''
                        }
                    },
                    customer: {
                        recent_address: {}
                    }
                },
                job_group_type: 'regular',
                recurring: {
                    id: "",
                    recurring_at: "",
                    recurring_start_at: "",
                    recurring_ends_at: "",
                    recurring_repeat_interval: "every_day",
                    recurring_frequency: '',
                    seasonal_frequencies: {},
                    repeat: 0,
                },
                data_id: {}
            };
            state.selectedCustomer = {};
        },
        setJob(state, job) {
            state.job = job;
        },
        setJobInfo(state, jobInfo) {
            state.jobInfo = jobInfo;
            state.job.estimation_preference = jobInfo.estimation_preference;
        },
        setCreateData(state, createData) {
            state.createData = createData;
        },
        appendLog(state, jobInfo) {
            let job_process = state.jobInfo.job_detail.log.job_process;
            state.jobInfo.job_detail.log.job_process = _.concat(job_process, jobInfo.job_detail.log.job_process);
        },
        addImageToProcess(state, image) {
            state.jobInfo.job_detail.log.job_process = state.jobInfo.job_detail.log.job_process.map(function (process) {
                let _process = process;
                if (image.process_id == _process.process_id) {
                    for (var key in _process.data) {
                        if (key.includes('image')) {
                            if (!_process.data[key].value) {
                                _process.data[key].value = [];
                            } else {
                                let available = _.filter(_process.data[key].value, {thumb: image.thumb});
                                if (available.length) {
                                    continue;
                                }
                            }

                            _process.data[key].value.push(image);
                            _process.data[key].display = 1;
                        }
                    }
                }
                return _process;
            });
       }
    },
};
export default Create;
