import Vue from 'vue'
import Acl from './plugins/acl'
import XhrRequest from './services/AxiosService'
import Utils from "./helpers/util";
import Echo from 'laravel-echo';
import axios from 'axios';
// import Toast from './plugins/toast'
window.axios = axios;

import VueEvents from 'vue-events'
Vue.use(VueEvents);

Vue.use(Acl, { store: store });

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//For notification toasts
import Toast from './plugins/toast'

Vue.use(Toast);

try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

window.XhrRequest = XhrRequest;
window.moment = require('moment');
window.Pusher = require('pusher-js');

var token = '';

try {
    token = JSON.parse(localStorage.getItem('access_credentials')).access_token;
} catch (e) { }

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    authEndpoint: process.env.VUE_APP_API_BASE_URL + 'api/v2/broadcasting/auth',
    disableStats: true,
    forceTLS: false,
    auth: {
        headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
        }
    }
});

window.Noty = require('noty');
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

VeeValidate.Validator.extend('complex_password', {
    getMessage: field => `The password must contain at least: 1 uppercase letter, 1 number, and 10 character`,
    validate: value => {
        var strongRegex = new RegExp("(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z]))^.{10,}$");
        return strongRegex.test(value);
    }
});

import validated from '../src/plugins/validated'

Vue.use(validated);

Vue.mixin(Utils);

import NotyService from './services/NotyService'
NotyService.overrideNotyDefaults();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
