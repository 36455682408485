<template>
    <div>
        <bread-crumb :links="[{name:'home', label:'Home'},{name:'job.manage', label: 'View Quotations'}]"/>

        <div class="row quotations-preview">
            <div class="col-xxl-6 col-xl-7 col-md-7 text-left left-scale" id="job-manage-section">


                <job-view :id="id" v-if="renderComponent">
                    <multi-request-items-list :multi_request_id="id" @showPdf="showPdf" @showQuotationRequest="showQuotationRequest" v-if="renderComponent"></multi-request-items-list>
                </job-view>
                <router-view></router-view>
            </div>
            <div class="col-xxl-6 col-xl-5 col-md-5">


                <div class="preview-area">
                    <div class="loader" v-if="loader"></div>
                    <vue-pdf-app :pdf="pdfUrl" :file-name="fileName" v-if="pdfUrl != ''" :config="config"></vue-pdf-app>
                    <div class="sub-preview-area" v-if="subPreviewArea">
                      <span>
                            <svg width="30px" height="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;    fill: #a4a4a6;" xml:space="preserve">
                            <g>
                                <g>
                                    <path d="M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474
                                        c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323
                                        c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848
                                        S326.847,409.323,225.474,409.323z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328
                                        c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z"/>
                                </g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            </svg>

                        </span>
                      <h3>Click on an item to preview</h3>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import BreadCrumb from "../../components/_global/breadcrumb/BreadCrumb";
    import JobView from "../job_details/JobView";
    import MultiRequestItemsList from "../job_details/MultiRequestItemsList";
    import QuotationService from "../../services/QuotationService";
    import VuePdfApp from "vue-pdf-app";
    import "vue-pdf-app/dist/icons/main.css";

    export default {
        name: 'QuotationView',
        components: {
            JobView,
            BreadCrumb,
            MultiRequestItemsList,
            VuePdfApp
        },
        props: ['id'],
        data() {
            return {
                pdfUrl:'',
                fileName: '',
                loader: false,
                renderComponent:true,
                config: {
                    toolbar: {
                        toolbarViewerRight: {
                            openFile: false,
                            print:false,
                            viewBookmark: false
                        }
                    }
                },
                subPreviewArea : true,
                pdfData:[]
            }
        },
        methods:{
            getDetail(){
                return QuotationService.quotation(this.id);
            },
            showPdf(quotation_id, job_id, type){
                let self = this;
                self.subPreviewArea = false;
                self.loader = true;
                self.pdfUrl = '';
                this.fileName = 'quotation_'+job_id;
                if (self.pdfData.hasOwnProperty(job_id) == true){
                    self.pdfUrl = self.base64ToArrayBuffer(self.pdfData[job_id]);
                    self.loader = false;
                }else{
                    QuotationService.pdfView(quotation_id, type).then((response) => {
                        self.pdfUrl = self.base64ToArrayBuffer(response.data);
                        self.loader = false;
                        self.pdfData[job_id] = response.data;
                    });
                }

            },
            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },
            showQuotationRequest(job_id){
                let self = this;
                QuotationService.quotationRequest(job_id).then((response) => {

                    const linkSource = `data:application/pdf;base64,${response.data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "Quotation Request "+job_id+".pdf";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                });
            },
            refreshPage(){
                this.renderComponent = false;
                this.$nextTick(() => {
                    this.renderComponent = true;
                    this.pdfUrl = '';
                });
            }
        },
        mounted() {
            $('#collpase-icon').trigger('click');
            $('body').addClass('quotation-view');
        },
        beforeDestroy() {
            $('#expand-icon').trigger('click');
            $('body').removeClass('quotation-view');
        },
        events: {
            newActivity(response){
                this.refreshPage();
            }
        }
    };
</script>

<style>
    .pdf-app .pdfViewer .page{
        border : 0px
    }
    #pdfFileInput{
        display: none !important;
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/styles/sass/_components/placeholder/placeholder";
    .txt-nl{
        word-break: break-word;
        max-height: 300px;
        overflow-y: auto;
        white-space: pre-line;
    }

    body{
        overflow: visible;
    }

    .preview-area{
        height: 100%;
        background-color: #dadada;
        padding: 25px;
        border-radius: 5px;
        box-shadow: inset 0 0 10px;
        position: relative;
        width: 100%;
    }

    .loader {
        border: 5px solid #f3f3f37a;
        border-top: 5px solid #595a5a;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%; left: 50%;
        -webkit-animation: spin-data-v-581f7ce3 2s linear infinite;
        animation: spin-data-v-581f7ce3 2s linear infinite;
    }

    .sub-preview-area{
        position: absolute;
        top: 50%;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }

    .sub-preview-area h3{
      color: #a4a4a4;
      font-size: 20px;
      font-weight: 400;
      margin-top: 13px;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .quotations-preview{
      .form-header{
        margin-bottom: 20px;
        padding-top: 0px !important;
      }
      .requested-suppliers-table{
        .row.job-dashboard-section{
          margin: 0px -30px;
        }
      }
      .preview-area {
        padding: 0px;
        box-shadow: unset;
        z-index: 10;
        .pdf-app {
          background-color: #ffffff;
          border-radius: 6px;
          box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
          .pdfViewer .page{
            border: unset;
          }
          #mainContainer{
            #viewerContainer{
              padding-right: 20px;
              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              &::-webkit-scrollbar-thumb {
                background: #cfd8dc;
                border-radius: 5px;
                transition: all 0.5s;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            }
          }
        }
      }

    }


</style>

