<template>
    <div>
        <VueJsModal ref="rejectModel" name="rejectModelPopup" transition="pop-out" :width="600"
                    :showFooter="showFooter"
                    :showHeader="showHeader"
                    :headerTitle="modelTitle"
                    :hasRoute="false"
        >
            <template slot="header">
                <h5>Quotation(s) Rejection</h5>
            </template>
            <template slot="body">
                <div class="set-model-padding">

                    <div class="tab-content removeminhight" role="tablist">
                        <div>
                            <div class="row privacy-section">
                                <div class="col-sm-12">
                                    <div role="alert" class="alert alert-info show billing-setting">
                                        <div class="alert-type"></div>
                                        <h4>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                 viewBox="0 0 48 48">
                                                <path data-v-2c246398=""
                                                      d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm2 30h-4V22h4v12zm0-16h-4v-4h4v4z"></path>
                                            </svg>
                                            Hint
                                        </h4>
                                      You are about to Reject the selected quotation(s). Please note that you can upload attachments and add notes if required.
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Reject Reason*</label>
                                        <select class="form-control" v-model="formData.reason_id"
                                                @change="onChangeReasons" data-vv-as="Reason"
                                                v-validate="{ required: true}"
                                                data-vv-name="reason">
                                            <option value="">&#45;&#45;Requires Additional Information&#45;&#45;
                                            </option>
                                            <option v-for="reason in rejectReasons" :value="reason.id"
                                                    :data-key="reason.key">{{reason.reason}}
                                            </option>
                                        </select>
                                        <error-message :message="errors.first('reason')"></error-message>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" rows="5" placeholder="Add Notes"
                                                  v-model="formData.comment" v-validate="{ required: add_note_required}"
                                                  data-vv-as="Add Notes"
                                                  data-vv-name="add_note"></textarea>
                                        <error-message :message="errors.first('add_note')"></error-message>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <form ref="fileUploadForm">
                                        <div class="form-group dropzone-pading upload-invoice-dropzone">
                                            <label>Attachments</label>
                                            <uploader
                                                    class="text-muted invoice-upload-dropzone qr-uploader"
                                                    ref="fileupload"
                                                    :id="'rejectAttachments'"
                                                    :maxFilesize="5"
                                                    :maxFiles="5"
                                                    :headerText="'Click here to Upload'"
                                                    :subHeaderText="'(Allowed File Type: pdf, Image, Doc)'"
                                                    :acceptedFiles="'.pdf, image/*, .docx'"
                                                    v-on:file-added="addImages"
                                                    v-on:file-removed="removedImages"
                                                    v-on:error="showUploadError">
                                            </uploader>
                                            <span class="text-danger">{{fileErrorMessage}}</span>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </template>
            <template slot="footer">
                <button class="btn btn-secondary" type="button" @click="cancel">
                    Cancel
                </button>

                <button id="saveButton" class="btn btn-primary" type="button" @click="save">
                    Reject
                </button>

            </template>

        </VueJsModal>
    </div>
</template>

<script>
    import Uploader from "../../components/file_uploader/Uploader";
    import VueJsModal from "../../components/modal/VueJsModal";
    import ErrorMessage from "../../components/validation/ErrorMessage";
    import QuotationService from "../../services/QuotationService";
    import ObjectToForm from "object-to-formdata";
    import AppConstants from "../../const/AppConstants";
    import swal from "sweetalert2";

    export default {
        name: "Reject",
        components: {
            Uploader,
            VueJsModal,
            ErrorMessage
        },
        data() {
            return {
                modelTitle: '',
                showHeader: true,
                showFooter: true,
                fileErrorMessage: '',
                formData: {
                    process: AppConstants.WORKFLOW.EXTERNAL_CM_QUOTE_REJECTED,
                    comment: '',
                    reason_id: ''
                },
                rejectReasons: [],
                add_note_required: false,
                attachments: {
                    add_files: []
                },
                multiple: false,
                job_id: '',
                quotation_id: '',
                jobs: '',
            }
        },
        methods: {
            openPopup(job_id, quotation_id, mutiple) {
                if (mutiple == true) {
                    this.jobs = job_id;
                } else {
                    this.job_id = job_id;
                    this.quotation_id = quotation_id;
                }

                this.multiple = mutiple;
                this.$refs.rejectModel.show();
            },
            addImages(id, file) {
                let self = this;
                //let uploads = self.attachments.add_files || [];
                //uploads = file;
                //self.$set(self.attachments, 'add_files', uploads);
                self.attachments.add_files = file;
                self.fileErrorMessage = '';
            },
            removedImages(file) {
                let self = this;
                let uploads = self.attachments.add_files;
                uploads = uploads.filter(function (value, index, files) {
                    if (value.upload) {
                        return (value.upload.uuid != file.upload.uuid);
                    }
                    return true;
                });
                this.$set(self.attachments, 'add_files', uploads);
            },
            cancel() {
                this.$refs.rejectModel.hide();
            },
            save() {
                let self = this;
                self.$validator.validate().then(result => {
                    if (result) {

                        if (self.multiple == true) {
                            self.multipleReject();
                        } else {
                            self.singleRejection();
                        }
                    }
                });
            },
            multipleReject() {
                let self = this;
                let oFormData = {};

                oFormData.process = self.formData.process;
                oFormData.files = self.attachments.add_files;
                oFormData.comment = self.formData.comment;
                oFormData.reason_id = self.formData.reason_id;
                oFormData = ObjectToForm(oFormData);

                for (let key of Object.keys(self.jobs)) {
                    for (let keyS of Object.keys(self.jobs[key])) {
                        oFormData.append(`${'jobs'}[${key}][${keyS}]`, self.jobs[key][keyS]);
                    }
                }

                new swal({
                    title: 'Are you sure you want to Reject selected Quotation(s)?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true,
                    customClass: 'archive-modal',
                }).then((result) => {
                    if (result.value) {
                        self.$toast.loading(true);

                        QuotationService.rejectMultiple(oFormData).then((response) => {
                            self.$toast.loading(false);

                            if (response.data.status_code === 200) {
                                self.$parent.$parent.$parent.refreshPage();
                                self.$toast.success(response.data.message);
                            } else {
                                self.$toast.error(response.data.message);
                            }

                        }).catch(function (response) {
                            self.$toast.loading(false);
                            self.$toast.error('Something went wrong please try again.');
                        });
                    }
                });
            },
            singleRejection() {
                let self = this;
                let oFormData = {};

                oFormData.process = self.formData.process;
                oFormData.files = self.attachments.add_files;
                oFormData.comment = self.formData.comment;
                oFormData.reason_id = self.formData.reason_id;
                oFormData.job_id = self.job_id;
                oFormData.quotation_id = self.quotation_id;

                oFormData = ObjectToForm(oFormData);

                new swal({
                    title: 'Are you sure you want to Reject selected Quotation(s)?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    reverseButtons: true,
                    customClass: 'archive-modal',
                }).then((result) => {
                    if (result.value) {
                        self.$toast.loading(true);
                        QuotationService.reject(oFormData).then((response) => {
                            self.$toast.loading(false);

                            if (response.data.status_code === 200) {
                                self.$parent.$parent.$parent.refreshPage();
                                self.$toast.success(response.data.message);
                            } else {
                                self.$toast.error(response.data.message);
                            }

                        }).catch(function (response) {
                            self.$toast.loading(false);
                            self.$toast.error('Something went wrong please try again.');
                        });
                    }
                });
            },
            getRejectReasons() {
                let self = this;
                QuotationService.rejectReasons().then((response) => {
                    self.rejectReasons = response.data.result;
                });
            },
            onChangeReasons(e) {
                this.add_note_required = false;
                if (e.target.options.selectedIndex > -1) {
                    const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
                    if (theTarget.key == 'other') {
                        this.add_note_required = true;
                    }
                }
            },
            showUploadError(error) {
                let self = this;
                let errorMessage = '';
                let errorElement = error.error;
                self.$toast.clear();
                if (errorElement.includes('File is too big')) {
                    errorMessage = 'The file is larger than the maximum allowed file size of 5MB.'
                } else if (errorElement.includes('You can not upload any more files')) {
                    errorMessage = 'You cannot upload more than 5 files here';
                }
                self.$toast.error(errorMessage, false, {duration: 2000});
            },
        },
        mounted() {
            this.getRejectReasons();
        }
    }
</script>

<style scoped>
    .modal-footer {
        text-align: center;
        display: block;

    button {
        font-size: 14px !important;
        padding: 9px !important;
    }

    }
</style>