<template>
  <div>
    <bread-crumb :links="[{name:'dashboard', label: 'Dashboard'}]"/>

    <div>
      <div class="row">

        <div class="col-sm-12">

          <div class="element-wrapper">

            <h6 class="element-header no-border">Dashboard</h6>

            <div class="element-content">
              <div class="row">
                <div class="col-md-12">
                  <div class="stg-user-div">

                      <div class="stg-user-img">
                        <a href="#" @click="$router.push({name: 'committee_member_profile_manage'})">
                          <img :src="user ? user.profile_logo : ''">
                        </a>

                      </div>

                    <p>Hey there <b>{{ user ? user.name : ''}}</b>, Welcome back!</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-xxxl-9">
          <div class="element-wrapper">
            <div class="heading-with-selectbox">
              <h6 class="element-header">Statistics</h6>
              <div>
                <label class="dashboard-filter">Strata Plan :</label>
                <select
                        v-model="filters.strata_plan_no"
                        @change="getStatistics"
                >
                  <option :value="''" :selected="true">All Plans</option>
                    <option v-for="item in properties" :value="item.reference_no">{{ item.name }}</option>
                </select>
              </div>
            </div>
            <div class="element-content">
              <div class="row">
                <div class="col-12">
                  <div class="home-statistics-div">
                  <a class="element-box el-tablo home-statistics dash-sms-request" href="#" @click="$router.push({name:'quote_request_dashboard', params:{status_group: stats.cm_approval_pending.id}})">
                    <i class="dash-icon aprl-fas">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="50px" height="50px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 2L11 13"/><path d="M22 2l-7 20l-4-9l-9-4l20-7z"/></g></svg>
                    </i>
                    <div>
                      <div class="label">New Quotes</div>
                      <div class="value">{{stats.cm_approval_pending.count}}</div>
                    </div>

                  </a>

                  <a class="element-box el-tablo home-statistics  dash-new-request " href="#" @click="$router.push({name:'quote_request_dashboard', params:{status_group: stats.cm_approved.id}})">
                    <i class="dash-icon aprl-fas">

                      <svg width="40" height="40" enable-background="new 0 0 384 384" version="1.1" viewBox="0 0 384 384" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
		<path d="m275.31 140.69c-6.24-6.248-16.384-6.248-22.624 0l-68.688 68.68-28.688-28.68c-6.24-6.248-16.384-6.248-22.624 0-6.248 6.248-6.248 16.376 0 22.624l40 40c3.12 3.128 7.216 4.688 11.312 4.688s8.192-1.56 11.312-4.688l80-80c6.248-6.248 6.248-16.376 0-22.624z"/>
                        <path d="m368 176c-8.832 0-16 7.168-16 16 0 88.224-71.776 160-160 160s-160-71.776-160-160 71.776-160 160-160c42.952 0 83.272 16.784 113.54 47.264 6.216 6.28 16.352 6.312 22.624 0.08 6.272-6.224 6.304-16.352 0.08-22.624-36.32-36.576-84.712-56.72-136.25-56.72-105.87 0-192 86.128-192 192s86.128 192 192 192 192-86.128 192-192c0-8.832-7.168-16-16-16z"/>
</svg>

                    </i>
                    <div>
                      <div class="label">Approved Quotes</div>
                      <div class="value">{{stats.cm_approved.count}}</div>
                    </div>
                  </a>

                  <a class="element-box el-tablo home-statistics dash-confirmed-request" href="#" @click="$router.push({name:'quote_request_dashboard', params:{status_group: stats.cm_rejected.id}})">
                    <i class="dash-icon aprl-fas">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="45px" height="50px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none" stroke="#ddd" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"><path d="M13 8l-7 6l7 7"/><path d="M6 14h22.994c6.883 0 12.728 5.62 12.996 12.5c.284 7.27-5.723 13.5-12.996 13.5H11.998"/></g></svg>
                    </i>
                    <div>
                      <div class="label">Rejected Quotes</div>
                      <div class="value">{{stats.cm_rejected.count}}</div>
                    </div>
                  </a>

                  <a class="element-box el-tablo home-statistics  dash-rejected-request" href="#" @click="$router.push({name:'work_order_dashboard', params:{status_group: stats.wo_in_progress.id}})">
                    <i class="dash-icon aprl-fas">
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="50px" height="50px"
                             preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92c-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93c-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 0 1 4.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0 0 11 22v-2a8.002 8.002 0 0 1-3.9-1.63M12.5 7v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5z" fill="currentColor"/></svg>
                    </i>
                    <div>
                      <div class="label">In Progress WO</div>
                      <div class="value">{{stats.wo_in_progress.count}}</div>
                    </div>
                  </a>

                  <a class="element-box el-tablo home-statistics dash-claimed-request" href="#" @click="$router.push({name:'work_order_dashboard', params:{status_group: stats.wo_completed.id}})">
                    <i class="os-icon  os-icon-tasks-checked dash-icon"></i>
                    <div>
                      <div class="label">Completed WO</div>
                      <div class="value">{{ stats.wo_completed.count }}</div>
                    </div>
                  </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="el-chart-w el-chart-w-local-scope">
                <linechart  v-if="datacollection.datasets.length" :data="datacollection" :ref="'lineChart'"></linechart>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BreadCrumb from "../../components/_global/breadcrumb/BreadCrumb";
import linechart from './linechart';
import Vue from "vue";
import { mapGetters } from 'vuex';
import WorkOrderService from '../../services/WorkOrderService';


export default {
  components: {
    BreadCrumb,
    linechart
  },
  data() {
    return {
        stats: {
            cm_approval_pending: 0,
            cm_approved: 0,
            cm_rejected: 0,
            wo_issued: 0,
            wo_in_progress: 0,
            wo_completed: 0,
            wo_archived: 0
        },
        datacollection: {
            labels: [],
            datasets: []
        },
        properties: [],
        filters: {
            strata_plan_no: ''
        }
    }
  },
  computed: {
    ...mapGetters({
      user: "userdata/user",
    }),
  },
  mounted() {
    this.$nextTick(function () {
      $('#pageLoader').fadeOut(1500);
    });
      this.getProperties();
  },
  beforeMount() {
    this.getStatistics();
  },
  methods: {
    slidemodalFn() {
      this.$refs.slidemodal.show();
    },

    getStatistics(loader = true) {
      let self = this;
      let queryData = Object.keys(this.filters).map(key => key + '=' + this.filters[key]).join('&');
      XhrRequest.send(XhrRequest.XHR_GET_METHOD, 'committee/dashboard/summery?'+queryData, {}, loader).then(res => {
        self.$set(self, 'stats', res.data.result.counts);
        self.$set(self, 'datacollection', res.data.result.chart);

        self.$nextTick(() => {
            console.log(typeof this.$refs.linechart)
            if (typeof self.$refs.linechart != "undefined"){
                self.$refs.linechart.reloadChart();
            }
        })

        // self.stats = res.data.result.counts;
        // self.datacollection = res.data.result.chart;

      }).catch(error => {
        console.error(error);
      });
    },

      getProperties(loader = true) {
          let self = this;
          console.log('self.user.property_tenant.customer_id', self.user.property_tenant.customer_id)
          XhrRequest.send(XhrRequest.XHR_GET_METHOD, 'committee/properties?customer_id=' + self.user.property_tenant.customer_id, {}, loader).then(res => {
              self.properties = res.data.result;
          }).catch(error => {
              console.error(error);
          });
      },

    goToScreen(name){
      this.$router.push({
        name: name
      });
    }
  },
  events: {}
};
</script>


<style lang="scss" scoped>
@import "../../assets/styles/sass/customer/main-dashboard";
@import "../../assets/styles/sass/_components/slider/slider";

.sub-status svg {
  margin-top: 8px;
}

.setFullScreen {
  width: 90%;
  height: 90%
}

.map-hide {
  display: none;
}

.map-show {
  display: block;
}

.dash-new-request{
  background: #4171cc;
  color:#fff;
  padding-left: 20px;

  .label{
    color:#fff !important;
    font-weight: 500;
  }

  .dash-icon {
    float: left;
    font-size: 57px;
    margin-right: 15px;
    border: 2px solid;
    border-radius: 11px;
    color: #96b1e6;
    min-width: 60px;
    text-align: center;
    padding: 3px;
  }
}

.dash-claimed-request{
  background: #63dba9;
  color:#fff;
  padding-left: 20px;

  .label{
    color:#fff !important;
    font-weight: 500;
  }

  .dash-icon {
    float: left;
    font-size: 47px;
    margin-right: 15px;
    border: 2px solid;
    border-radius: 11px;
    padding: 6px;
    color: #d6f4e8;
    width: 60px;
    text-align: center;
  }
}

.dash-confirmed-request{
  background: #708891;
  color:#fff;
  padding-left: 20px;

  .label{
    color:#fff !important;
    font-weight: 500;
  }

  .dash-icon {
    float: left;
    font-size: 45px;
    margin-right: 15px;
    border: 2px solid;
    border-radius: 11px;
    padding: 8px;
    color: #e6e6e6;
    width: 60px;
    text-align: center;
  }
}

.dash-rejected-request{
  background: #ee875e;
  color:#fff;
  padding-left: 20px;

  .label{
    color:#fff !important;
    font-weight: 500;
  }

  .dash-icon {
    float: left;
    font-size: 45px;
    margin-right: 15px;
    border: 2px solid;
    border-radius: 11px;
    padding: 8px;
    color: #f7d2c3;
    width: 60px;
    text-align: center;
  }
}

a.el-tablo:hover .value{
  color: #eee !important;
}

.el-chart-w-local-scope {
  background-color: white;
  border-radius: 10px;
  margin-top: 30px;
  padding: 30px;
  min-height: 316px;
}
.aprl-fas{
  font-size: 38px !important;
  padding-top: 10px !important;
}

</style>
