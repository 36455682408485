import terminologies from "./terminologies";
import AuthService from "../services/AuthService";
/**
 * i4T ACL plugin to manage user permissions throughout the system
 * Example of usage
 * 1. this.$acl.method() - Globally
 * 2. this.$acl.can({obj}) - Globally
 * 2. v-can directive - <elment v-can=({name:'dashboard.search', perm: 'view'}) />
 */
export default {

    install: (Vue, options) => {

        let self = this;
        let store = options.store;
        let _user = false;

        Vue.prototype.$acl = {
            /**
             * Get user object with permissions from the backend and store it on vuex
             * @returns {Promise<any>}
             */
            _storeUserData() {
                return new Promise((resolve, reject) => {
                    store.dispatch('userdata/fetchAuthUserData').then((response) => {
                        //set user vue instance
                        //this can be called this.$user in all components
                        Vue.prototype.$user  = response.user;
                        terminologies._setTerminologies(response.terminologies);

                        resolve(response);

                    }).catch((error) => {
                        reject(error);
                    });
                });
            },

            /**
             * Return a promise with the user data object from vuex store
             * if it is not present, fetch again and send
             * @returns {Promise<any>}
             */
            getUserPermissions() {
                let self = this;
                return new Promise((resolve, reject) => {
                    //fetching from vuex store
                    let permission = store.getters['userdata/data'];
                    if (permission == null) {
                        self._storeUserData().then(function (userdata) {
                            resolve(userdata);
                        }).catch(function (error) {
                            reject(error);
                        });
                    } else {
                        resolve(permission);
                    }
                });
            },

            /**
             * Check router permissions
             * @param to
             * @param from
             * @returns {Promise<any>}
             */
            hasRoutePermission(to, from) {
                let self = this;
                return new Promise((resolve, reject) => {
                    if (to.matched.some(record => record.meta.auth)) {
                        if (this.isAuthenticated()){
                            this.getUserPermissions().then((userdata) => {
                                // this route requires auth, check if logged in
                                if (self.checkPermission(userdata, to.name)) {
                                    resolve({auth: true, hasPermission: true});
                                } else{
                                    resolve({auth: true, hasPermission: false});
                                }
                            }).catch((error) => {
                                resolve({auth: false, hasPermission: false});
                            });
                        }else {
                            resolve({auth: false, hasPermission: false});
                        }

                    } else {
                        resolve(true); // make sure to always call next()!
                    }

                });
            },


            /**
             * Check against user permissions for the given key
             * @param permissions
             * @param key
             * @param perm | default is view (view, edit, delete, read)
             * @returns {boolean}
             */
            checkPermission(userdata, key, perm = 'view') {
                let permissions = userdata.permissions;
                let features = userdata.features;

                const has = permissions.find(function (elem) {
                    if (elem._route_name == key) {
                        if (elem.hasOwnProperty('_permission')) {
                            //check for root user
                            if(JSON.parse(elem._permission).indexOf('root') !== -1){
                                if(!userdata.user.root){
                                    return false;
                                }
                            }
                            //check for permissions
                            if (JSON.parse(elem._permission).indexOf(perm) !== -1) {
                                //check for feataure enablity
                                if (elem._feature != null) {
                                    if(features[elem._feature] == 1){
                                        return true;
                                    }
                                } else
                                    return true;
                            }
                        };
                    };
                });

                return (has !== undefined);
            },

            isAuthenticated(){
                return AuthService.isAuthenticated();
            },

            /**
             * Example of usage
             * this.$acl.can({name:'dashboard.search', perm: 'view'})
             * @param binding
             */
            can(binding) {
                let self = this;
                this.getUserPermissions().then((userdata) => {
                    let hasPermission = self.checkPermission(userdata, binding.name, binding.perm);
                    if (!hasPermission) {
                        return false
                    }
                    return true;
                }).catch((error) => {

                });
            },

            check2fa() { // check admin user required & enabled 2fa authentication
                const check2fa = this.getUserPermissions().then((userdata) => {
                    //check whether logged user has admin id or not
                    if (userdata.user.hasOwnProperty('admin_id') === true && userdata.user.admin_id != 0 && userdata.user.admin_id != ''){
                        if (userdata.user.two_factor === 0 && userdata.user.two_factor_required === 1){
                            return false;
                        }
                    }
                    return true;
                }).catch((error) => {

                });

                return check2fa;
            }
        }

        /**
         * v-can Directive
         * Ex: <elment v-can=({name:'dashboard.search', perm: 'view'}) />
         * Ex. for Sub Resources <elment v-can=({name:'dashboard', perm: 'view', key:'new.job.requests'}) />
         *      name -> Parent route name, perm -> access, key -> sub resource key
         */

        Vue.directive('can', function (el, binding) {
            Vue.prototype.$acl.getUserPermissions().then((userdata) => {

                let hasPermission = userdata.permissions.find(function (elem) {

                    if (elem._route_name == binding.value.name) {
                        if (elem.hasOwnProperty('_permission')) {
                            //sub resource permission
                            if (binding.value.hasOwnProperty('key')) {
                                return checkSubResource(elem._sub_resources, binding.value);
                            }else {
                                //check for permissions
                                if (JSON.parse(elem._permission).indexOf(binding.value.perm) !== -1) {
                                    return true;
                                }
                            }
                        }
                    }
                });

                if (!hasPermission) {
                    //TODO: Remove the element from DOM
                    if (binding.value.hasOwnProperty('element')) {
                        switch (binding.value.element) {
                            case "disabled":
                                $(el).attr("disabled", binding.value);
                                break;

                            default:
                                $(el).hide();
                        }

                    } else {
                        el.disabled = true;
                        $(el).hide();
                    }

                }
            }).catch((error) => {

            });
        });

        //Need to replace to a proper place
        Vue.directive('multi-ref', {
            bind: addRef,
            update: addRef
        });

        function checkSubResource(sub_permission, value) {

            if (sub_permission != undefined) {

                let subpermission = sub_permission.find(function (elem) {

                    if (elem._key == value.key) {
                        if (elem.hasOwnProperty('_permission')) {
                            //check for permissions
                            if (elem._permission.length > 0){
                                if (JSON.parse(elem._permission).indexOf(value.perm) !== -1) {
                                    return true;
                                }else{
                                    return false;
                                }
                            }else{
                                return false;
                            }

                        }
                    }
                });
                return subpermission;
            }else{
                return false;
            }
        }

        function addRef(el, binding, vnode) {
            const ref = binding.arg
            const vm = vnode.context
            if (!vm.$refs.hasOwnProperty(ref)) {
                vm.$refs[ref] = []
            }
            const index = vm.$refs[ref].indexOf(el)
            if (index == -1) {
                vnode.context.$refs[ref].push(el)
            }
        }

    }
}
