const Create = [
    'job',
    'job.estimations',
    'createData',
    'jobInfo',
    'jobInfo.recurring',
    'jobInfo.recurring.seasonal_frequencies',
    'jobInfo.job_detail',
    'jobInfo.data_id',
    'jobInfo.job_detail.job_meta_data',
    'selectedCustomer',
];
export default Create;
