<template>
    <span class="text-danger">{{ message }}</span>
</template>

<script>
    export default {
        name: "ErrorMessage",
        props: {
            message: {
                type: String
            }
        },
        data() {
            return {
                messageFormatted: ""
            }
        },
        watch: {
            message() {
                let self = this;
                if (self.message) {
                    let arraySplits = self.message.split(' ');
                    arraySplits[1] = self.humanize(arraySplits[1]);
                    self.messageFormatted = arraySplits.join(" ");
                } else {
                    self.messageFormatted = "";
                }

                /*if (self.$validator.errors.items.length) {
                    if (document.body.querySelector('[name="' + self.$validator.errors.items[0].field + '"]')) {
                        document.body.querySelector('[name="' + self.$validator.errors.items[0].field + '"]').scrollIntoView();
                        // if (!window.pageYOffset) {
                        //     if (document.body.querySelector('[name="' + self.$validator.errors.items[1].field + '"]')) {
                        //         document.body.querySelector('[name="' + self.$validator.errors.items[1].field + '"]').scrollIntoView();
                        //     }
                        // }
                    } else {
                        document.body.querySelector('[data-vv-name="' + self.$validator.errors.items[0].field + '"]').scrollIntoView();
                        // if (!window.pageYOffset) {
                        //     if (document.body.querySelector('[name="' + self.$validator.errors.items[1].field + '"]')) {
                        //         document.body.querySelector('[name="' + self.$validator.errors.items[1].field + '"]').scrollIntoView();
                        //     }
                        // }
                    }
                }*/
            }
        },
        methods: {
            humanize(str) {
                if (str) {
                    let frags = str.split('_');
                    for (let i = 0; i < frags.length; i++) {
                        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                    }
                    return frags.join(' ');
                } else
                    return str;
            }
        }

    }
</script>

